export const getServiceVersion = () => ({
  server: 'facility',
  method: 'GET',
  url: '/api/facility/getServiceVersion',
})

export const sendEmailAPI = () => ({
  server: 'facility',
  method: 'POST',
  url: '/api/facility/email/send',
  isAlwaysUseRestfulAPI: true,
})

// scan
// lookupID
//* 會多開一隻掃全部的 這只是掃單一 middleware 的
//* 全部要給 ClientID
export const scanDevicesAPI = () => ({
  server: 'facility',
  method: 'GET',
  url: `/api/facility/device/scanDevices`,
  timeout: 20000,
})

// member
export const memberAvatarUploadAPI = () => ({
  server: 'facility',
  method: 'POST',
  url: '/api/facility/user/avatar/upload',
  timeout: 20000,
})
