import React from 'react'
import * as R from 'ramda'
import { useDispatch, useSelector } from 'react-redux'

import Head from 'src/containers/Modal/Normal/beamformers/bbox/DeviceController/CustomAntenna/EditMode/Head'
import SpacingAndAngle from 'src/containers/Modal/Normal/beamformers/bbox/DeviceController/CustomAntenna/EditMode/SpacingAndAngle'
import OffsetInputGroups from 'src/containers/Modal/Normal/beamformers/bbox/DeviceController/CustomAntenna/EditMode/Offset'

import CommonButton from 'src/components/Button/CommonButton'

import {
  main,
  footer,
} from 'src/containers/Modal/Normal/beamformers/bbox/DeviceController/CustomAntenna/style'
import { h3, h6 } from 'src/containers/Modal/style'

import {
  bbox_customAntennaModal_deleteClick_watcher,
  bbox_customAntennaModal_saveClick_watcher,
} from 'src/redux/actions/beamformers/bboxAction'

import {
  getAntennaName,
  antennaNameIsInvalid,
  spacingIsInvalid,
  thetaMaxIsInvalid,
  offsetIsInvalid,
} from 'src/funcs/device/bbox'

import { offsetMin, offsetMax } from 'src/constants/beamformers'

const EditMode = () => {
  return (
    <>
      <Head />

      <div className={'w-full px-[84px]'}>
        <main className={main + ' overflow-y-scroll scrollbar'}>
          <SpacingAndAngle />

          <div className='h-[1px] w-full bg-dark-5 my-4' />

          <Offset />
        </main>
      </div>

      <Footer />
    </>
  )
}

export default EditMode

const Offset = () => {
  return (
    <div className='w-full flex flex-col px-2 pb-4'>
      <h3 className={h3 + ' mb-1 text-light-1'}>
        Phase offset of each channel
      </h3>
      <h6 className={h6 + ' text-light-4'}>
        The value can be between 0° - 355°. Only multiples of 5 are allowed.
      </h6>

      <h3 className='my-6 text-light-1'>TX mode</h3>
      <OffsetInputGroups mode='txOffset' />

      <div className='dashedDivide w-full h-[1px] my-6' />

      <h3 className='mb-6 text-light-1'>RX mode</h3>
      <OffsetInputGroups mode='rxOffset' />
    </div>
  )
}

const Footer = () => {
  const dispatch = useDispatch()
  const modalProps = useSelector(state => state.modal.normal.props)
  const { editData, isCreate, isEditSaveRequesting, isEditDeleteRequesting } =
    modalProps

  const {
    id = '',
    name,
    spacingX,
    spacingY,
    thetaMax,
    rxOffset,
    txOffset,
  } = editData

  const nameInvalid = antennaNameIsInvalid(getAntennaName(name))
  const spacingXInvalid = spacingIsInvalid(spacingX)
  const spacingYInvalid = spacingIsInvalid(spacingY)
  const thetaMaxInvalid = thetaMaxIsInvalid(thetaMax)

  const offsetVerify = offsetArray =>
    R.pipe(
      R.filter(e => offsetIsInvalid(e, offsetMin, offsetMax)),
      R.length,
      R.equals(0),
      R.not
    )(offsetArray)
  const rxOffsetInvalid = offsetVerify(rxOffset)
  const txOffsetInvalid = offsetVerify(txOffset)

  const saveIsDisabled =
    nameInvalid ||
    spacingXInvalid ||
    spacingYInvalid ||
    thetaMaxInvalid ||
    rxOffsetInvalid ||
    txOffsetInvalid

  const handleDeleteClick = () =>
    dispatch(bbox_customAntennaModal_deleteClick_watcher([id]))
  const handleSaveClick = () =>
    dispatch(bbox_customAntennaModal_saveClick_watcher())

  if (isCreate)
    return (
      <footer className={footer + ' flex justify-end'}>
        <CommonButton
          type='primary'
          size='lg'
          loading={isEditSaveRequesting}
          onClick={handleSaveClick}
          disabled={saveIsDisabled}>
          Save
        </CommonButton>
      </footer>
    )

  return (
    <footer className={footer + ' flex justify-between gap-x-8'}>
      <CommonButton
        type='outlined'
        size='lg'
        danger
        className='px-4'
        disabled={saveIsDisabled}
        loading={isEditDeleteRequesting}
        onClick={handleDeleteClick}>
        Delete
      </CommonButton>

      <CommonButton
        type='primary'
        size='lg'
        disabled={saveIsDisabled}
        loading={isEditSaveRequesting}
        onClick={handleSaveClick}>
        Save
      </CommonButton>
    </footer>
  )
}
