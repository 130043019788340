import { call, put, take, race } from 'redux-saga/effects'

import { setSingleDeviceData } from 'src/redux/slices/deviceData'
import { sgUIControlForSingleAction as uiControlAction } from 'src/redux/slices/uiControl/freqConverter/sg'

import __socket_API_sender from 'src/redux/sagas/services/socketTools/__socket_API_sender'

import { getCommonArgs } from 'src/redux/sagas/selector/deviceData'

import { devWarLog } from 'src/funcs/tools'

import freqConverterApi from 'src/redux/sagas/services/socketAPI/freqConverter'
import { modalActions } from 'src/redux/slices/modal'

import {
  sg_turnOffOutputCheckModal_okClick_watcher,
  sg_turnOffOutputCheckModal_cancelClick_watcher,
  sg_toExternalModal_useExternalClick_watcher,
  sg_toExternalModal_cancelClick_watcher,
} from 'src/redux/actions/freqConverter/sgAction'

export function* sgDeviceSettingsRefSourceChange(payloads) {
  try {
    const { sn, value } = payloads
    const isToInternal = +value === 0
    const isToExternal = +value === 1
    let { currentData, lookupID } = yield call(getCommonArgs, sn)

    if (isToInternal) {
      yield put(setSingleDeviceData({ sn, data: currentData }))

      yield call(__socket_API_sender, freqConverterApi.SG_SET_REF_SOURCE, {
        sn,
        lookupID,
        refSource: 0,
        inputRefSource: { currentSelection: null },
      })
    }

    if (isToExternal) yield call(sgDeviceSettingsInputSourceChange, { sn })
  } catch (error) {
    devWarLog('[handler] sgDeviceSettingsRefSourceChange error:', error)
  }
}
export function* sgDeviceSettingsOutputSource10MHzChange(payloads) {
  try {
    const { sn, value } = payloads
    let { lookupID } = yield call(getCommonArgs, sn)

    yield call(__socket_API_sender, freqConverterApi.SG_OUTPUT_REF_SOURCE, {
      sn,
      lookupID,
      outputRefSource: value,
    })
  } catch (error) {
    devWarLog('[handler] sgDeviceSettingsOutputSource10MHzChange error:', error)
  }
}

function* _outputRefCheck({ sn }) {
  try {
    let { currentData } = yield call(getCommonArgs, sn)

    const isOutputRefSourceActive =
      currentData.settings.device.outputRefSource === true

    let result = true

    if (isOutputRefSourceActive) {
      yield put(
        modalActions.modal_show({
          priority: 'normal',
          name: 'FREQ_CONVERTER_SG_TO_EXTERNAL_TUNE_OFF_OUTPUT_CHECK',
          props: { sn },
        })
      )

      const { cancel } = yield race({
        ok: take(sg_turnOffOutputCheckModal_okClick_watcher.type),
        cancel: take(sg_turnOffOutputCheckModal_cancelClick_watcher.type),
      })
      if (cancel) {
        yield put(modalActions.modal_hide({ priority: 'normal' }))
        result = false
      }
    }

    return result
  } catch (error) {
    devWarLog('[handler] _outputRefCheck error:', error)
  }
}
function* _useExternalCheck({ sn }) {
  try {
    yield put(
      modalActions.modal_show({
        priority: 'normal',
        name: 'FREQ_CONVERTER_SG_TO_EXTERNAL_CHECK',
        props: { sn },
      })
    )

    const { useExternal, cancel } = yield race({
      useExternal: take(sg_toExternalModal_useExternalClick_watcher.type),
      cancel: take(sg_toExternalModal_cancelClick_watcher.type),
    })

    if (cancel) yield put(modalActions.modal_hide({ priority: 'normal' }))
    if (useExternal) {
      const { lookupID } = yield call(getCommonArgs, sn)

      yield put(
        uiControlAction.setRefSourceIsRequesting({
          sn,
        })
      )

      const inputRefSource = useExternal.payload.inputRefSource
      yield call(__socket_API_sender, freqConverterApi.SG_SET_REF_SOURCE, {
        sn,
        lookupID,
        refSource: 1,
        inputRefSource: { currentSelection: inputRefSource },
      })
    }
  } catch (error) {
    devWarLog('[handler] _useExternalCheck error:', error)
  }
}
export function* sgDeviceSettingsInputSourceChange(payloads) {
  try {
    const { sn } = payloads
    const outputRefCheckPass = yield call(_outputRefCheck, { sn })
    if (outputRefCheckPass) yield call(_useExternalCheck, { sn })
  } catch (error) {
    devWarLog('[handler] sgDeviceSettingsInputSourceChange error:', error)
  }
}
