import { put } from 'redux-saga/effects'

import { getCurrentData } from 'src/redux/sagas/selector/deviceData'
import { setSingleDeviceData } from 'src/redux/slices/deviceData'

import { devWarLog } from 'src/funcs/tools'
import { call } from 'ramda'

import { modalActions } from 'src/redux/slices/modal'

export function* deviceError({ response }) {
  try {
    yield console.log('error init:', response)

    // 只要是 device 沒特別處理的 api error 這邊就把 initialization 改 'failed'
    // 當 initialization = 'failed'
    // 此 bbox 的畫面就會顯示 device were something wrong

    const sn = response.errorInfo.sn
    const currentDeviceData = yield call(getCurrentData, sn)
    currentDeviceData.info.initialization = 'failed'

    yield put(setSingleDeviceData({ sn, data: currentDeviceData }))
  } catch (error) {
    devWarLog('[res-handler] init error:', error)
  }
}

export function* deviceErrorAndClearNormalModal({ response }) {
  try {
    yield console.log('error init:', response)

    // 只要是 device 沒特別處理的 api error 這邊就把 initialization 改 'failed'
    // 當 initialization = 'failed'
    // 此 bbox 的畫面就會顯示 device were something wrong

    const sn = response.errorInfo.sn
    const currentDeviceData = yield call(getCurrentData, sn)
    currentDeviceData.info.initialization = 'failed'

    yield put(setSingleDeviceData({ sn, data: currentDeviceData }))
    yield put(modalActions.modal_hide({ priority: 'normal' }))
  } catch (error) {
    devWarLog('[res-handler] init error:', error)
  }
}
