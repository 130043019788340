import { call, put, select } from 'redux-saga/effects'
import moment from 'moment'

import { devWarLog } from 'src/funcs/tools'

import { modalActions } from 'src/redux/slices/modal'

import __restful_API_sender from 'src/redux/sagas/services/restfulTools/__restful_API_sender'
import { sendEmailAPI } from 'src/redux/sagas/services/restfulAPI/facilityApi'

import {
  createRecipient,
  createCcRecipient,
  createBccRecipient,
  createSubject,
  createHtmlTemplate,
  createTextTemplate,
} from 'src/constants/generalError'

export function* generalError({
  time,
  userID,
  api,
  code,
  codeName,
  message,
  sn,
  deviceType,
  versionInfo,
}) {
  try {
    const { preferredUsername, email } = yield select(state => state.member)

    yield put(
      modalActions.modal_show({
        priority: 'emergency',
        name: 'ERROR_GENERAL',
        props: {
          name: preferredUsername,
          email,
          time,
          userID,
          api,
          sn,
          deviceType,
          hwVersion: versionInfo?.hardware,
          caliTableVersion: versionInfo?.caliTable,
          fwVersion: versionInfo?.firmware,
          code,
          codeName,
          message,
        },
      })
    )
  } catch (error) {
    devWarLog('[handler] generalError error:', error)
    yield put(
      modalActions.modal_show({
        priority: 'emergency',
        name: 'ERROR_GENERAL',
        props: {
          time,
          userID,
          api,
          code,
          codeName,
          message: error.message,
          sn,
          deviceType,
          versionInfo,
        },
      })
    )
  }
}

export function* generalErrorSendMail() {
  try {
    yield put(
      modalActions.modal_props_set({
        priority: 'emergency',
        props: { isRequesting: true },
      })
    )

    yield call(sendErrorMail)

    yield put(
      modalActions.modal_show({
        priority: 'emergency',
        name: 'ERROR_GENERAL_MAIL_SENDED',
        props: {},
      })
    )
  } catch (error) {
    devWarLog('[handler] generalErrorSendMail error:', error)
    yield put(
      modalActions.modal_props_set({
        priority: 'emergency',
        props: { errorMessage: error.message },
      })
    )
    yield call(generalErrorSendMail)
  }
}

export function* deviceErrorSendMail() {
  try {
    yield put(
      modalActions.modal_props_set({
        priority: 'emergency',
        props: { isRequesting: true },
      })
    )

    yield call(sendErrorMail)

    yield put(
      modalActions.modal_show({
        priority: 'emergency',
        name: 'ERROR_DEVICE_ERROR_MAIL_SENDED',
        props: {},
      })
    )
  } catch (error) {
    devWarLog('[handler] deviceErrorSendMail error:', error)
    yield put(
      modalActions.modal_props_set({
        priority: 'emergency',
        props: { errorMessage: error.message },
      })
    )
    yield call(deviceErrorSendMail)
  }
}

function* sendErrorMail() {
  const member = yield select(state => state.member)
  const modalProps = yield select(state => state.modal.emergency.props)

  const { email, preferredUsername } = member
  const {
    comments,
    time,
    userID,
    api,
    sn,
    deviceType,
    hwVersion,
    fwVersion,
    code,
    codeName,
    message,
  } = modalProps

  const ticketNumber = +moment()

  const bodyHtml = createHtmlTemplate({
    ticketNumber,
    email,
    preferredUsername,
    time,
    userID,
    api,
    sn,
    deviceType,
    hwVersion,
    fwVersion,
    code,
    codeName,
    message,
    comments,
  })
  const bodyText = createTextTemplate({
    email,
    preferredUsername,
    time,
    userID,
    api,
    sn,
    deviceType,
    hwVersion,
    fwVersion,
    code,
    codeName,
    message,
    comments,
  })

  yield call(__restful_API_sender, {
    api: sendEmailAPI,
    data: {
      recipient: createRecipient(email),
      ccRecipient: createCcRecipient(),
      bccRecipient: createBccRecipient(),
      subject: createSubject({ email, preferredUsername }),
      bodyHtml,
      bodyText,
    },
  })
}
