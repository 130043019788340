import React from 'react'
import { useDispatch } from 'react-redux'

import Modal from 'src/components/Popup/Modal'
import CommonButton from 'src/components/Button/CommonButton'

import { BiError } from 'react-icons/bi'

import { modalActions } from 'src/redux/slices/modal'

const GetInfoFailed = props => {
  const dispatch = useDispatch()

  return (
    <Modal>
      <Modal.Icon>
        <BiError className='text-red ' />
      </Modal.Icon>

      <Modal.Title>Failed to Connect WEB-TLK Middleware</Modal.Title>

      <Modal.Description>Please contact TMYTEK.</Modal.Description>

      <Modal.ColumnButtonBox>
        <CommonButton
          size='lg'
          type='general'
          onClick={() =>
            dispatch(modalActions.modal_hide({ priority: 'normal' }))
          }>
          OK
        </CommonButton>
      </Modal.ColumnButtonBox>
    </Modal>
  )
}

export default GetInfoFailed
