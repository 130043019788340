import { call, put, take, select, cancelled } from 'redux-saga/effects'

import { setSingleDeviceData } from 'src/redux/slices/deviceData'

import __socket_API_sender from 'src/redux/sagas/services/socketTools/__socket_API_sender'
import freqConverterApi from 'src/redux/sagas/services/socketAPI/freqConverter'

import { getCommonArgs } from 'src/redux/sagas/selector/deviceData'

import { devWarLog } from 'src/funcs/tools'

import { modalActions } from 'src/redux/slices/modal'

import {
  udModule_turnOffOutputCheckModal_okClick_watcher,
  udModule_toExternalCheckModal_useExternalClick_watcher,
} from 'src/redux/actions/freqConverter/udmoduleAction'

import { udmodule_deviceSettings_InternalEnableClick_watcher } from 'src/redux/actions/freqConverter/udmoduleAction'

export function* deviceSettingRefSourceChange(payloads) {
  try {
    const { sn, refSource } = payloads
    let { currentData, lookupID } = yield call(getCommonArgs, sn)

    const outputIsEnable = currentData.settings.device.internal.isEnable

    const toExternal = refSource === 1
    const needCheck = toExternal && outputIsEnable

    // 1. 確認是不是要顯示關掉 output 的 modal
    if (needCheck) {
      yield put(
        modalActions.modal_show({
          priority: 'normal',
          name: 'FREQ_CONVERTER_UDMODULE_TO_EXTERNAL_TUNE_OFF_OUTPUT_CHECK',
          props: { sn },
        })
      )

      yield take(udModule_turnOffOutputCheckModal_okClick_watcher.type)
    }

    // 2. 顯示 to external check 的 modal
    const deviceDataInputFreq = yield select(
      state => state.deviceData[sn].settings.device.external.currentSelection
    )
    if (toExternal) {
      yield put(
        modalActions.modal_show({
          priority: 'normal',
          name: 'FREQ_CONVERTER_UDMODULE_TO_EXTERNAL_CHECK',
          props: {
            sn,
            inputFreq: deviceDataInputFreq,
            isLoading: false,
            isSuccess: false,
          },
        })
      )

      yield take(udModule_toExternalCheckModal_useExternalClick_watcher.type)
    }

    // 3. 更新 FE 的 ref source
    const modalInputFreqSelection = yield select(
      state => state.modal.normal.props.inputFreq
    )
    if (toExternal)
      currentData.settings.device.external.currentSelection =
        modalInputFreqSelection

    currentData.settings.device.refSource = refSource
    yield put(setSingleDeviceData({ sn, data: currentData }))

    if (toExternal)
      yield put(
        modalActions.modal_props_set({
          priority: 'normal',
          props: { isLoading: true },
        })
      )

    const ack = yield call(
      __socket_API_sender,
      freqConverterApi.DEVICE_SET_REF_SOURCE,
      {
        sn,
        lookupID,
        refSource,
        inputFreq: modalInputFreqSelection || 0,
      }
    )

    if (ack === 0) {
      // 如果是切回 internal 而且 output enable 是開的
      // 就要多補 call output api
      const isEnable = currentData.settings.device.internal.isEnable

      if (!toExternal && isEnable)
        yield put(
          udmodule_deviceSettings_InternalEnableClick_watcher({
            sn,
            isEnable,
          })
        )
    }
  } catch (error) {
    devWarLog('[handler] deviceSettingRefSourceChange error:', error)
  } finally {
    if (yield cancelled())
      yield put(modalActions.modal_hide({ priority: 'normal' }))
  }
}
export function* deviceSettingInternalEnableClick(payloads) {
  try {
    const { sn, isEnable } = payloads
    let { currentData, lookupID } = yield call(getCommonArgs, sn)

    currentData.settings.device.internal.isEnable = isEnable
    yield put(setSingleDeviceData({ sn, data: currentData }))

    const currentSelection =
      currentData.settings.device.internal.currentSelection

    yield call(
      __socket_API_sender,
      freqConverterApi.DEVICE_INTERNAL_SET_OUTPUT_FREQ,
      {
        sn,
        lookupID,
        isEnable,
        outputFreq: isEnable && currentSelection,
      }
    )
  } catch (error) {
    devWarLog('[handler] deviceSettingInternalEnableClick error:', error)
  }
}
export function* deviceSettingOutputChange(payloads) {
  try {
    const { sn, outputFreq } = payloads
    let { currentData, lookupID } = yield call(getCommonArgs, sn)

    currentData.settings.device.internal.currentSelection = outputFreq
    yield put(setSingleDeviceData({ sn, data: currentData }))

    yield call(
      __socket_API_sender,
      freqConverterApi.DEVICE_INTERNAL_SET_OUTPUT_FREQ,
      {
        sn,
        lookupID,
        isEnable: true,
        outputFreq,
      }
    )
  } catch (error) {
    devWarLog('[handler] deviceSettingOutputChange error:', error)
  }
}
export function* deviceSettingInputChange(payloads) {
  try {
    const { sn, inputFreq } = payloads
    let { currentData, lookupID } = yield call(getCommonArgs, sn)

    currentData.settings.device.external.currentSelection = inputFreq
    yield put(setSingleDeviceData({ sn, data: currentData }))

    yield call(
      __socket_API_sender,
      freqConverterApi.DEVICE_EXTERNAL_SET_INPUT_FREQ,
      {
        sn,
        lookupID,
        inputFreq,
      }
    )
  } catch (error) {
    devWarLog('[handler] deviceSettingInputChange error:', error)
  }
}
