import { take, call, race, put, select, delay } from 'redux-saga/effects'

import { getCommonArgs } from 'src/redux/sagas/selector/deviceData'

import { setShowLoadingMask } from 'src/redux/slices/uiControl/beamformers/bbox'

import { modalActions } from 'src/redux/slices/modal'

import {
  bbox_customAntennaModal_deleteChildModal_deleteClick_watcher,
  bbox_customAntennaModal_deleteChildModal_cancelClick_watcher,
  bbox_customAntennaModal_usingNotDeleteChildModal_okClick_watcher,
} from 'src/redux/actions/beamformers/bboxAction'
import {
  deviceResetReminderModal_cancelClick_watcher,
  deviceResetReminderModal_yesClick_watcher,
} from 'src/redux/actions/facility/operating'

import { setSingleDeviceData } from 'src/redux/slices/deviceData'

import __socket_API_sender from 'src/redux/sagas/services/socketTools/__socket_API_sender'
import beamformersApi from 'src/redux/sagas/services/socketAPI/beamformers'

import { isType } from 'src/funcs/tools'

import { devWarLog } from 'src/funcs/tools'

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* -----------------      Antenna       -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
export function* commonAntennaSelect(payloads) {
  try {
    yield put(
      modalActions.modal_show({
        priority: 'normal',
        name: 'OPERATING_DEVICE_RESET_REMINDER',
        props: { isLoading: false, isSuccess: false },
      })
    )

    const { cancel, yes } = yield race({
      cancel: take(deviceResetReminderModal_cancelClick_watcher.type),
      yes: take(deviceResetReminderModal_yesClick_watcher.type),
    })

    if (cancel) yield put(modalActions.modal_hide({ priority: 'normal' }))

    if (yes) {
      yield put(modalActions.modal_hide({ priority: 'normal' }))

      const { sn, value } = payloads
      let { currentData, lookupID } = yield call(getCommonArgs, sn)

      yield put(
        setShowLoadingMask({
          sn,
          value: { common: false, steering: true, channel: true },
        })
      )
      yield put(
        modalActions.modal_show({
          priority: 'normal',
          name: 'OPERATING_DEVICE_RESET_REMINDER',
          props: { isLoading: true, isSuccess: false },
        })
      )

      currentData.deviceControl.common.currentAntenna = value

      yield put(setSingleDeviceData({ sn, data: currentData }))

      const ack = yield call(__socket_API_sender, beamformersApi.AAKIT_SELECT, {
        sn,
        lookupID,
        value,
      })

      if (ack === 0) {
        yield put(
          modalActions.modal_show({
            priority: 'normal',
            name: 'OPERATING_DEVICE_RESET_REMINDER',
            props: { isLoading: false, isSuccess: true },
          })
        )
        yield delay(1000)
        yield put(modalActions.modal_hide({ priority: 'normal' }))
      }
    }
  } catch (error) {
    devWarLog('[handler] commonAntennaSelect error:', error)
  }
}

const _dataFormattingBeforeApi = data => {
  try {
    if (isType('array')(data))
      return data.map(item => ({
        ...item,
        deviceType: +item.deviceType,
        thetaMax: +item.thetaMax,
        spacingX: +item.spacingX,
        spacingY: +item.spacingY,
        rxOffset: item.rxOffset.map(e => +e),
        txOffset: item.txOffset.map(e => +e),
      }))

    if (isType('object')(data))
      return {
        ...data,
        deviceType: +data.deviceType,
        thetaMax: +data.thetaMax,
        spacingX: +data.spacingX,
        spacingY: +data.spacingY,
        rxOffset: data.rxOffset.map(e => +e),
        txOffset: data.txOffset.map(e => +e),
      }
  } catch (error) {
    devWarLog('[handler] _dataFormattingBeforeApi error:', error)
  }
}

function* antennaCallInsertApi({ sn, validData = [], invalidData = [] }) {
  try {
    const { lookupID } = yield call(getCommonArgs, sn)

    yield call(__socket_API_sender, beamformersApi.AAKIT_INSERT, {
      sn,
      lookupID,
      lstValidData: _dataFormattingBeforeApi(validData),
      lstImportFailed: invalidData,
    })
  } catch (error) {
    devWarLog('[handler] antennaCallInsertApi error:', error)
  }
}

export function* commonAntennaImport(payloads) {
  try {
    const { sn, validData, invalidData } = payloads

    const filesCountMax = 10

    const allFilesLength = [...validData, ...invalidData].length

    const feInvalidFilenameList = invalidData.map(e => e.filename)

    if (allFilesLength > filesCountMax) {
      yield put(
        modalActions.modal_show({
          priority: 'normal',
          name: 'FILE_IMPORT_EXCEEDED_MAX',
          props: { filesCountMax },
        })
      )
    } else {
      if (!validData.length)
        yield put(
          modalActions.modal_show({
            priority: 'normal',
            name: 'FILE_IMPORT_FAILED',
            props: {
              failedList: feInvalidFilenameList,
            },
          })
        )

      if (validData.length) {
        yield put(
          modalActions.modal_show({
            priority: 'normal',
            name: 'FILE_IMPORT_IMPORTING',
            props: {},
          })
        )

        //! 注意：兩者資料格式不一樣
        // validData = [...antennaData (obj) ]
        // invalidData = [...antennaName (str) ]

        // 這裡沒有用傳統 import file api 的做法
        // 這邊直接比照新增 antenna 的做法進行
        yield call(antennaCallInsertApi, {
          sn,
          validData,
          invalidData: feInvalidFilenameList,
        })
      }
    }
  } catch (error) {
    devWarLog('[handler] commonAntennaImport error:', error)
  }
}

export function* customAntennaSave(payloads) {
  try {
    const modalProps = yield select(state => state.modal.normal.props)
    const { sn, isCreate, editData } = modalProps

    yield put(
      modalActions.modal_props_set({
        priority: 'normal',
        props: {
          isEditSaveRequesting: true,
        },
      })
    )

    // new data 跟 import 一起用 insert api
    if (isCreate) {
      yield call(antennaCallInsertApi, {
        sn,
        validData: [editData],
        invalidData: [],
      })
    }

    // 既有資料 用 update (這些 data 裡面已包含 be 產生的 id)
    if (!isCreate) {
      const { lookupID } = yield call(getCommonArgs, sn)

      yield call(__socket_API_sender, beamformersApi.AAKIT_UPDATE, {
        sn,
        lookupID,
        data: _dataFormattingBeforeApi(editData),
      })
    }
  } catch (error) {
    devWarLog('[handler] customAntennaSave error:', error)
  }
}

export function* customAntennaCreate(payloads) {
  try {
    const { sn } = payloads

    const { boardCount, channelCount } = yield select(
      state => state.deviceData[sn].deviceControl.channel
    )
    const deviceType = yield select(
      state => state.deviceData[sn].info.deviceType
    )

    const offsetCount = boardCount * channelCount

    const newAntennaData = {
      filename: '',
      name: 'Unnamed antenna',
      deviceType,
      type: 'customized',
      spacingX: 0,
      spacingY: 0,
      thetaMax: 0,
      rxOffset: new Array(offsetCount).fill(0), // List[float]
      txOffset: new Array(offsetCount).fill(0),
    }

    yield put(
      modalActions.modal_props_set({
        priority: 'normal',
        props: {
          selectedIdList: [],
          isCreate: true,
          editData: newAntennaData,
          child_modal_show: '',
        },
      })
    )
  } catch (error) {
    devWarLog('[handler] customAntennaCreate error:', error)
  }
}

export function* customAntennaLeaveEdit(payloads) {
  try {
    const modalProps = yield select(state => state.modal.normal.props)
    const { isEditChanged, isCreate } = modalProps

    if (isEditChanged || isCreate)
      yield put(
        modalActions.modal_props_set({
          priority: 'normal',
          props: {
            child_modal_show: 'LEAVE_EDIT_CHECK',
          },
        })
      )
    else
      yield put(
        modalActions.modal_props_set({
          priority: 'normal',
          props: {
            editData: {},
            selectedIdList: [],
            child_modal_show: '',
            isCreate: false,
            isEditChanged: false,
            isEditSaveRequesting: false,
            isEditDeleteRequesting: false,
          },
        })
      )
  } catch (error) {
    devWarLog('[handler] customAntennaLeaveEdit error:', error)
  }
}

function* _usingVerify({ sn, deleteList }) {
  try {
    const deviceData = yield select(state => state.deviceData)
    // 撈出全部 device 使用中的 currentAntenna
    const currentAntennaList = Object.entries(deviceData).map(([sn, data]) => ({
      currentAntenna: data?.deviceControl?.common?.currentAntenna,
    }))

    // 拿到當前 sn device 的 lstAntennaData
    // 因為 deleteList 只有存 id, 我們需要拿到該 id 對應的 name 才有辦法比對
    // 而客製化天線只會顯示當前 sn 的 lstAntennaData 裡面是 customized 的那幾筆
    // 所以直接撈 lstAntennaData 去比對就可以了
    const lstAntennaData = deviceData[sn].deviceControl.common.lstAntennaData

    const deleteListAppendName = deleteList.map(id => ({
      id,
      name: lstAntennaData.find(e => e.id === id).name,
    }))

    const { hadUsingData, withoutUsingDeleteList } =
      deleteListAppendName.reduce(
        (prev, { id, name }) => {
          const notUsing =
            currentAntennaList.find(e => e.currentAntenna === name) ===
            undefined

          if (!notUsing) return { ...prev, hadUsingData: true }

          return {
            ...prev,
            withoutUsingDeleteList: [...prev.withoutUsingDeleteList, id],
          }
        },
        { hadUsingData: false, withoutUsingDeleteList: [] }
      )

    return { hadUsingData, withoutUsingDeleteList }
  } catch (error) {
    devWarLog('[handler] _usingVerify error:', error)
  }
}

function* _getIsEditMode() {
  try {
    const editData = yield select(state => state.modal.normal.props.editData)
    const isEditMode = Object.keys(editData).length !== 0

    return isEditMode
  } catch (error) {
    devWarLog('[handler] _getIsEditMode error:', error)
  }
}

export function* customAntennaDelete(payloads = []) {
  try {
    // payloads = [...id] || 'all'
    const sn = yield select(state => state.modal.normal.props.sn)

    let selectedIdList = payloads

    const { hadUsingData, withoutUsingDeleteList } = yield call(_usingVerify, {
      sn,
      deleteList: selectedIdList,
    })

    if (hadUsingData) {
      yield put(
        modalActions.modal_props_set({
          priority: 'normal',
          props: {
            child_modal_show: 'USING_NOT_DELETE_CHECK',
          },
        })
      )
      yield take(
        bbox_customAntennaModal_usingNotDeleteChildModal_okClick_watcher.type
      )
      // show using modal
    }

    if (!withoutUsingDeleteList.length)
      yield put(
        modalActions.modal_props_set({
          priority: 'normal',
          props: {
            child_modal_show: '',
          },
        })
      )

    if (withoutUsingDeleteList.length) {
      const isEditMode = yield call(_getIsEditMode)

      if (isEditMode)
        yield put(
          modalActions.modal_props_set({
            priority: 'normal',
            props: {
              child_modal_show: 'EDIT_MODE_DELETE_CHECK',
            },
          })
        )
      else
        yield put(
          modalActions.modal_props_set({
            priority: 'normal',
            props: {
              child_modal_show: 'LIST_MODE_DELETE_CHECK',
            },
          })
        )

      const { deleteClick, cancelClick } = yield race({
        deleteClick: take(
          bbox_customAntennaModal_deleteChildModal_deleteClick_watcher.type
        ),
        cancelClick: take(
          bbox_customAntennaModal_deleteChildModal_cancelClick_watcher.type
        ),
      })

      if (cancelClick)
        yield put(
          modalActions.modal_props_set({
            priority: 'normal',
            props: { child_modal_show: '' },
          })
        )

      if (deleteClick) {
        const { lookupID } = yield call(getCommonArgs, sn)

        yield put(
          modalActions.modal_props_set({
            priority: 'normal',
            props: { isRequesting: true },
          })
        )

        yield call(__socket_API_sender, beamformersApi.AAKIT_DELETE, {
          sn,
          lookupID,
          data: withoutUsingDeleteList,
        })
      }
    }
  } catch (error) {
    devWarLog('[handler] customAntennaDelete error:', error)
  }
}

export function* customAntennaExport() {
  // antenna export 在 phase 2 還不用 support
}
