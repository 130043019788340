import React, { useState, useRef } from 'react'
import { useDispatch } from 'react-redux'
import AvatarEditor from 'react-avatar-editor'
import { Auth } from 'aws-amplify'

import Modal from 'src/components/Popup/Modal'
import IconButton from 'src/components/Button/IconButton'
import CommonButton from 'src/components/Button/CommonButton'
import CommonSlider from 'src/components/Slider/CommonSlider'

import { BiPlus, BiMinus } from 'react-icons/bi'

import { modalActions } from 'src/redux/slices/modal'
import { setMemberAttribute } from 'src/redux/slices/uiControl/facility/member'
import { memberAvatarUploadAPI } from 'src/redux/sagas/services/restfulAPI/facilityApi'

import { devWarLog } from 'src/funcs/tools'
import { __ngf_restful_API_sender } from 'src/redux/sagas/services/restfulTools/__restful_API_sender'

const scaleMin = 1
const scaleMax = 10

const ChangeProfilePhotoEdit = props => {
  const dispatch = useDispatch()
  const editorRef = useRef(null)
  const [position, setPosition] = useState({ x: 0.5, y: 0.5 })
  const [scale, setScale] = useState(1)
  const [errorMessage, setErrorMessage] = useState('')

  const { uploadFileState } = props
  const [uploadFile] = uploadFileState

  const handlePositionChange = position => {
    setPosition(position)
  }

  const handleSave = async event => {
    try {
      if (editorRef.current && uploadFile) {
        const user = await Auth.currentAuthenticatedUser()
        const canvas = editorRef.current.getImageScaledToCanvas()

        // to base64 string
        // ex: 'data:image/jpeg;base64,/9j/4AAQSkZJ.....'
        const dataUrl = canvas.toDataURL()
        // 給 BE 舊的頭像圖片名稱 （要刪掉）
        const avatarImageID = user.attributes.picture.split('amazonaws.com/')[1]

        const formData = new FormData()
        formData.append('image', dataUrl)
        formData.append('avatarImageID', avatarImageID)
        formData.append('userID', user.attributes.sub)

        const payload = { api: memberAvatarUploadAPI, data: formData }
        const response = await __ngf_restful_API_sender(payload)

        const picture = response.data.data.imageUrl

        const responseSuccess = response.data.code === 0
        const responseFailed = response.data.code !== 0

        if (responseFailed) throw Error('upload failed')

        if (responseSuccess) {
          await Auth.updateUserAttributes(user, {
            picture,
          })

          dispatch(setMemberAttribute({ picture }))
          dispatch(modalActions.modal_hide({ priority: 'normal' }))
        }
      }
    } catch (error) {
      devWarLog('ChangeProfilePhotoEdit error:', error)
      setErrorMessage(error?.message)
    }
  }

  return (
    <Modal>
      <Modal.Title>Change Profile Photo</Modal.Title>

      {errorMessage && <div className='text-red my-6'>{errorMessage}</div>}

      <div className={editContainerClass}>
        {uploadFile && (
          <AvatarEditor
            className='mb-4'
            ref={editorRef}
            image={uploadFile}
            width={144}
            height={144}
            borderRadius={144}
            border={0}
            color={[34, 34, 34, 1]} // 设置边框颜色，透明度为 0.6
            scale={scale}
            position={position}
            onPositionChange={handlePositionChange}
          />
        )}

        <div className='w-full flex items-center gap-x-2'>
          <IconButton
            type='standard'
            size='lg'
            className='shrink-0'
            onClick={() => setScale(prev => Math.max(prev - 1, scaleMin))}>
            <BiMinus />
          </IconButton>

          <CommonSlider
            value={scale}
            onChange={value => setScale(value)}
            min={scaleMin}
            max={scaleMax}
            step={1}
          />

          <IconButton
            type='standard'
            size='lg'
            className='shrink-0'
            onClick={() => setScale(prev => Math.min(prev + 1, scaleMax))}>
            <BiPlus />
          </IconButton>
        </div>
      </div>

      <Modal.RowButtonBox>
        <CommonButton
          size='lg'
          type='outlined'
          onClick={() =>
            dispatch(modalActions.modal_hide({ priority: 'normal' }))
          }>
          Cancel
        </CommonButton>
        <CommonButton size='lg' type='primary' onClick={handleSave}>
          Save
        </CommonButton>
      </Modal.RowButtonBox>
    </Modal>
  )
}

export default ChangeProfilePhotoEdit

const editContainerClass = `w-full max-w-[296px] 
                            flex flex-col justify-center items-center 
                            gap-y-4 pt-10 mx-auto 
                            `
