import React, { useState, useRef, useEffect } from 'react'
import * as R from 'ramda'
import { useDispatch, useSelector } from 'react-redux'

import CommonInput from 'src/components/Input/CommonInput'
import Tooltip from 'src/components/Popup/Tooltip'

import { head } from 'src/containers/Modal/Normal/beamformers/bbox/DeviceController/CustomAntenna/style'
import { h2, h6 } from 'src/containers/Modal/style'

import { BiArrowBack } from 'react-icons/bi'

import {
  getAntennaName,
  getAntennaTimestamp,
  nameIsTooLong,
  nameIsIllegal,
  nameIsEmpty,
  antennaNameIsInvalid,
} from 'src/funcs/device/bbox'

import { bbox_customAntennaModal_leaveEditMode_watcher } from 'src/redux/actions/beamformers/bboxAction'
import { modalActions } from 'src/redux/slices/modal'

const Head = () => {
  const dispatch = useDispatch()
  const modalProps = useSelector(state => state.modal.normal.props)
  const { editData, isCreate } = modalProps

  const nameRef = useRef(null)
  const [nameIsFocus, setNameIsFocus] = useState(false)

  const name = getAntennaName(editData.name)

  // 新增時沒有 timestamp
  // edit 才需要把原本的 timestamp 加回去
  const timestamp = !isCreate ? `_${getAntennaTimestamp(editData.name)}` : ''

  const nameIsInvalid = antennaNameIsInvalid(name)

  const nameBorderBottomBg = nameIsInvalid
    ? `bg-red`
    : nameIsFocus
    ? `bg-teal-5`
    : `bg-transparent`

  const nameBorderBottom = `${nameBorderBottomBg} w-full h-[2px] duration-500`

  const nameInvalidHint = `${h6} block w-full text-red text-center first:mt-2 mt-1`
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------     Life Cycle     -----------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  useEffect(() => {
    if (isCreate && nameRef?.current) nameRef?.current?.focus()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------       Event        -----------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------

  const handleBackClick = () =>
    dispatch(bbox_customAntennaModal_leaveEditMode_watcher())

  const handleNameChange = e =>
    dispatch(
      modalActions.modal_props_set({
        priority: 'normal',
        props: {
          isEditChanged: true,
          editData: R.assoc('name', e.formattedValue + timestamp)(editData),
        },
      })
    )

  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------        JSX         -----------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  return (
    <header
      className={`${head} ${h2} relative text-light-1 pt-6 pb-6 px-[84px]`}>
      <div
        className='absolute left-[44px] top-[36px] cursor-pointer'
        onClick={handleBackClick}>
        <BiArrowBack />
      </div>

      <Tooltip content='Rename' placement='bottom' enableDismiss={true}>
        <CommonInput
          ref={nameRef}
          inputType='text'
          value={name}
          size='lg'
          autoFocus={isCreate}
          placeholder='Custom antenna name'
          className='w-full shrink-0 bg-transparent hover:bg-black/30 border-none '
          inputClassName='text-center bg-transparent'
          onFocus={() => setNameIsFocus(true)}
          onBlur={() => setNameIsFocus(false)}
          onChange={handleNameChange}
        />
      </Tooltip>
      <div className={nameBorderBottom} />

      {nameIsIllegal(name) && (
        <small
          className={
            nameInvalidHint
            // eslint-disable-next-line no-useless-escape
          }>{`File name can’t contain any of the following characters: ? ” “/ \ . ; < > * | : `}</small>
      )}

      {nameIsTooLong(name) && (
        <small className={nameInvalidHint}>
          Maximum 60 characters. {name?.length} / 60
        </small>
      )}

      {nameIsEmpty(name) && (
        <small className={nameInvalidHint}>Required field</small>
      )}
    </header>
  )
}

export default Head
