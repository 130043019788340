import { call, put, delay } from 'redux-saga/effects'

import { setSingleDeviceData } from 'src/redux/slices/deviceData'

import { getCommonArgs } from 'src/redux/sagas/selector/deviceData'

import { devWarLog } from 'src/funcs/tools'

import { sgUIControlForSingleAction as uiControlAction } from 'src/redux/slices/uiControl/freqConverter/sg'
import { modalActions } from 'src/redux/slices/modal'

export function* sgDeviceSettingsRefSourceChange(response) {
  try {
    const [sn, { refSource, inputRefSource, ledIndicators }] = Object.entries(
      response?.data
    )[0]

    let { currentData } = yield call(getCommonArgs, sn)

    currentData.settings.device.refSource = refSource
    currentData.settings.device.inputRefSource.currentSelection =
      inputRefSource?.currentSelection

    const isToExternal = +refSource === 1
    if (isToExternal) {
      // spec: 切到 external 的話要把 output ref source 關掉
      currentData.settings.device.outputRefSource = false

      yield put(
        uiControlAction.setRefSourceIsSuccess({
          sn,
        })
      )

      yield delay(1000)
      yield put(modalActions.modal_all_hide())
      yield put(uiControlAction.clearRefSourceStatus({ sn }))
    }

    // spec: 每次切 source 都要更新燈號
    currentData.current.ledIndicators = ledIndicators
    yield put(setSingleDeviceData({ sn, data: currentData }))
  } catch (error) {
    devWarLog('[response] sgDeviceSettingsRefSourceChange error:', error)
  }
}
export function* sgDeviceSettingsOutputSource10MHzChange(response) {
  try {
    const [sn, { outputRefSource, ledIndicators }] = Object.entries(
      response?.data
    )[0]
    let { currentData } = yield call(getCommonArgs, sn)
    currentData.current.ledIndicators = ledIndicators
    currentData.settings.device.outputRefSource = outputRefSource
    yield put(setSingleDeviceData({ sn, data: currentData }))
  } catch (error) {
    devWarLog(
      '[response] sgDeviceSettingsOutputSource10MHzChange error:',
      error
    )
  }
}
