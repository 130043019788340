import React from 'react'
import PropTypes from 'prop-types'

import SwitchGroup from 'src/components/Switch/SwitchGroup'
import Theta from 'src/components/composite/InputGroupAppendDialog/Theta'
import Phi from 'src/components/composite/InputGroupAppendDialog/Phi'
import Gain from 'src/components/composite/InputGroupAppendDialog/Gain'

import { getDeviceInfo } from 'src/funcs/getDeviceInfo'

import { phiIcon } from 'src/constants/beamformers'

const InputControl = ({
  deviceType,
  theta,
  phi,
  beamCurrentGain,
  thetaMax,
  beamGainMin,
  beamGainMax,
  handleInputChange,
}) => {
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------       State        -----------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------

  const isBBoxLite = getDeviceInfo[deviceType].devName.second === 'lite'

  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------        JSX         -----------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  return (
    <div className={container}>
      <div className='w-full flex justify-start gap-x-4 xl:gap-x-6 '>
        <Theta
          value={String(theta)}
          thetaMax={thetaMax}
          onChange={({ value, isValid }) =>
            handleInputChange({ label: 'theta', value, isValid })
          }
        />

        <div className={itemBox}>
          {isBBoxLite ? (
            <SwitchGroup
              className='w-full '
              size='lg'
              value={+phi}
              icon={phiIcon}
              text1={0}
              text2={180}
              unit='˚'
              onClick={() =>
                handleInputChange({
                  label: 'phi',
                  value: +phi === 0 ? 180 : 0,
                  isValid: true,
                })
              }
            />
          ) : (
            <Phi
              value={String(phi)}
              onChange={({ value, isValid }) =>
                handleInputChange({
                  label: 'phi',
                  value,
                  isValid,
                })
              }
            />
          )}
        </div>

        <Gain
          className='hidden xl:hidden md:block'
          value={String(beamCurrentGain)}
          gainMin={beamGainMin}
          gainMax={beamGainMax}
          onChange={({ value, isValid }) =>
            handleInputChange({ label: 'gain', value, isValid })
          }
        />
      </div>

      {/* 手機版專用 gain */}

      <Gain
        className='block xl:block md:hidden mt-4'
        value={String(beamCurrentGain)}
        gainMin={beamGainMin}
        gainMax={beamGainMax}
        onChange={({ value, isValid }) =>
          handleInputChange({ label: 'gain', value, isValid })
        }
      />
    </div>
  )
}

export default InputControl

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* -----------------     Static CSS     -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
const container = `[BeamSteering-InputControl-container]
                    flex 
                    xl:flex-col md:flex-row sm:flex-col
                    xl:justify-stretch md:justify-center 
                    gap-x-4 
                    `
const itemBox = `flex flex-col w-full `

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* -----------------   Type  validate   -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
InputControl.propTypes = {
  theta: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  phi: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  handleInputChange: PropTypes.func,
}
