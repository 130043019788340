import React from 'react'

import WelcomeDemo from 'src/containers/Modal/Normal/WelcomeDemo'

import FileImporting from 'src/containers/Modal/Normal/FilesImport/FileImporting'
import FileImportSuccessful from 'src/containers/Modal/Normal/FilesImport/FileImportSuccessful'
import FileFailed from 'src/containers/Modal/Normal/FilesImport/FileImportFailed'
import FileExceededMax from 'src/containers/Modal/Normal/FilesImport/FileImportExceededMax'

import FileExporting from 'src/containers/Modal/Normal/FilesExport/FileExporting'
import FileExportFailed from 'src/containers/Modal/Normal/FilesExport/FileExportFailed'
import FileExportSuccessful from 'src/containers/Modal/Normal/FilesExport/FileExportSuccessful'

import MidNotRunning from 'src/containers/Modal/Normal/Middleware/NotRunning'
import MidGetInfoFailed from 'src/containers/Modal/Normal/Middleware/GetInfoFailed'
import MidDownloadNewMid from 'src/containers/Modal/Normal/Middleware/DownloadNewMid'
import MidConnectAwsFailed from 'src/containers/Modal/Normal/Middleware/ConnectAwsFailed'

import DeviceResetReminder from 'src/containers/Modal/Normal/Operating/DeviceResetReminder'

import AnimationTMYLogoLongTime from 'src/containers/Modal/Normal/Lottie/AnimationTMYLogoLongTime'
import AnimationTMYLogoShortTime from 'src/containers/Modal/Normal/Lottie/AnimationTMYLogoShortTime'
import AnimationTMYLogoLoginWelcome from 'src/containers/Modal/Normal/Lottie/AnimationTMYLogoLoginWelcome'

import HomeCaliReminder from 'src/containers/Modal/Normal/home/CaliReminder'
import HomeLeaveDeviceInfoCheck from 'src/containers/Modal/Normal/home/LeaveDeviceInfoCheck'
import HomeApplyDeviceInfoCheck from 'src/containers/Modal/Normal/home/ApplyDeviceInfoCheck'

import UnlockInput from 'src/containers/Modal/Normal/freqConverter/general/freqSetting/UnlockInput'
import UnlockRequesting from 'src/containers/Modal/Normal/freqConverter/general/freqSetting/UnlockRequesting'
import UnlockSuccess from 'src/containers/Modal/Normal/freqConverter/general/freqSetting/UnlockSuccess'
import UDBoxRefSourceCheck from 'src/containers/Modal/Normal/freqConverter/udbox/RefSourceCheck'
import UDBoxToExternalError from 'src/containers/Modal/Normal/freqConverter/udbox/ToExternalError'
import UDModuleTurnOffOutputCheck from 'src/containers/Modal/Normal/freqConverter/udModule/TurnOffOutputCheck'
import UDModuleToExternalCheck from 'src/containers/Modal/Normal/freqConverter/udModule/ToExternalCheck'
import UDModuleToExternalError from 'src/containers/Modal/Normal/freqConverter/udModule/ToExternalError'
import UD0630ToExternalCheck from 'src/containers/Modal/Normal/freqConverter/udbox0630/ToExternalCheck'
import UD0630TurnOffOutputCheck from 'src/containers/Modal/Normal/freqConverter/udbox0630/TurnOffOutputCheck'
import SGToExternalCheck from 'src/containers/Modal/Normal/freqConverter/sg/ToExternalCheck'
import SGTurnOffOutputCheck from 'src/containers/Modal/Normal/freqConverter/sg/TurnOffOutputCheck'

import BBoxCommonCustomAntenna from 'src/containers/Modal/Normal/beamformers/bbox/DeviceController/CustomAntenna'
import BBoxBeamConfigEditorEditBeam from 'src/containers/Modal/Normal/beamformers/bbox/BeamConfigEditor/EditBeam'
import BBoxBeamConfigEditorImportNewSettings from 'src/containers/Modal/Normal/beamformers/bbox/BeamConfigEditor/import/NewSettings'
import BBoxBeamConfigEditorImportNoMatchedAntenna from 'src/containers/Modal/Normal/beamformers/bbox/BeamConfigEditor/import/NoMatchedAntenna'
import BBoxBeamConfigEditorImportIncorrectFormat from 'src/containers/Modal/Normal/beamformers/bbox/BeamConfigEditor/import/IncorrectFormat'
import BBoxBeamConfigEditorImportIncorrectSn from 'src/containers/Modal/Normal/beamformers/bbox/BeamConfigEditor/import/IncorrectSn'
import BBoxBeamConfigEditorExportUnsavedChangesReminder from 'src/containers/Modal/Normal/beamformers/bbox/BeamConfigEditor/export/UnsavedChangesReminder'
import BBoxBeamConfigEditorCurrentMethodSwitching from 'src/containers/Modal/Normal/beamformers/bbox/BeamConfigEditor/CurrentMethodSwitching'
import BBoxBeamConfigEditorSaveNoAntennaAndBeamExist from 'src/containers/Modal/Normal/beamformers/bbox/BeamConfigEditor/save/NoAntennaAndBeamExist'
import BBoxBeamConfigEditorSaveGeneralFailed from 'src/containers/Modal/Normal/beamformers/bbox/BeamConfigEditor/save/GeneralFailed'
import BBoxSaveToBeamConfig from 'src/containers/Modal/Normal/beamformers/bbox/DeviceController/SaveToBeamConfig'
import CloverCellPolarPremiumContactTMYTEK from 'src/containers/Modal/Normal/beamformers/cloverCell/Polarization/PremiumContactTMYTEK'
import CloverCellCttcToExternalCheck from 'src/containers/Modal/Normal/beamformers/cloverCell/Cttc/ToExternalCheck'
import CloverCellCttcToExternalError from 'src/containers/Modal/Normal/beamformers/cloverCell/Cttc/ToExternalError'

import PDCustomCalibration from 'src/containers/Modal/Normal/PowerDetector/CustomCalibration'
import PDCaliConfigFileChanged from 'src/containers/Modal/Normal/PowerDetector/actionPanel/CaliConfigFileChanged'
import PDCaliConfigFileChanging from 'src/containers/Modal/Normal/PowerDetector/actionPanel/CaliConfigFileChanging'
import PDChangeAnotherCaliConfigFile from 'src/containers/Modal/Normal/PowerDetector/actionPanel/ChangeAnotherCaliConfigFile'
import PDFailedToChangeCaliConfig from 'src/containers/Modal/Normal/PowerDetector/actionPanel/FailedToChangeCaliConfig'
import PDClearTheCurrentlyReadData from 'src/containers/Modal/Normal/PowerDetector/dashboard/ClearTheCurrentlyReadData'

import MemberChangeProfilePhoto from 'src/containers/Modal/Normal/Member/ChangeProfilePhoto'
import MemberChangeName from 'src/containers/Modal/Normal/Member/ChangeName'
import MemberChangePassword from 'src/containers/Modal/Normal/Member/ChangePassword'

import GetQuoteChooseDeviceAndPurpose from 'src/containers/Modal/Normal/GetQuote/ChooseDeviceAndPurpose'
import GetQuotePersonalInformation from 'src/containers/Modal/Normal/GetQuote/PersonalInformation'
import GetQuoteSuccess from 'src/containers/Modal/Normal/GetQuote/Success'

const NormalModal = ({ name, props }) => {
  // Welcome Demo
  // Welcome Demo
  // Welcome Demo
  if (name === 'WELCOME_DEMO') return <WelcomeDemo {...props} />

  // File Import
  // File Import
  // File Import
  if (name === 'FILE_IMPORT_IMPORTING') return <FileImporting {...props} />
  if (name === 'FILE_IMPORT_SUCCESS') return <FileImportSuccessful {...props} />
  if (name === 'FILE_IMPORT_FAILED') return <FileFailed {...props} />
  if (name === 'FILE_IMPORT_EXCEEDED_MAX') return <FileExceededMax {...props} />

  // File Export
  // File Export
  // File Export
  if (name === 'FILE_EXPORT_EXPORTING') return <FileExporting {...props} />
  if (name === 'FILE_EXPORT_SUCCESS') return <FileExportSuccessful {...props} />
  if (name === 'FILE_EXPORT_FAILED') return <FileExportFailed {...props} />

  // Middleware
  // Middleware
  // Middleware
  if (name === 'MIDDLEWARE_NOT_RUNNING') return <MidNotRunning {...props} />
  if (name === 'MIDDLEWARE_GET_INFO_FAILED')
    return <MidGetInfoFailed {...props} />
  if (name === 'MIDDLEWARE_DOWNLOAD_NEW_MID')
    return <MidDownloadNewMid {...props} />
  if (name === 'MIDDLEWARE_CONNECT_AWS_FAILED')
    return <MidConnectAwsFailed {...props} />

  // Lottie Animation
  // Lottie Animation
  // Lottie Animation
  if (name === 'ANIMATION_TMYTEK_LOGO_LONG_TIME')
    return <AnimationTMYLogoLongTime {...props} />
  if (name === 'ANIMATION_TMYTEK_LOGO_SHORT_TIME')
    return <AnimationTMYLogoShortTime {...props} />
  if (name === 'ANIMATION_TMYTEK_LOGO_LOGIN_WELCOME')
    return <AnimationTMYLogoLoginWelcome {...props} />

  // Home
  // Home
  // Home
  if (name === 'HOME_CALI_REMINDER') return <HomeCaliReminder {...props} />
  if (name === 'HOME_LEAVE_DEVICE_INFO_CHECK')
    return <HomeLeaveDeviceInfoCheck {...props} />
  if (name === 'HOME_APPLY_DEVICE_INFO_CHECK')
    return <HomeApplyDeviceInfoCheck {...props} />

  // Operating
  // Operating
  // Operating
  if (name === 'OPERATING_DEVICE_RESET_REMINDER')
    return <DeviceResetReminder {...props} />

  // Freq Converter
  // Freq Converter
  // Freq Converter
  // ----- unlock
  if (name === 'FREQ_CONVERTER_UNLOCK_INPUT') return <UnlockInput {...props} />
  if (name === 'FREQ_CONVERTER_UNLOCK_REQUESTING')
    return <UnlockRequesting {...props} />
  if (name === 'FREQ_CONVERTER_UNLOCK_SUCCESS')
    return <UnlockSuccess {...props} />
  // ----- ud box
  if (name === 'FREQ_CONVERTER_UDBOX_TO_EXTERNAL_CHECK')
    return <UDBoxRefSourceCheck {...props} />
  if (name === 'FREQ_CONVERTER_UDBOX_TO_EXTERNAL_ERROR')
    return <UDBoxToExternalError {...props} />
  // ----- ud module
  if (name === 'FREQ_CONVERTER_UDMODULE_TO_EXTERNAL_TUNE_OFF_OUTPUT_CHECK')
    return <UDModuleTurnOffOutputCheck {...props} />
  if (name === 'FREQ_CONVERTER_UDMODULE_TO_EXTERNAL_CHECK')
    return <UDModuleToExternalCheck {...props} />
  if (name === 'FREQ_CONVERTER_UDMODULE_TO_EXTERNAL_ERROR')
    return <UDModuleToExternalError {...props} />
  // ----- ud 0630
  if (name === 'FREQ_CONVERTER_UD0630_TO_EXTERNAL_TUNE_OFF_OUTPUT_CHECK')
    return <UD0630TurnOffOutputCheck {...props} />
  if (name === 'FREQ_CONVERTER_UD0630_TO_EXTERNAL_CHECK')
    return <UD0630ToExternalCheck {...props} />
  // ----- sg
  if (name === 'FREQ_CONVERTER_SG_TO_EXTERNAL_TUNE_OFF_OUTPUT_CHECK')
    return <SGTurnOffOutputCheck {...props} />
  if (name === 'FREQ_CONVERTER_SG_TO_EXTERNAL_CHECK')
    return <SGToExternalCheck {...props} />

  // Beamformers
  // Beamformers
  // Beamformers
  // ----- bbox
  // ---------- common
  if (name === 'BBOX_COMMON_CUSTOM_ANTENNA')
    return <BBoxCommonCustomAntenna {...props} />
  // ---------- beam config editor
  if (name === 'BBOX_BEAM_CONFIG_EDITOR_EDIT_BEAM')
    return <BBoxBeamConfigEditorEditBeam {...props} />
  // ---------- beam config editor (Save)
  if (name === 'BBOX_BEAM_CONFIG_EDITOR_SAVE_NO_ANTENNA_AND_BEAM_EXIST')
    return <BBoxBeamConfigEditorSaveNoAntennaAndBeamExist {...props} />
  if (name === 'BBOX_BEAM_CONFIG_EDITOR_SAVE_GENERAL_FAILED')
    return <BBoxBeamConfigEditorSaveGeneralFailed {...props} />
  // ---------- beam config editor (Current Method Switching)
  if (name === 'BBOX_BEAM_CONFIG_EDITOR_CURRENT_METHOD_SWITCHING')
    return <BBoxBeamConfigEditorCurrentMethodSwitching {...props} />
  // ---------- beam config editor (Import)
  if (name === 'BBOX_BEAM_CONFIG_EDITOR_IMPORT_NEW_SETTINGS')
    return <BBoxBeamConfigEditorImportNewSettings {...props} />
  if (name === 'BBOX_BEAM_CONFIG_EDITOR_IMPORT_NO_MATCHED_ANTENNA')
    return <BBoxBeamConfigEditorImportNoMatchedAntenna {...props} />
  if (name === 'BBOX_BEAM_CONFIG_EDITOR_IMPORT_INCORRECT_FORMAT')
    return <BBoxBeamConfigEditorImportIncorrectFormat {...props} />
  if (name === 'BBOX_BEAM_CONFIG_EDITOR_IMPORT_INCORRECT_SN')
    return <BBoxBeamConfigEditorImportIncorrectSn {...props} />
  // ---------- beam config editor (Export)
  if (name === 'BBOX_BEAM_CONFIG_EDITOR_EXPORT_UNSAVED_CHANGES')
    return <BBoxBeamConfigEditorExportUnsavedChangesReminder {...props} />

  // ---------- save to beam config
  if (name === 'BBOX_SAVE_TO_BEAM_CONFIG')
    return <BBoxSaveToBeamConfig {...props} />
  // ----- clover cell evb
  if (name === 'CLOVER_CELL_POLARIZATION_PREMIUM_CONTACT_TMYTEK')
    return <CloverCellPolarPremiumContactTMYTEK {...props} />
  // ----- clover cell cttc
  if (name === 'CLOVER_CELL_CTTC_TO_EXTERNAL_CHECK')
    return <CloverCellCttcToExternalCheck {...props} />
  if (name === 'CLOVER_CELL_CTTC_TO_EXTERNAL_ERROR')
    return <CloverCellCttcToExternalError {...props} />

  // Power Detector
  // Power Detector
  // Power Detector
  // ----- action panel
  if (name === 'PD_CUSTOM_CALIBRATION')
    return <PDCustomCalibration {...props} />
  if (name === 'PD_CALI_CONFIG_FILE_CHANGED')
    return <PDCaliConfigFileChanged {...props} />
  if (name === 'PD_CALI_CONFIG_FILE_CHANGING')
    return <PDCaliConfigFileChanging {...props} />
  if (name === 'PD_CHANGE_ANOTHER_CALI_CONFIG_FILE')
    return <PDChangeAnotherCaliConfigFile {...props} />
  if (name === 'PD_FAILED_TO_CHANGE_CALI_CONFIG')
    return <PDFailedToChangeCaliConfig {...props} />
  // ----- dashboard
  if (name === 'PD_CLEAR_THE_CURRENTLY_READ_DATA')
    return <PDClearTheCurrentlyReadData {...props} />

  // Member
  // Member
  // Member
  if (name === 'MEMBER_CHANGE_PROFILE_PHOTO')
    return <MemberChangeProfilePhoto {...props} />
  if (name === 'MEMBER_CHANGE_NAME') return <MemberChangeName {...props} />
  if (name === 'MEMBER_CHANGE_PASSWORD')
    return <MemberChangePassword {...props} />

  // Get Quote
  // Get Quote
  // Get Quote
  if (name === 'GET_QUOTE_CHOOSE_DEVICE_AND_PURPOSE')
    return <GetQuoteChooseDeviceAndPurpose {...props} />
  if (name === 'GET_QUOTE_PERSONAL_INFORMATION')
    return <GetQuotePersonalInformation {...props} />
  if (name === 'GET_QUOTE_SUCCESS') return <GetQuoteSuccess {...props} />
}

export default NormalModal
