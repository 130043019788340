import React from 'react'
import * as R from 'ramda'
import { useDispatch } from 'react-redux'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'

import getRenderTrItem from 'src/containers/operating/device/beamformers/bbox/BeamConfigEditor/BeamList/getRenderTrItem'
import FunctionBar from 'src/containers/operating/device/beamformers/bbox/BeamConfigEditor/BeamList/FunctionBar'

import SegmentedButton from 'src/components/Button/SegmentedButton'

import useGetScreenSize from 'src/hooks/useGetScreenSize'
import useGetCurrentDeviceData from 'src/hooks/useGetCurrentDeviceData'
import useBindErrorBoundary from 'src/hooks/useBindErrorBoundary'

import {
  setConfigEditorRfMode,
  setConfigEditorIsSelectMode,
  setConfigEditorSelectList,
} from 'src/redux/slices/uiControl/beamformers/bbox'

import {
  bbox_deviceDataCurrentConfig_update_watcher,
  bbox_spi_configClear_watcher,
} from 'src/redux/actions/beamformers/bboxAction'

import { createConfigInitData } from 'src/funcs/device/bbox'

import { modalActions } from 'src/redux/slices/modal'
import useGetUIControl from 'src/hooks/useGetUIControl'

const BeamList = props => {
  const dispatch = useDispatch()

  const { isSM, isMD } = useGetScreenSize()
  const isSmallSize = isSM || isMD

  const { current } = useGetCurrentDeviceData()
  const sn = current.sn

  const { single: s_uiControl } = useGetUIControl(sn)
  const s_spiUiControl = s_uiControl.beamConfigEditor

  const { rfMode, isSelectMode, selectLists } = s_spiUiControl

  const currentAntenna = current.data.deviceControl.common.currentAntenna

  const { controlMethod, currentConfig } = current.data.beamConfigEditor
  const { numberOfConfigs, sort } = currentConfig
  const userDefinedOnlyTableData = currentConfig.tableData[rfMode]

  const isUserDefinedFulled = userDefinedOnlyTableData.length >= numberOfConfigs

  const tableDataPath = [
    'beamConfigEditor',
    'currentConfig',
    'tableData',
    rfMode,
  ]

  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------        Func        -----------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  const userDefinedBeamData = beamID =>
    userDefinedOnlyTableData.find(e => +e.beamID === +beamID)

  const fillDefaultTableData = new Array(numberOfConfigs)
    .fill('')
    .map((e, i) =>
      userDefinedBeamData(i + 1)
        ? { ...userDefinedBeamData(i + 1) }
        : { beamID: i + 1 }
    )

  const updateReduxCurrentConfig = ({ sn, currentConfig }) => {
    dispatch(bbox_deviceDataCurrentConfig_update_watcher({ sn, currentConfig }))
  }

  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------       Event        -----------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  const handleCreateClick = useBindErrorBoundary(({ beamID, type }) => {
    const initData = createConfigInitData({
      beamID,
      type,
      rfMode,
      currentDeviceData: current.data,
    })

    const newCurrentConfig = R.modifyPath(
      ['tableData', rfMode],
      R.insert(beamID - 1, initData)
    )(currentConfig)

    updateReduxCurrentConfig({ sn, currentConfig: newCurrentConfig })
  })

  const handleEditClick = ({ beamID }) =>
    dispatch(
      modalActions.modal_show({
        priority: 'normal',
        name: 'BBOX_BEAM_CONFIG_EDITOR_EDIT_BEAM',
        props: {
          sn,
          rfMode,
          beamID,
          isChanged: false,
        },
      })
    )

  const handleCopyClick = useBindErrorBoundary(({ beamID: copyBeamID }) => {
    const afterThisCopyIndex = R.findIndex(
      e =>
        e.beamID <= numberOfConfigs &&
        e.beamID > copyBeamID &&
        !e.beam_config &&
        !e.channel_config
    )(fillDefaultTableData)

    const allCopyIndex = R.findIndex(e => !e.beam_config && !e.channel_config)(
      fillDefaultTableData
    )

    // 先找當前後面第一個 未定義
    // 後面都滿了就重頭開始找
    let addIndex = afterThisCopyIndex >= 0 ? afterThisCopyIndex : allCopyIndex

    const copyData = R.pipe(
      R.view(R.lensPath(tableDataPath)),
      R.find(e => e.beamID === copyBeamID)
    )(current.data)

    const newCurrentConfig = R.modifyPath(
      ['tableData', rfMode],
      R.pipe(
        R.insert(addIndex, { ...copyData, beamID: addIndex + 1 }),
        R.sort((a, b) => a - b)
      )
    )(currentConfig)

    updateReduxCurrentConfig({ sn, currentConfig: newCurrentConfig })
  })

  const handleClearClick = ({ beamID }) =>
    dispatch(bbox_spi_configClear_watcher({ sn, clearLists: [beamID] }))

  // ----------------
  // ----------------
  // ----------------
  // ----------------
  const handleDragEnd = useBindErrorBoundary(event => {
    const { source, destination } = event
    if (!destination) return

    // 根據排序來找到 source 跟 destination 在 data 裡的位置
    const sourceIndex =
      sort === 'asc' ? source.index : numberOfConfigs - source.index - 1
    const destinationIndex =
      sort === 'asc'
        ? destination.index
        : numberOfConfigs - destination.index - 1

    if (!destination) return

    const mapIndexed = R.addIndex(R.map)
    const newTableData = R.pipe(
      R.move(sourceIndex, destinationIndex), // 移動位置
      mapIndexed((value, index) => ({ ...value, beamID: index + 1 })), // 重新照順序賦予 beamID
      R.filter(value => value.beam_config || value.channel_config) // 拔掉非使用者定義的
    )(fillDefaultTableData) //（起始 data 是填滿非使用者定義的 data）
    // 用 fillDefaultTableData 在塞新的 beamID 才會是對的，因為使用者有可能 1,2,6,7 中間有空的值

    const newCurrentConfig = R.assocPath(
      ['tableData', rfMode],
      newTableData
    )(currentConfig)

    updateReduxCurrentConfig({ sn, currentConfig: newCurrentConfig })
  })
  // ----------------
  // ----------------
  // ----------------
  // ----------------

  const handleSteeringChange = useBindErrorBoundary(
    ({ beamID, label, value }) => {
      const adjustIndex = R.findIndex(e => e.beamID === beamID)(
        userDefinedOnlyTableData
      )

      const newTableData = R.assocPath(
        [adjustIndex, 'beam_config', label],
        value
      )(userDefinedOnlyTableData)

      const newCurrentConfig = R.assocPath(
        ['tableData', rfMode],
        newTableData
      )(currentConfig)

      updateReduxCurrentConfig({ sn, currentConfig: newCurrentConfig })
    }
  )

  const handleSortChange = useBindErrorBoundary((event, value, children) => {
    const newCurrentConfig = R.assoc('sort', value)(currentConfig)

    updateReduxCurrentConfig({ sn, currentConfig: newCurrentConfig })
  })

  const handleSelectClearClick = () => {
    dispatch(
      bbox_spi_configClear_watcher({ sn, clearLists: selectLists[rfMode] })
    )
  }

  const handleRfModeChange = (event, value) =>
    dispatch(setConfigEditorRfMode({ sn, value }))

  const handleSelectChange = ({ newSelectLists }) =>
    dispatch(setConfigEditorSelectList({ sn, value: newSelectLists }))

  const handleIsSelectModeChange = value => {
    dispatch(setConfigEditorIsSelectMode({ sn, value }))

    if (!value)
      dispatch(
        setConfigEditorSelectList({
          sn,
          value: { ...selectLists, [rfMode]: [] },
        })
      )
  }

  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------       Props       ------------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  const renderTrItem = getRenderTrItem({
    rfMode,
    isSelectMode,
    selectLists,
    fillDefaultTableData,
    sort,
    currentAntenna,
    controlMethod,
    isUserDefinedFulled,
    handleCreateClick,
    handleEditClick,
    handleCopyClick,
    handleClearClick,
    handleSteeringChange,
    handleSelectChange,
  })

  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------        JSX         -----------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  return (
    <div className='funcBG w-[632px] pt-5 pb-[60px] px-3 rounded-md overflow-hidden'>
      <div className='w-full mb-4 flex flex-col gap-y-4 px-1'>
        <SegmentedButton
          size={isSmallSize ? 'md' : 'sm'}
          type='solid'
          value={rfMode}
          onChange={handleRfModeChange}>
          <SegmentedButton.Option name='tx'>TX</SegmentedButton.Option>
          <SegmentedButton.Option name='rx'>RX</SegmentedButton.Option>
        </SegmentedButton>

        <FunctionBar
          {...{
            userDefinedOnlyTableData,
            numberOfConfigs,
            sort,
            rfMode,
            isSelectMode,
            selectLists,
            handleSelectClearClick,
            handleSortChange,
            handleSelectChange,
            handleIsSelectModeChange,
          }}
        />
      </div>

      <div className={tableContainer}>
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId='drop-id' renderClone={renderTrItem}>
            {provided => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                {/*
                  provided.droppableProps & provided.innerRef
                  套件的機制所需, 直接去取用 dom 的 ref, 就是套用的例行公事
                */}
                {fillDefaultTableData.map((e, i) => (
                  <Draggable
                    draggableId={`drag-id-${i}`}
                    index={i}
                    key={`tr-${i}`}
                    isDragDisabled={isSelectMode || controlMethod === 'SPI'}>
                    {renderTrItem}
                  </Draggable>
                ))}

                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    </div>
  )
}

BeamList.propTypes = {}

export default BeamList

const tableContainer = `w-full h-[433px] text-light-1 scrollbar overscroll-contain`
