import React from 'react'
import * as R from 'ramda'
import { useDispatch, useSelector } from 'react-redux'

import { h6 } from 'src/containers/Modal/style'

import InputGroupPkg from 'src/containers/Modal/Normal/beamformers/bbox/DeviceController/CustomAntenna/EditMode/InputGroupPkg'

import { offsetIsInvalid } from 'src/funcs/device/bbox'
import { offsetMin, offsetMax } from 'src/constants/beamformers'
import { modalActions } from 'src/redux/slices/modal'

const Offset = ({ mode }) => {
  const dispatch = useDispatch()

  const modalProps = useSelector(state => state.modal.normal.props)
  const { sn, editData } = modalProps

  const { boardCount, channelCount } = useSelector(
    state => state.deviceData[sn].deviceControl.channel
  )
  const leadingZero = number =>
    number <= 9 ? '0' + String(number) : String(number)

  const labelIndex = (bI, cI) => leadingZero(bI * 4 + cI + 1)
  const index = (bI, cI) => bI * 4 + cI

  const labelColor = (bI, cI) =>
    labelIndex(bI, cI) === '01' ? 'text-yellow' : 'text-light-4'

  const isInvalid = value => offsetIsInvalid(value, offsetMin, offsetMax)

  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------       Props       ------------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  const inputGroupPkgProps = index => ({
    hint: '0 ~ 355, multiples of 5',
    isInvalid: isInvalid(editData[mode][index]),
    inputGroupProps: {
      value: editData[mode][index],
      icon: <></>,
      unit: <span className='opacity-30'>˚</span>,
      danger: isInvalid(editData[mode][index]),
      step: 5,
      keydownMin: 0,
      keydownMax: 355,
      loop: true,
      onChange: e =>
        dispatch(
          modalActions.modal_props_set({
            priority: 'normal',
            props: {
              isEditChanged: true,
              editData: R.assocPath([mode, index], e.formattedValue)(editData),
            },
          })
        ),
    },
  })

  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------        JSX         -----------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  return (
    <div className={containerClass}>
      {new Array(boardCount).fill(null).map((bE, bI) => (
        <div key={mode + bI} className={rowClass}>
          {new Array(channelCount).fill(null).map((cE, cI) => (
            <div key={mode + index(bI, cI)} className='w-full'>
              <h6 className={`${labelColor(bI, cI)} ${h6} mb-1 `}>
                CH {labelIndex(bI, cI)}
              </h6>

              <InputGroupPkg {...inputGroupPkgProps(index(bI, cI))} />
            </div>
          ))}
        </div>
      ))}
    </div>
  )
}

export default Offset

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* -----------------     Static CSS     -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
const containerClass = `flex flex-wrap gap-x-[30px]`
const rowClass = `flex flex-row-reverse gap-x-[30px] mb-3 last:md-0`
