import { call, put, delay } from 'redux-saga/effects'

import { setSingleDeviceData } from 'src/redux/slices/deviceData'

import { udboxUIControlForSingleAction as uiControlAction } from 'src/redux/slices/uiControl/freqConverter/udbox'

import { getCurrentData } from 'src/redux/sagas/selector/deviceData'

import { rfIfIsValid } from 'src/funcs/device/udbox'

import { devWarLog } from 'src/funcs/tools'

import { modalActions } from 'src/redux/slices/modal'

export function* getLO(response) {
  try {
    const [sn, { LO }] = Object.entries(response?.data)[0]

    let currentData = yield call(getCurrentData, sn)

    // ! 這邊要判斷畫面上 RF IF 是否合法來決定要不要顯示更新 LO 回傳值
    // ! WEBTLK-1320
    // ! https://tmytek.atlassian.net/browse/WEBTLK-1320
    const unit = currentData.settings.freq.unit
    const currentRF = currentData.settings.freq.RF
    const currentIF = currentData.settings.freq.IF

    const isValid = yield call(rfIfIsValid, {
      RF: currentRF,
      IF: currentIF,
      unit,
    })

    if (isValid) {
      currentData.settings.freq.LO = LO
      yield put(setSingleDeviceData({ sn, data: currentData }))
    }
  } catch (error) {
    devWarLog('[res-handler] getLO error:', error)
  }
}

export function* apply(response) {
  try {
    const [sn, { currentFreq }] = Object.entries(response?.data)[0]
    // const { RF, IF, LO, isHarmonic } = currentFreq

    let currentData = yield call(getCurrentData, sn)
    // 避免改到 isSupportUpdateCurrent , 所以只替換有回傳的部分
    currentData.current.freq = { ...currentData.current.freq, ...currentFreq }

    yield put(setSingleDeviceData({ sn, data: currentData }))

    yield put(uiControlAction.setIsFreqChanged({ sn, value: false }))
    yield put(uiControlAction.setIsFreqSuccess({ sn }))
    yield delay(1000)
    yield put(uiControlAction.clearFreqSuccess({ sn }))
  } catch (error) {
    devWarLog('[res-handler] apply error:', error)
  }
}

export function* freqSettingUnlock(response) {
  try {
    const [sn, { RF: responseRF, IF: responseIF, LO: responseLO }] =
      Object.entries(response?.data)[0]

    yield put(
      modalActions.modal_show({
        priority: 'normal',
        name: 'FREQ_CONVERTER_UNLOCK_REQUESTING',
        props: {
          sn,
          RF: responseRF,
          IF: responseIF,
          LO: responseLO,
        },
      })
    )

    let currentData = yield call(getCurrentData, sn)
    currentData.settings.freq.RF.userMin = responseRF.userMin
    currentData.settings.freq.RF.userMax = responseIF.userMax
    currentData.settings.freq.IF.userMin = responseLO.userMin
    yield put(setSingleDeviceData({ sn, data: currentData }))
  } catch (error) {
    devWarLog('[res-handler] apply error:', error)
  }
}
