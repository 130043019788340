import { take, call, race, put, select } from 'redux-saga/effects'
import * as R from 'ramda'

import { modalActions } from 'src/redux/slices/modal'

import {
  setIsIpChanged,
  openDeviceInfo,
  closeDeviceInfo,
  addSnToLstInitFailedRetryRequesting,
  removeSnToLstInitFailedRetryRequesting,
  tempIpUpdateFromDeviceData,
} from 'src/redux/slices/uiControl/facility/home'

import {
  home_leaveCheckModal_dontApplyClick_watcher,
  home_leaveCheckModal_cancelClick_watcher,
  home_leaveCheckModal_applyClick_watcher,
  home_applyCheckModal_NoClick_watcher,
  home_applyCheckModal_yesClick_watcher,
} from 'src/redux/actions/facility/home'

import { setSingleDeviceData } from 'src/redux/slices/deviceData'

import { getCommonArgs } from 'src/redux/sagas/selector/deviceData'

import { devWarLog } from 'src/funcs/tools'
import { getDeviceInfo } from 'src/funcs/getDeviceInfo'

import __socket_API_sender from 'src/redux/sagas/services/socketTools/__socket_API_sender'

import facilityApi from 'src/redux/sagas/services/socketAPI/facility'
import beamformersApi from 'src/redux/sagas/services/socketAPI/beamformers'
import freqConverterApi from 'src/redux/sagas/services/socketAPI/freqConverter'

import { History } from 'src/containers/NavigateSetter'

export function* applyDeviceInfo() {
  try {
    yield put(
      modalActions.modal_show({
        priority: 'normal',
        name: 'HOME_APPLY_DEVICE_INFO_CHECK',
        props: {},
      })
    )

    const { no, yes } = yield race({
      no: take(home_applyCheckModal_NoClick_watcher.type),
      yes: take(home_applyCheckModal_yesClick_watcher.type),
    })

    if (no) yield put(modalActions.modal_hide({ priority: 'normal' }))

    if (yes) {
      yield put(
        modalActions.modal_show({
          priority: 'normal',
          name: 'ANIMATION_TMYTEK_LOGO_SHORT_TIME',
          props: { status: 'loading' },
        })
      )

      const data = yield select(state => state.temporaryIpData)

      // temporaryIpData 的格式為 { [sn]:'xxx.xxx.xxx.xxx' }
      // 這邊移除 value 為 空字串 跟 undefined 的 data
      //! 如果把空字串送下去 API 會出事
      const removeInvalidData = R.reject(R.anyPass([R.isEmpty, R.isNil]))(data)

      //TODO: 回來檢驗這邊寫法是否在 phase 3 之後還合理
      // phase 2 only
      // 多包一層 object 是因為 __socket_API_sender 會多包一層 lookupID
      // 這裡包 object 才會變成 {lookupID, data} 的 api 格式
      yield call(__socket_API_sender, facilityApi.MODIFY_STATIC_IP, {
        data: removeInvalidData,
      })
    }
  } catch (error) {
    devWarLog('[handler] applyDeviceInfo error:', error)
  }
}

export function* leaveDeviceInfo() {
  try {
    const homeUiControl = yield select(state => state.homeUiControl)
    const { isIpChanged } = homeUiControl
    if (!isIpChanged) yield put(closeDeviceInfo())

    if (isIpChanged) {
      yield put(
        modalActions.modal_show({
          priority: 'normal',
          name: 'HOME_LEAVE_DEVICE_INFO_CHECK',
          props: {},
        })
      )

      // cancel button 事件在 modal 做完了
      const { cancel, dontApply, apply } = yield race({
        cancel: take(home_leaveCheckModal_cancelClick_watcher.type),
        dontApply: take(home_leaveCheckModal_dontApplyClick_watcher.type),
        apply: take(home_leaveCheckModal_applyClick_watcher.type),
      })

      if (cancel) {
        yield History.push('/home/device-control')
        yield put(openDeviceInfo())
        yield put(modalActions.modal_hide({ priority: 'normal' }))
      }

      if (dontApply) {
        yield put(setIsIpChanged(false))
        const deviceData = yield select(state => state.deviceData)
        yield put(tempIpUpdateFromDeviceData(deviceData))
        yield put(closeDeviceInfo())
        yield put(modalActions.modal_hide({ priority: 'normal' }))
      }

      if (apply) yield call(applyDeviceInfo)
    }
  } catch (error) {
    devWarLog('[handler] leaveDeviceInfo error:', error)
  }
}

export function* initFailedRetry(payloads) {
  try {
    const { sn } = payloads

    const { lookupID, currentData } = yield call(getCommonArgs, sn)

    yield put(addSnToLstInitFailedRetryRequesting({ sn }))

    const deviceInitGetInfoResponse = yield call(
      __socket_API_sender,
      facilityApi.DEVICE_GET_INFO,
      { sn, lookupID }
    )

    if (deviceInitGetInfoResponse === 0) {
      const deviceType = currentData.info.deviceType

      const deviceClass = getDeviceInfo[deviceType].deviceClass

      // initialization 在 init device 前不先寫成 unfinished,

      // initialization 在等於 'failed' 時
      // 在 operating 裡面會顯示 init failed 且有 retry 可以按
      currentData.info.initialization = 'unfinished'
      yield put(setSingleDeviceData({ sn, data: currentData }))

      const api = {
        beamformers: beamformersApi,
        freqConverter: freqConverterApi,
      }

      yield call(__socket_API_sender, api[deviceClass].INIT_DEVICE, {
        sn,
        lookupID,
      })
    }

    yield put(removeSnToLstInitFailedRetryRequesting({ sn }))
  } catch (error) {
    devWarLog('[handler] initFailedRetry error:', error)
  }
}
