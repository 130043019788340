import React from 'react'
import { useDispatch } from 'react-redux'

import Mask from 'src/components/Mask'

import Head from 'src/containers/Modal/Normal/PowerDetector/CustomCalibration/EditMode/Head'
import FreqItem from 'src/containers/Modal/Normal/PowerDetector/CustomCalibration/EditMode/FreqItem'
import Footer from 'src/containers/Modal/Normal/PowerDetector/CustomCalibration/EditMode/Footer'

import { main } from 'src/containers/Modal/Normal/beamformers/bbox/DeviceController/CustomAntenna/style'
import CommonButton from 'src/components/Button/CommonButton'
import { modalActions } from 'src/redux/slices/modal'

const EditMode = props => {
  const dispatch = useDispatch()
  const { isEditCreate, isEditSaveRequesting, editData, isGetVoltError } = props

  const [[id, data]] = Object.entries(editData)

  const handleMaskOKClick = () =>
    dispatch(
      modalActions.modal_props_set({
        priority: 'normal',
        props: {
          isGetVoltError: false,
        },
      })
    )

  return (
    <>
      {isGetVoltError && (
        <Mask className='flex flex-col gap-y-10 items-center justify-center'>
          <h3 className='text-xl text-white font-bold text-center'>
            Unable to receive voltage
            <br />
            Please connect the signal source and try again
          </h3>
          <CommonButton type='general' onClick={handleMaskOKClick}>
            OK
          </CommonButton>
        </Mask>
      )}

      <Head {...{ isEditCreate, editData }} />

      <div className={'w-full px-[84px]'}>
        <main className={main + ' overflow-y-scroll scrollbar'}>
          <div className='py-2'>
            <h6 className='text-white'>Set up calibration config file</h6>

            <ul className='list-outside list-decimal ml-6 text-light-4'>
              <li>
                According to the frequency you want to set, after inputting the
                corresponding power value in the device. Click related -&gt;
                button to receive the device voltage.
              </li>
              <li>
                Each frequency requires high and low power settings to be
                completed. Unfinished items are shown with a yellow outline.
              </li>
            </ul>
          </div>

          <div className='dashedDivide h-[1px] w-full my-4' />

          <div className='w-full px-4 py-2 grid grid-cols-3 gap-x-3 gap-y-4'>
            {Object.entries(data.value).map(([freq, itemData], index) => (
              <FreqItem
                key={`${id} freq item ${index}`}
                {...{ freq, itemData }}
              />
            ))}
          </div>
        </main>
      </div>

      <Footer name={data.name} {...{ isEditCreate, isEditSaveRequesting }} />
    </>
  )
}

export default EditMode
