import React from 'react'

import GeneralError from 'src/containers/Modal/Emergency/GeneralError'
import GeneralErrorSendMail from 'src/containers/Modal/Emergency/GeneralErrorSendMail'
import GeneralErrorMailSended from 'src/containers/Modal/Emergency/GeneralErrorMailSended'

import DeviceErrorSendMail from 'src/containers/Modal/Emergency/DeviceErrorSendMail'
import DeviceErrorMailSended from 'src/containers/Modal/Emergency/DeviceErrorMailSended'

import LocalMidSocketDisconnect from 'src/containers/Modal/Emergency/LocalMidSocketDisconnect'

const EmergencyModal = ({ name, props }) => {
  if (name === 'ERROR_GENERAL') return <GeneralError {...props} />

  if (name === 'ERROR_GENERAL_SEND_MAIL')
    return <GeneralErrorSendMail {...props} />

  if (name === 'ERROR_GENERAL_MAIL_SENDED')
    return <GeneralErrorMailSended {...props} />

  if (name === 'ERROR_DEVICE_ERROR_SEND_MAIL')
    return <DeviceErrorSendMail {...props} />

  if (name === 'ERROR_DEVICE_ERROR_MAIL_SENDED')
    return <DeviceErrorMailSended {...props} />

  if (name === 'ERROR_LOCAL_MID_SOCKET_DISCONNECT')
    return <LocalMidSocketDisconnect {...props} />
  return <></>
}

export default EmergencyModal
