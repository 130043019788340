import React from 'react'
import { useDispatch } from 'react-redux'

import Modal from 'src/components/Popup/Modal'
import CommonButton from 'src/components/Button/CommonButton'

import { modalActions } from 'src/redux/slices/modal'

import { BiError } from 'react-icons/bi'

const GlobalError = props => {
  const dispatch = useDispatch()

  const { isReportIssueDisabled } = props

  const handleContactTmytekClick = () =>
    dispatch(
      modalActions.modal_show({
        priority: 'emergency',
        name: 'ERROR_GENERAL_SEND_MAIL',
        props: {},
      })
    )

  return (
    <Modal>
      <Modal.Icon>
        <BiError className='text-red' />
      </Modal.Icon>

      <Modal.Title>Something went wrong</Modal.Title>

      <Modal.Description>
        Please refresh and try again.
        <br /> If the problem persists, please contact TMYTEK.
      </Modal.Description>

      {!isReportIssueDisabled && (
        <Modal.RowButtonBox>
          <CommonButton
            size='lg'
            type='outlined'
            onClick={handleContactTmytekClick}>
            Report Issue
          </CommonButton>
          <CommonButton
            size='lg'
            type='primary'
            onClick={() => window.location.reload()}>
            Refresh
          </CommonButton>
        </Modal.RowButtonBox>
      )}

      {isReportIssueDisabled && (
        <Modal.ColumnButtonBox>
          <CommonButton
            size='lg'
            type='primary'
            onClick={() => window.location.reload()}>
            Refresh
          </CommonButton>
        </Modal.ColumnButtonBox>
      )}
    </Modal>
  )
}

export default GlobalError
