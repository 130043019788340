import {
  call,
  fork,
  put,
  cancel,
  race,
  select,
  take,
  delay,
} from 'redux-saga/effects'
import { Auth } from 'aws-amplify'

import { modalActions } from 'src/redux/slices/modal'
import { setDeviceControlInitIsFalse } from 'src/redux/slices/uiControl/facility/init'
import {
  addMember,
  clearMember,
} from 'src/redux/slices/uiControl/facility/member'

import { devWarLog } from 'src/funcs/tools'

import { History } from 'src/containers/NavigateSetter'

import {
  member_modalClose_watcher,
  member_signInSignUpModal_signInClick_watcher,
  member_signInSignUpModal_createAccountClick_watcher,
  member_signInSignUpModal_forgotPasswordClick_watcher,
  member_signUpVerificationModal_submit_watcher,
  member_signUpVerificationModal_resend_watcher,
  member_forgotPasswordSendMailModal_submit_watcher,
  member_forgotPasswordSendMailModal_backToSignIn_watcher,
  member_forgotPasswordResetModal_submit_watcher,
  member_forgotPasswordResetModal_resendCode_watcher,
  member_signUpVerificationSuccessModal_okClick_watcher,
} from 'src/redux/actions/facility/member'

import { socketDisconnect } from 'src/redux/actions/socket'
import { isOffline } from 'src/funcs/getEnv'

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* -----------------        Func        -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
function* _close() {
  try {
    yield put(modalActions.modal_hide({ priority: 'high' }))
  } catch (error) {
    devWarLog('[handler] _close error:', error)
  }
}

function* _updateFeMemberData(awsResponse) {
  const {
    preferred_username,
    email,
    picture,
    sub: userID,
  } = awsResponse?.attributes

  const avatarDefaultColor =
    awsResponse?.attributes['custom:avatar_default_color']

  const { jwtToken: accessJwtToken } =
    awsResponse?.signInUserSession.accessToken

  yield put(
    addMember({
      userID,
      email,
      preferredUsername: preferred_username,
      picture: picture === 'not uploaded' ? '' : picture,
      avatarDefaultColor,
      accessJwtToken,
    })
  )
}

// 給外部 function 確認是否登錄，如果沒有就跑 AfterHandler
export function* loginCheck(afterHandler, ...props) {
  try {
    if (isOffline) return true

    yield Auth.currentSession()

    return yield true
  } catch (error) {
    devWarLog('[handler] loginCheck error:', error)
    return yield call(signInSignUp, afterHandler) // initDeviceControlMode = afterHandler
  }
}

function createAvatarDefaultColor() {
  const randomNumber = Math.round(Math.random() * 100)

  const color = {
    yellow: '#F2C94C',
    orange: '#F2994A',
    teal: '#28CACE',
    purple: '#B284FC',
  }

  if (randomNumber <= 25) return color.yellow
  if (randomNumber <= 50) return color.orange
  if (randomNumber <= 75) return color.teal
  if (randomNumber <= 100) return color.purple
}
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* -----------------       main        -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
export function* updateMemberInfo() {
  try {
    const response = yield Auth.currentAuthenticatedUser()
    yield call(_updateFeMemberData, response)
  } catch (error) {
    devWarLog('[handler] updateMemberInfo error:', error)
  }
}

export function* signInSignUp(afterHandler) {
  try {
    // 先判斷目前有沒有顯示 SignInSignUp 的 Modal
    // 因為有可能是 SignOut 失敗 call 回來的
    const modalName = yield select(state => state.modal.high?.name)
    if (modalName !== 'MEMBER_SIGN_IN_SIGN_UP')
      yield put(
        modalActions.modal_show({
          priority: 'high',
          name: 'MEMBER_SIGN_IN_SIGN_UP',
          props: {
            tabs: 'signIn',
            email: '',
            password: '',
            confirmPassword: '',
            preferredUsername: '',
            errorMessage: '',
          },
        })
      )

    const { signIn, createAccount, forgotPassword, close } = yield race({
      signIn: take(member_signInSignUpModal_signInClick_watcher.type),
      createAccount: take(
        member_signInSignUpModal_createAccountClick_watcher.type
      ),
      forgotPassword: take(
        member_signInSignUpModal_forgotPasswordClick_watcher.type
      ),
      close: take(member_modalClose_watcher.type),
    })

    if (signIn) {
      yield call(_signIn, afterHandler)
      return yield true
    }
    if (createAccount) yield call(_signUp, afterHandler)
    if (forgotPassword) yield call(_forgotPassWord)

    if (close) {
      yield call(_close)
      return yield false
    }
  } catch (error) {
    devWarLog('[handler] signInSignUp error:', error)
  }
}

export function* signOut() {
  try {
    yield put(socketDisconnect())
    yield Auth.signOut()
    yield put(clearMember())
    yield put(setDeviceControlInitIsFalse())
    yield History.push('/')
  } catch (error) {
    devWarLog('[handler] signInSignUp error:', error)
  }
}

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* -----------------      Sign in       -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
function* _signIn(afterHandler) {
  try {
    const modalProps = yield select(state => state.modal.high.props)
    const { email, password } = modalProps

    if (!email || !password)
      throw Error('The email or password cannot be empty.')

    const response = yield Auth.signIn(email, password)

    yield call(_updateFeMemberData, response)
    yield call(_close)

    if (!afterHandler) {
      yield put(
        modalActions.modal_show({
          priority: 'normal',
          name: 'ANIMATION_TMYTEK_LOGO_LOGIN_WELCOME',
          props: {},
        })
      )
      yield delay(3000)
      yield put(modalActions.modal_hide({ priority: 'normal' }))
    }

    if (afterHandler) yield call(afterHandler)
  } catch (error) {
    devWarLog('[handler] _signIn error:', error)

    const errorStats = {
      'CUSTOM_AUTH is not enabled for the client.': 'clientNotEnabled',
      'User is not confirmed.': 'clientNotEnabled',
      'Pending sign-in attempt already in progress': 'safe',
    }

    const isSafe = errorStats[error.message] === 'safe'
    const isClientNotEnabled = errorStats[error.message] === 'clientNotEnabled'
    const isOtherError = errorStats[error.message] === undefined

    // forget password 之後重新登錄，會跳已登陸過(儘管還是會成功登陸，但看了很不爽)
    // 這邊抓到直接 return，不做任何動作
    if (isSafe) return

    if (isClientNotEnabled) yield call(_signUpVerification, afterHandler)

    if (isOtherError) {
      yield put(
        modalActions.modal_props_set({
          priority: 'high',
          props: { errorMessage: String(error) },
        })
      )

      yield call(signInSignUp, afterHandler)
    }
  }
}

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* -----------------      Sign up       -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
function* _signUp(afterHandler) {
  try {
    const modalProps = yield select(state => state.modal.high.props)
    const { email, password, confirmPassword, preferredUsername } = modalProps

    if (password !== confirmPassword)
      throw Error('Password and the confirmed password do not match')

    if (password.length < 8)
      throw Error('The password must be at least 8 characters long.')

    if (!preferredUsername)
      throw Error('The preferred username cannot be empty.')

    yield Auth.signUp({
      username: email,
      password,
      attributes: {
        preferred_username: preferredUsername,
        picture: 'not uploaded',
        'custom:avatar_default_color': createAvatarDefaultColor(),
      },
    })

    yield call(_signUpVerification, afterHandler)
  } catch (error) {
    devWarLog('[handler] _signUp error:', error)

    yield put(
      modalActions.modal_props_set({
        priority: 'high',
        props: { errorMessage: error?.message },
      })
    )

    yield call(signInSignUp, afterHandler)
  }
}

let reciprocal
function* _signUpVerification(afterHandler) {
  try {
    let modalProps = yield select(state => state.modal.high.props)
    const { email, password } = modalProps
    yield put(
      modalActions.modal_show({
        priority: 'high',
        name: 'MEMBER_SIGN_UP_VERIFICATION',
      })
    )
    yield put(
      modalActions.modal_props_set({
        priority: 'high',
        props: { email, password, verificationCode: '', resendDelay: 0 },
      })
    )

    if (!reciprocal) reciprocal = yield fork(_resendDelayReciprocal)

    const { submit, resend, close } = yield race({
      submit: take(member_signUpVerificationModal_submit_watcher.type),
      resend: take(member_signUpVerificationModal_resend_watcher.type),
      close: take(member_modalClose_watcher.type),
    })

    modalProps = yield select(state => state.modal.high.props)
    const { verificationCode } = modalProps

    if (submit) {
      yield Auth.confirmSignUp(email, verificationCode)
      yield call(_cancelReciprocal)

      yield put(
        modalActions.modal_show({
          priority: 'high',
          name: 'MEMBER_SIGN_UP_SUCCESS',
          props: { email, password },
        })
      )

      yield take(member_signUpVerificationSuccessModal_okClick_watcher.type)
      yield call(_signIn, afterHandler)
    }

    if (resend) {
      Auth.resendSignUp(email)
      yield call(_cancelReciprocal)
      yield call(_signUpVerification)
    }

    if (close) {
      yield call(_close)
      yield call(_cancelReciprocal)
    }
  } catch (error) {
    devWarLog('[handler] _signUpVerification error:', error)
    yield put(
      modalActions.modal_props_set({
        priority: 'high',
        props: { errorMessage: error.message },
      })
    )

    yield call(_signUpVerification)
  }
}
function* _resendDelayReciprocal() {
  try {
    yield put(
      modalActions.modal_props_set({
        priority: 'high',
        props: {
          resendDelay: 30,
        },
      })
    )

    for (let i = 30; i >= 0; i--) {
      yield put(
        modalActions.modal_props_set({
          priority: 'high',
          props: {
            resendDelay: i,
          },
        })
      )

      yield delay(1000)
    }
  } catch (error) {
    devWarLog('[handler] _resendDelayReciprocal error:', error)
  }
}
function* _cancelReciprocal() {
  try {
    yield cancel(reciprocal)
    reciprocal = yield null
  } catch (error) {
    devWarLog('[handler] _cancelReciprocal error:', error)
  }
}

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* -----------------   Forgot Password  -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
function* _clearErrorMessageOnForgotPassword() {
  yield put(
    modalActions.modal_props_set({
      priority: 'high',
      props: {
        errorMessage: '',
      },
    })
  )
}

function* _forgotPassWord() {
  try {
    yield put(
      modalActions.modal_show({
        priority: 'high',
        name: 'MEMBER_FORGOT_PASSWORD_SEND_EMAIL',
      })
    )

    const { sendCode, back, close } = yield race({
      sendCode: take(member_forgotPasswordSendMailModal_submit_watcher.type),
      back: take(member_forgotPasswordSendMailModal_backToSignIn_watcher.type),
      close: take(member_modalClose_watcher.type),
    })

    if (back) yield call(signInSignUp)
    if (close) yield call(_close)
    if (sendCode) {
      yield call(_clearErrorMessageOnForgotPassword)

      const { email } = yield select(state => state.modal.high.props)

      yield Auth.forgotPassword(email)

      yield call(_forgotPasswordReset)
    }
  } catch (error) {
    devWarLog('[handler] _forgotPassWord error:', error)

    const errorMessage =
      String(error) === 'Username cannot be empty'
        ? 'Email cannot be empty'
        : String(error)

    yield put(
      modalActions.modal_props_set({
        priority: 'high',
        props: {
          errorMessage,
        },
      })
    )

    yield call(_forgotPassWord)
  }
}
function* _forgotPasswordReset() {
  try {
    yield put(
      modalActions.modal_show({
        priority: 'high',
        name: 'MEMBER_FORGOT_PASSWORD_RESET',
      })
    )

    if (!reciprocal) reciprocal = yield fork(_resendDelayReciprocal)

    const { submit, resend, close } = yield race({
      submit: take(member_forgotPasswordResetModal_submit_watcher.type),
      resend: take(member_forgotPasswordResetModal_resendCode_watcher.type),
      close: take(member_modalClose_watcher.type),
    })

    if (submit) {
      const { email, verificationCode, newPassword, confirmNewPassword } =
        yield select(state => state.modal.high.props)

      yield call(_clearErrorMessageOnForgotPassword)

      if (newPassword !== confirmNewPassword)
        throw Error('Password and the confirmed password do not match')

      yield Auth.forgotPasswordSubmit(email, verificationCode, newPassword)
      yield call(_cancelReciprocal)

      yield put(
        modalActions.modal_show({
          priority: 'high',
          name: 'MEMBER_FORGOT_PASSWORD_SUCCESS',
        })
      )
    }

    if (resend) {
      const { email } = yield select(state => state.modal.high.props)
      yield call(_cancelReciprocal)
      Auth.forgotPassword(email)
      yield call(_forgotPasswordReset)
    }
    if (close) {
      yield call(_cancelReciprocal)
      yield call(_close)
    }
  } catch (error) {
    devWarLog('[handler] _forgotPasswordReset error:', error)

    yield put(
      modalActions.modal_props_set({
        priority: 'high',
        props: {
          errorMessage: String(error),
        },
      })
    )

    yield call(_forgotPasswordReset)
  }
}
