import React from 'react'
import { useDispatch } from 'react-redux'

import Modal from 'src/components/Popup/Modal'
import CommonButton from 'src/components/Button/CommonButton'

import { modalActions } from 'src/redux/slices/modal'

import { BiInfoCircle } from 'react-icons/bi'

const CaliReminder = props => {
  const dispatch = useDispatch()

  return (
    <Modal>
      <Modal.Icon>
        <BiInfoCircle className='text-white ' />
      </Modal.Icon>

      <Modal.Title>Regular Calibration Reminder</Modal.Title>

      <Modal.Description>
        Your product name has been used for more than time. In order to provide
        the best product experience, please return to the factory and update the
        calibration table regularly.
      </Modal.Description>

      <Modal.ColumnButtonBox>
        <CommonButton
          size='lg'
          type='general'
          onClick={() =>
            dispatch(modalActions.modal_hide({ priority: 'normal' }))
          }>
          OK
        </CommonButton>
      </Modal.ColumnButtonBox>
    </Modal>
  )
}

export default CaliReminder
