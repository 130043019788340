import React from 'react'
import * as R from 'ramda'
import { twMerge } from 'tailwind-merge'
import { useSelector, useDispatch } from 'react-redux'

import IconButton from 'src/components/Button/IconButton'

import { BiRightArrowAlt } from 'react-icons/bi'

import { pd_customCaliModal_getVoltClick_watcher } from 'src/redux/actions/powerDetector'

import useGetCurrentDeviceData from 'src/hooks/useGetCurrentDeviceData'

import { BiCheckCircle } from 'react-icons/bi'

const FreqItem = props => {
  const dispatch = useDispatch()
  const { freq, itemData } = props

  const { current } = useGetCurrentDeviceData()
  const sn = current.sn

  const currentGetVoltageList = useSelector(
    state => state.modal.normal?.props?.getVoltageEditList?.[freq]
  )

  const freqNumber = R.take(freq.length - 3)(freq)
  const freqUnit = R.takeLast(3)(freq)

  const { lowPower, lowVolt, highPower, highVolt, isDefault } = itemData

  const readVoltageIncompleteClass =
    currentGetVoltageList?.length === 1 ? ' border-yellow' : ''

  const readVoltageCompleteClass =
    currentGetVoltageList?.length === 2 ? ' border-transparent bg-dark-1' : ''

  const containerClass = twMerge(
    containerCommonClass,
    readVoltageIncompleteClass,
    readVoltageCompleteClass
  )

  const isWhiteText = target =>
    !isDefault || currentGetVoltageList?.includes(target) ? ` text-white` : ``

  const isDarkText = target =>
    isDefault || !currentGetVoltageList?.includes(target) ? ` text-dark-5` : ``

  const voltClass = target =>
    twMerge(
      rowItemClass + isDarkText(target),
      rowItemClass + isWhiteText(target)
    )

  const handleArrowButtonClick = ({ power, target }) =>
    dispatch(
      pd_customCaliModal_getVoltClick_watcher({ freq, sn, power, target })
    )

  return (
    <div className={containerClass}>
      <div className='w-full relative'>
        <h4 className='text-lg leading-6 font-bold text-light-4 text-center h-6'>
          {freqNumber} {freqUnit}
        </h4>
        {readVoltageCompleteClass && (
          <BiCheckCircle className='text-xl text-green absolute top-[2px] right-8' />
        )}
      </div>

      <div className={itemRowClass + ' text-light-5 text-sm'}>
        <div className={rowItemClass + ' translate-x-1'}>dBm</div>
        <div className={rowItemClass} />
        <div className={rowItemClass}>mV</div>
      </div>

      <div className={itemRowClass + ' text-white text-lg font-bold'}>
        <div className={rowItemClass}>{highPower}</div>
        <div className={rowItemClass}>
          <IconButton
            type='outlined'
            size='md'
            onClick={() =>
              handleArrowButtonClick({ power: highPower, target: 'highVolt' })
            }>
            <BiRightArrowAlt />
          </IconButton>
        </div>
        <div className={voltClass('highVolt')}>{highVolt}</div>
      </div>

      <div className={itemRowClass + ' text-white text-lg font-bold'}>
        <div className={rowItemClass}>{lowPower}</div>
        <div className={rowItemClass}>
          <IconButton
            type='outlined'
            size='md'
            onClick={() =>
              handleArrowButtonClick({ power: lowPower, target: 'lowVolt' })
            }>
            <BiRightArrowAlt />
          </IconButton>
        </div>
        <div className={voltClass('lowVolt')}>{lowVolt}</div>
      </div>
    </div>
  )
}

FreqItem.propTypes = {}

export default FreqItem

const containerCommonClass = `w-[192px] h-[192px] 
                    rounded-3xl 
                    border-1 border-solid border-dark-5 
                    flex flex-col 
                    items-center justify-center 
                    gap-y-3 
                    duration-500
                    `

const itemRowClass = `grid grid-cols-3 gap-x-6 `
const rowItemClass = `w-8 flex items-center justify-center`
