import React from 'react'
import { useDispatch } from 'react-redux'

import Modal from 'src/components/Popup/Modal'
import CommonButton from 'src/components/Button/CommonButton'

import { modalActions } from 'src/redux/slices/modal'

const DiscardUnsavedChanges = props => {
  const dispatch = useDispatch()

  const handleDiscardClick = () => {
    dispatch(modalActions.modal_hide({ priority: 'high' }))
    dispatch(modalActions.modal_hide({ priority: 'normal' }))
  }
  const handleCancelClick = () =>
    dispatch(modalActions.modal_hide({ priority: 'high' }))

  return (
    <Modal>
      <Modal.Title>Discard unsaved changes?</Modal.Title>

      <Modal.RowButtonBox>
        <CommonButton
          size='lg'
          type='primary'
          danger
          onClick={handleDiscardClick}>
          Discard
        </CommonButton>
        <CommonButton size='lg' type='general' onClick={handleCancelClick}>
          Cancel
        </CommonButton>
      </Modal.RowButtonBox>
    </Modal>
  )
}

export default DiscardUnsavedChanges
