import React, { useState, useRef, useEffect } from 'react'
import { useDispatch } from 'react-redux'

import Modal from 'src/components/Popup/Modal'
import SegmentedButton from 'src/components/Button/SegmentedButton'
import CommonInput from 'src/components/Input/CommonInput'
import PasswordInput from 'src/components/Input/PasswordInput'
import Checkbox from 'src/components/Button/Checkbox'
import CommonButton from 'src/components/Button/CommonButton'

import { modalActions } from 'src/redux/slices/modal'

import {
  member_modalClose_watcher,
  member_signInSignUpModal_signInClick_watcher,
  member_signInSignUpModal_createAccountClick_watcher,
  member_signInSignUpModal_forgotPasswordClick_watcher,
} from 'src/redux/actions/facility/member'
import { useSelector } from 'react-redux'

const SignInSignUp = props => {
  const dispatch = useDispatch()
  const emailInputRef = useRef()
  const modalProps = useSelector(state => state.modal.high.props)

  const {
    tabs,
    email,
    password,
    confirmPassword,
    preferredUsername,
    errorMessage,
  } = modalProps

  const [checked, setChecked] = useState(false)

  const createAccountSubmitDisabled = tabs === 'createAccount' && !checked

  const handleTabsChange = (event, value) =>
    dispatch(
      modalActions.modal_props_set({
        priority: 'high',
        props: { tabs: value, errorMessage: '' },
      })
    )

  const handleInputChange = ({ value, label }) =>
    dispatch(
      modalActions.modal_props_set({
        priority: 'high',
        props: { [label]: value, errorMessage: '' },
      })
    )

  const handleSignIn = () =>
    dispatch(member_signInSignUpModal_signInClick_watcher())
  const handleCreateAccount = () =>
    dispatch(member_signInSignUpModal_createAccountClick_watcher())
  const handleSubmit = event => {
    event.preventDefault()

    if (tabs === 'signIn') return handleSignIn()
    if (tabs === 'createAccount' && !createAccountSubmitDisabled)
      handleCreateAccount()
  }

  const handleForgotPasswordClick = () =>
    dispatch(member_signInSignUpModal_forgotPasswordClick_watcher())
  const handleCloseIconClick = () => dispatch(member_modalClose_watcher())

  useEffect(() => {
    if (emailInputRef.current) emailInputRef.current.focus()
  }, [tabs])

  return (
    <Modal>
      <Modal.CloseIcon onClick={handleCloseIconClick} />

      <Modal.Title>TMXLAB Kit</Modal.Title>

      <div className='flex flex-col mt-4'>
        <SegmentedButton
          className='mb-2'
          size='md'
          type='outlined'
          value={tabs}
          onChange={handleTabsChange}>
          <SegmentedButton.Option name='signIn'>Sign In</SegmentedButton.Option>
          <SegmentedButton.Option name='createAccount'>
            Create Account
          </SegmentedButton.Option>
        </SegmentedButton>

        {errorMessage && <div className='text-red mt-6'>{errorMessage}</div>}

        <form className='w-full flex flex-col' onSubmit={handleSubmit}>
          <Modal.FormLabel htmlFor='email' className='mt-6'>
            Email
          </Modal.FormLabel>
          <CommonInput
            ref={emailInputRef}
            id='email'
            size='lg'
            value={email}
            placeholder='Enter your email'
            autoFocus
            onChange={e =>
              handleInputChange({ value: e.formattedValue, label: 'email' })
            }
          />
          <Modal.FormLabel htmlFor='password' className='mt-6'>
            Password
          </Modal.FormLabel>
          <PasswordInput
            id='password'
            size='lg'
            value={password}
            placeholder='Enter your password'
            onChange={e =>
              handleInputChange({
                value: e.formattedValue,
                label: 'password',
              })
            }
          />
          <small className='text-white/50 mt-2'>At least 8 characters</small>

          {tabs === 'createAccount' && (
            <>
              <Modal.FormLabel htmlFor='confirmPassword' className='mt-6'>
                Confirm Password
              </Modal.FormLabel>
              <PasswordInput
                id='confirmPassword'
                size='lg'
                value={confirmPassword}
                placeholder='Please confirm your password'
                onChange={e =>
                  handleInputChange({
                    value: e.formattedValue,
                    label: 'confirmPassword',
                  })
                }
              />

              <Modal.FormLabel htmlFor='userName' className='mt-6'>
                Name
              </Modal.FormLabel>
              <CommonInput
                id='userName'
                size='lg'
                value={preferredUsername}
                placeholder='Enter your name'
                onChange={e =>
                  handleInputChange({
                    value: e.formattedValue,
                    label: 'preferredUsername',
                  })
                }
              />

              <div
                className='flex items-center justify-center gap-x-3 mt-9 mb-4 text-light-1 cursor-pointer'
                onClick={() => setChecked(prev => !prev)}>
                <Checkbox
                  className='shrink-0'
                  status={checked ? 'checked' : 'uncheck'}
                />
                <p>
                  I’ve read and agree to the
                  <a
                    href='https://www.tmytek.com/legal/privacy'
                    target='_blank'
                    rel='noreferrer'
                    className='underline underline-offset-4 ml-1'
                    onClick={e => e.stopPropagation()}>
                    Privacy Policy
                  </a>
                </p>
              </div>
              {!checked && (
                <div className='text-center text-yellow'>
                  You must agree to the Privacy Policy
                </div>
              )}
            </>
          )}

          <Modal.ColumnButtonBox>
            <CommonButton
              buttonType='submit'
              size='lg'
              type='primary'
              disabled={createAccountSubmitDisabled}>
              {tabs === 'createAccount' ? 'Create Account' : 'Sign In'}
            </CommonButton>

            {tabs === 'signIn' && (
              <CommonButton
                type='text'
                size='lg'
                onClick={handleForgotPasswordClick}>
                Forgot password?
              </CommonButton>
            )}
          </Modal.ColumnButtonBox>
        </form>
      </div>
    </Modal>
  )
}

export default SignInSignUp
