import { call, take, put } from 'redux-saga/effects'

import { modalActions } from 'src/redux/slices/modal'

import { lottie_loadingAnimateModal_complete_watcher } from 'src/redux/actions/facility/home'

import {
  setIsIpChanged,
  tempIpUpdateFromDeviceData,
} from 'src/redux/slices/uiControl/facility/home'

import { getCurrentData } from 'src/redux/sagas/selector/deviceData'
import {
  setSingleDeviceData,
  setScanDevicesData,
} from 'src/redux/slices/deviceData'

import { devWarLog } from 'src/funcs/tools'

export function* applyDeviceInfo(response) {
  try {
    const newDeviceData = response?.data

    yield put(setIsIpChanged(false))

    // 設 ip be 會 reboot 所有 devices 並重新 scan
    // 所以要寫回來更新 deviceData
    yield put(setScanDevicesData(newDeviceData))
    yield put(tempIpUpdateFromDeviceData(newDeviceData))

    yield put(
      modalActions.modal_show({
        priority: 'normal',
        name: 'ANIMATION_TMYTEK_LOGO_SHORT_TIME',
        props: { status: 'complete' },
      })
    )
    yield take(lottie_loadingAnimateModal_complete_watcher.type)
    yield put(modalActions.modal_hide({ priority: 'normal' }))
  } catch (error) {
    devWarLog(error)
  }
}

export function* deviceInitGetInfo(response) {
  try {
    const [sn, { info }] = Object.entries(response?.data)[0]
    let newCurrentData = yield call(getCurrentData, sn)

    newCurrentData.info = info

    yield put(setSingleDeviceData({ sn, data: newCurrentData }))
  } catch (error) {
    devWarLog(error)
  }
}

export function* deviceReboot(response) {
  try {
    const [sn, deviceData] = Object.entries(response?.data)[0]

    // 這邊的 deviceData = 剛 scan 完成的 該 sn 的 deviceData
    // 所以內容物只有 { info }

    yield put(setSingleDeviceData({ sn, data: deviceData }))
  } catch (error) {
    devWarLog(error)
  }
}
