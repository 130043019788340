import { call, put } from 'redux-saga/effects'

import { udboxUIControlForSingleAction as uiControlAction } from 'src/redux/slices/uiControl/freqConverter/udbox'

import { modalActions } from 'src/redux/slices/modal'

import { devWarLog } from 'src/funcs/tools'

import { getCommonArgs } from 'src/redux/sagas/selector/deviceData'

import { setSingleDeviceData } from 'src/redux/slices/deviceData'

import responseToDeviceError from 'src/redux/sagas/handlers/errorResponse/responseToDeviceError'

export function* deviceSetting({ response, api }) {
  try {
    const [sn, { label, value, ledIndicators }] = Object.entries(
      response?.data
    )[0]

    // 就算有錯誤也要更新燈號
    let { currentData } = yield call(getCommonArgs, sn)
    currentData.current.ledIndicators = ledIndicators
    yield put(setSingleDeviceData({ sn, data: currentData }))

    yield put(uiControlAction.clearUD5gRefSourceStatus({ sn }))

    if (label === 'SOURCE_100M' && +value === 1) {
      yield put(
        modalActions.modal_show({
          priority: 'normal',
          name: 'FREQ_CONVERTER_UDBOX_TO_EXTERNAL_ERROR',
          props: { sn },
        })
      )
    } else {
      yield call(responseToDeviceError, sn)
      yield put(modalActions.modal_all_hide())
    }
  } catch (error) {
    devWarLog('[handler] deviceSetting error:', error)
  }
}
