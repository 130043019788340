import React from 'react'
import moment from 'moment'
import { useDispatch } from 'react-redux'
import { ErrorBoundary } from 'react-error-boundary'

import DeviceSomethingWereWrong from 'src/containers/operating/DeviceSomethingWereWrong'

import { errorActions } from 'src/redux/slices/uiControl/facility/error'

import useGetCurrentDeviceData from 'src/hooks/useGetCurrentDeviceData'

const ErrorBoundaryOfDevice = props => {
  const dispatch = useDispatch()
  const { current } = useGetCurrentDeviceData()
  const sn = current.sn

  const { deviceName = '', handleRetry } = props

  const handleError = (info, error) => {
    const time = moment().format('YYYY-MM-DD  HH:mm:ss')
    dispatch(
      errorActions.setDeviceError({
        sn,
        errorData: {
          time,
          code: 1,
          codeName: 'FE Device ERROR',
          message: `${deviceName ? `Device: [${deviceName}]` : ''} ${String(
            info
          )} \n ${String(error.componentStack)}`,
        },
      })
    )
  }

  return (
    <ErrorBoundary
      fallback={<DeviceSomethingWereWrong />}
      onReset={handleRetry}
      onError={handleError}>
      {props.children}
    </ErrorBoundary>
  )
}

ErrorBoundaryOfDevice.propTypes = {}

export default ErrorBoundaryOfDevice
