import React from 'react'

import Timeout from 'src/containers/Modal/High/Timeout'
import SignInSignUp from 'src/containers/Modal/High/Member/SignInSignUp'

import SignUpVerification from 'src/containers/Modal/High/Member/SignUpVerification'
import SignUpSuccess from 'src/containers/Modal/High/Member/SignUpSuccess'

import ForgotPasswordSendMail from 'src/containers/Modal/High/Member/ForgotPasswordSendMail'
import ForgotPasswordReset from 'src/containers/Modal/High/Member/ForgotPasswordReset'
import ForgotPasswordSuccess from 'src/containers/Modal/High/Member/ForgotPasswordSuccess'

import ClearCheck from 'src/containers/Modal/High/beamformers/bbox/beamConfigEditor/ClearCheck'
import DiscardUnsavedChanges from 'src/containers/Modal/High/beamformers/bbox/beamConfigEditor/DiscardUnsavedChanges'

const HighModal = ({ name, props }) => {
  if (name === 'ERROR_TIMEOUT') return <Timeout {...props} />

  if (name === 'MEMBER_SIGN_IN_SIGN_UP') return <SignInSignUp {...props} />

  if (name === 'MEMBER_SIGN_UP_VERIFICATION')
    return <SignUpVerification {...props} />
  if (name === 'MEMBER_SIGN_UP_SUCCESS') return <SignUpSuccess {...props} />
  if (name === 'MEMBER_FORGOT_PASSWORD_SEND_EMAIL')
    return <ForgotPasswordSendMail {...props} />

  if (name === 'MEMBER_FORGOT_PASSWORD_RESET')
    return <ForgotPasswordReset {...props} />
  if (name === 'MEMBER_FORGOT_PASSWORD_SUCCESS')
    return <ForgotPasswordSuccess {...props} />

  if (name === 'BEAMFORMERS_BBOX_BEAM_CONFIG_EDITOR_CLEAR_CHECK')
    return <ClearCheck {...props} />

  if (name === 'BEAMFORMERS_BBOX_BEAM_CONFIG_EDITOR_DISCARD_UNSAVED_CHANGES')
    return <DiscardUnsavedChanges {...props} />

  return <></>
}

export default HighModal
