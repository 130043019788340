import React, { useState } from 'react'
import ChangeProfilePhotoEdit from 'src/containers/Modal/Normal/Member/ChangeProfilePhoto/ChangeProfilePhotoEdit'
import ChangeProfilePhotoUpload from 'src/containers/Modal/Normal/Member/ChangeProfilePhoto/ChangeProfilePhotoUpload'

const ChangeProfilePhoto = () => {
  const uploadFile = useState('')

  if (!uploadFile?.[0])
    return <ChangeProfilePhotoUpload uploadFileState={uploadFile} />
  if (uploadFile?.[0])
    return <ChangeProfilePhotoEdit uploadFileState={uploadFile} />
}

ChangeProfilePhoto.propTypes = {}

export default ChangeProfilePhoto
