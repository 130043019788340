import React from 'react'
import { useDispatch } from 'react-redux'

import CommonButton from 'src/components/Button/CommonButton'
import Tooltip from 'src/components/Popup/Tooltip'

import { BiExport, BiInfoCircle } from 'react-icons/bi'
import { AiOutlineClear } from 'react-icons/ai'

import { h4 } from 'src/containers/operating/device/powerDetector/style'

import useGetCurrentDeviceData from 'src/hooks/useGetCurrentDeviceData'
import useGetCurrentMode from 'src/hooks/useGetCurrentMode'

import { pd_exportPowerHistoryClick_watcher } from 'src/redux/actions/powerDetector'
import { modalActions } from 'src/redux/slices/modal'
import useGetUIControl from 'src/hooks/useGetUIControl'

const Info = props => {
  const dispatch = useDispatch()
  const { current } = useGetCurrentDeviceData()
  const { isDemoMode } = useGetCurrentMode()
  const sn = current?.sn

  const currentPowerValue =
    current.data.deviceControl.dashboard.currentPowerValue

  const isCurrentPowerValueError = +currentPowerValue === -1000

  const { single: s_uiControl } = useGetUIControl(sn)

  const measureDataLength = s_uiControl?.measureDataLength
  const isPowerReading = s_uiControl?.isPowerReading

  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------       Event        -----------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  const handleClearClick = () =>
    dispatch(
      modalActions.modal_show({
        priority: 'normal',
        name: 'PD_CLEAR_THE_CURRENTLY_READ_DATA',
        props: {},
      })
    )

  const handleExportHistoryClick = () =>
    dispatch(pd_exportPowerHistoryClick_watcher({ sn }))

  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------        JSX         -----------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  return (
    <div className='px-5 mb-4'>
      <div className={infoClass}>
        <div className='h-full px-4 flex flex-col justify-between'>
          <h4 className={h4 + ' flex'}>
            Current Power Value{' '}
            <span className='block md:hidden ml-1'>(dBm)</span>{' '}
          </h4>

          <div className='h-14 flex items-end justify-center md:justify-start'>
            {isCurrentPowerValueError ? (
              <h3 className='text-5xl text-red font-bold'>ERROR</h3>
            ) : (
              <>
                <h3
                  className={`${
                    !currentPowerValue ? 'text-light-5' : 'text-white'
                  } text-5xl font-bold`}>
                  {currentPowerValue || '----'}
                </h3>
                <small className='text-sm text-light-5 ml-2 -translate-y-1 hidden md:block'>
                  dBm
                </small>
              </>
            )}
          </div>
        </div>

        <div className='h-full flex flex-col justify-between items-center md:items-end mt-5 md:mt-0'>
          <div className='flex gap-x-4'>
            <CommonButton
              icon={<AiOutlineClear />}
              type='outlined'
              size='sm'
              danger
              disabled={isPowerReading || measureDataLength === 0}
              onClick={handleClearClick}>
              Clear
            </CommonButton>

            <Tooltip disabled={!isDemoMode} title='No support in demo mode'>
              <CommonButton
                icon={<BiExport />}
                type='outlined'
                size='sm'
                disabled={
                  isPowerReading || measureDataLength === 0 || isDemoMode
                }
                onClick={handleExportHistoryClick}>
                Export
              </CommonButton>
            </Tooltip>
          </div>

          <h4 className={h4 + ' flex opacity-80 mt-2'}>
            Current {measureDataLength} records. Maximum 960 records.
            <Tooltip
              placement='bottom'
              title='The system keeps 960 records in total. Overwrite the oldest one'>
              <BiInfoCircle className='cursor-pointer' />
            </Tooltip>
          </h4>
        </div>
      </div>

      {isCurrentPowerValueError && (
        <small className='text-sm text-red px-4'>
          Something went wrong. Please re-scan and verify that your calibration
          config profile settings are correct.
        </small>
      )}
    </div>
  )
}

export default Info

const infoClass = `md:h-[80px] 
                    mb-4 
                    flex justify-between 
                    flex-col md:flex-row 
                    items-center
                    `
