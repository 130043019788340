import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import ErrorBoundaryOfDevice from 'src/containers/ErrorBoundary/Device'

import Current from 'src/containers/operating/device/freqConverter/general/Current'
import FreqSettings from 'src/containers/operating/device/freqConverter/general/FreqSettings'
import DeviceSettings from 'src/containers/operating/device/freqConverter/udModule/DeviceSettings'
import DeviceInitializing from 'src/containers/operating/DeviceInitializing'
import DeviceSomethingWereWrong from 'src/containers/operating/DeviceSomethingWereWrong'

import SegmentedButton from 'src/components/Button/SegmentedButton'
import CoBrandingBar from 'src/components/CoBrandingBar'

import useGetCurrentDeviceData from 'src/hooks/useGetCurrentDeviceData'
import useGetScreenSize from 'src/hooks/useGetScreenSize'
import useGetHelmet from 'src/hooks/useGetHelmet'

import { ud_general_initDevice_watcher } from 'src/redux/actions/freqConverter/generalAction'

import { udboxUIControlForSingleAction as uiControlAction } from 'src/redux/slices/uiControl/freqConverter/udbox'
import useGetUIControl from 'src/hooks/useGetUIControl'

const UdModule = () => {
  const { isSM, isMD, isLG, isXL, is2XL } = useGetScreenSize()

  const dispatch = useDispatch()
  const { current } = useGetCurrentDeviceData()
  const sn = current.sn
  const info = current.data?.info

  // smallSizeControlMode 用來控制小銀幕時 內容要顯示 freqSetting || deviceSetting
  // 跟 bbox UIControl 的 padControlContent 一樣用來切換小尺寸的內容，為了規格一致 ud 也一起
  const { single: s_uiControl } = useGetUIControl(sn)
  const smallSizeControlMode = s_uiControl?.smallSizeControlMode

  const iSmallSize = isSM || isMD || isLG
  const isPC = isXL || is2XL

  useEffect(() => {
    if (info?.initialization === 'unfinished') {
      dispatch(ud_general_initDevice_watcher({ sn }))
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sn])

  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------        JSX         -----------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  if (info?.initialization === 'unfinished') return <DeviceInitializing />
  if (info?.initialization === 'failed')
    return (
      <DeviceSomethingWereWrong
        handleRetry={() => dispatch(ud_general_initDevice_watcher({ sn: sn }))}
      />
    )

  // ==================================
  // small size = 直立式, 操作區需藉由切換決定要顯示什麼
  // ==================================
  if (iSmallSize)
    return (
      <div className='flex flex-col gap-y-8 md:gap-y-6 lg:gap-y-4'>
        <CoBrandingBar />
        <Current />

        <SegmentedButton
          size='md'
          type='solid'
          value={smallSizeControlMode}
          onChange={(event, value) =>
            dispatch(uiControlAction.changeSmallSizeControlMode({ sn, value }))
          }>
          <SegmentedButton.Option name='Frequency Settings'>
            Frequency Settings
          </SegmentedButton.Option>
          <SegmentedButton.Option name='Device Settings'>
            Device Settings
          </SegmentedButton.Option>
        </SegmentedButton>

        {smallSizeControlMode === 'Frequency Settings' && <FreqSettings />}

        {smallSizeControlMode === 'Device Settings' && <DeviceSettings />}
      </div>
    )

  // ==================================
  // 橫式 標準版 所有區塊全展開直接顯示
  // ==================================
  if (isPC)
    return (
      <div>
        <div className='flex gap-x-4'>
          <div className='w-[632px] flex flex-col gap-y-4'>
            <CoBrandingBar />

            <Current />

            <FreqSettings />
          </div>

          <div className='w-[416px]'>
            <DeviceSettings />
          </div>
        </div>
      </div>
    )
}

const UdModuleWithErrorBoundary = () => {
  const { current } = useGetCurrentDeviceData()
  const deviceType = current.deviceType
  const helmet = useGetHelmet({ deviceType })

  return (
    // TODO: retry 補 call re-init api
    <ErrorBoundaryOfDevice
      deviceName='udmodule'
      handleRetry={() => console.log('Error Boundary Retry Click')}>
      {helmet}
      <UdModule />
    </ErrorBoundaryOfDevice>
  )
}

export default UdModuleWithErrorBoundary
