import { createSlice } from '@reduxjs/toolkit'

// import low from 'src/redux/slices/modal/low'
// import normal from 'src/redux/slices/modal/normal'
// import high from 'src/redux/slices/modal/high'
// import emergency from 'src/redux/slices/modal/emergency'
// import { forEach } from 'ramda'

export const modal = createSlice({
  name: 'modal',
  initialState: {
    emergency: { name: '', props: {} },
    high: { name: '', props: {} },
    normal: { name: '', props: {} },
    low: { name: '', props: {} },
  },
  reducers: {
    modal_show(state, action) {
      const { priority, name, props } = action.payload

      if (props) state[priority] = { name, props }

      // 改 modal 但 props 要沿用，這時 props 就不給就好
      if (!props) state[priority] = { ...state[priority], name }

      return state
    },

    modal_hide(state, action) {
      const { priority } = action.payload
      state[priority] = { name: '', props: {} }
      return state
    },

    modal_all_hide(state, action) {
      state['emergency'] = { name: '', props: {} }
      state['high'] = { name: '', props: {} }
      state['normal'] = { name: '', props: {} }
      state['low'] = { name: '', props: {} }
      return state
    },

    modal_props_set(state, action) {
      const { priority, props } = action.payload
      Object.entries(props).forEach(
        ([key, value]) => (state[priority].props[key] = value)
      )
      return state
    },

    // ...emergency,
    // ...high,
    // ...normal,
    // ...low,
  },
})

export default modal.reducer

export const modalActions = modal.actions
