import { call, delay, select, put } from 'redux-saga/effects'
import * as R from 'ramda'

import { getCommonArgs } from 'src/redux/sagas/selector/deviceData'
import { setSingleDeviceData } from 'src/redux/slices/deviceData'

import { devWarLog } from 'src/funcs/tools'

import { modalActions } from 'src/redux/slices/modal'

import { getInitCaliData } from 'src/constants/powerDetector'
import { defaultProps } from 'src/containers/Modal/Normal/PowerDetector/CustomCalibration'

export function* insertCaliData(response) {
  try {
    const [sn, data] = Object.entries(response?.data)[0]
    const { currentData } = yield call(getCommonArgs, sn)

    const initCaliDataValue = Object.values(getInitCaliData())[0].value

    let [responseCaliID, responseCaliData] = Object.entries(data)[0]

    const insertData = data
    insertData[responseCaliID].value = {
      ...initCaliDataValue,
      ...responseCaliData.value,
    }

    currentData.deviceControl.actionPanel.caliData = {
      ...currentData.deviceControl.actionPanel.caliData,
      ...insertData,
    }

    yield put(setSingleDeviceData({ sn, data: currentData }))
    yield put(
      modalActions.modal_props_set({
        priority: 'normal',
        props: { child_modal_show: 'CALI_CONFIG_FILE_SAVED' },
      })
    )
    yield delay(1000)
    yield put(
      modalActions.modal_props_set({
        priority: 'normal',
        props: defaultProps,
      })
    )
  } catch (error) {
    devWarLog('[res-handler] insertCaliData error:', error)
  }
}

export function* updateCaliData(response) {
  try {
    const [sn, data] = Object.entries(response?.data)[0]
    const { currentData } = yield call(getCommonArgs, sn)

    const [id, content] = Object.entries(data)[0]

    // 更新 caliData
    const previousCaliDataValue =
      currentData.deviceControl.actionPanel.caliData[id].value

    currentData.deviceControl.actionPanel.caliData[id] = content

    currentData.deviceControl.actionPanel.caliData[id].value = {
      ...previousCaliDataValue,
      ...content.value,
    }

    yield put(setSingleDeviceData({ sn, data: currentData }))
    yield put(
      modalActions.modal_props_set({
        priority: 'normal',
        props: { child_modal_show: 'CALI_CONFIG_FILE_SAVED' },
      })
    )
    yield delay(1000)
    yield put(
      modalActions.modal_props_set({
        priority: 'normal',
        props: defaultProps,
      })
    )
  } catch (error) {
    devWarLog('[res-handler] updateCaliData error:', error)
  }
}

export function* deleteCaliData(response) {
  try {
    const [sn, { caliData }] = Object.entries(response?.data)[0]
    const { currentData } = yield call(getCommonArgs, sn)

    currentData.deviceControl.actionPanel.caliData = caliData

    yield put(setSingleDeviceData({ sn, data: currentData }))

    // 清空 selectIdList
    yield put(
      modalActions.modal_props_set({
        priority: 'normal',
        props: {
          selectIdList: [],
        },
      })
    )

    yield put(
      modalActions.modal_props_set({
        priority: 'normal',
        props: {
          isDeleteRequesting: false,
        },
      })
    )
    yield put(
      modalActions.modal_props_set({
        priority: 'normal',
        props: defaultProps,
      })
    )
  } catch (error) {
    devWarLog('[res-handler] updateCaliData error:', error)
  }
}

export function* getCaliVoltage(response) {
  try {
    const [, freqPowerVolt] = Object.entries(response?.data)[0]
    const [freq, { power, voltage }] = Object.entries(freqPowerVolt)[0]

    // 1. 塞 response voltage 到目標欄位
    const editData = yield select(state => state.modal.normal.props.editData)
    let cloneTempData = R.clone(editData)

    const [[id, content]] = Object.entries(cloneTempData)

    let targetVoltage
    let oppositeVoltage

    if (+content.value[freq].lowPower === +power) {
      targetVoltage = 'lowVolt'
      oppositeVoltage = 'highVolt'
    } else if (+content.value[freq].highPower === +power) {
      targetVoltage = 'highVolt'
      oppositeVoltage = 'lowVolt'
    } else throw Error('getCaliVoltage: BE return power not matched!')

    cloneTempData[id].value[freq][targetVoltage] = voltage

    // 2. 判斷是否要將 oppositeVoltage 設成 '---'
    const getVoltageEditList = yield select(
      state => state.modal.normal.props.getVoltageEditList
    )
    const isNeedChangeOppositeValueToDash =
      !getVoltageEditList[freq].includes(oppositeVoltage)

    if (isNeedChangeOppositeValueToDash)
      cloneTempData[id].value[freq][oppositeVoltage] = '---'

    // 3. 判斷是否要調整 isDefault (同時滿足 3-1 & 3-2)
    // 3-1 low, high 都 getVolt
    // 3-2 low, high 都不能是 '---'
    const isNeedChangeIsDefaultToFalse =
      getVoltageEditList[freq].length === 2 && !isNeedChangeOppositeValueToDash

    if (isNeedChangeIsDefaultToFalse)
      cloneTempData[id].value[freq]['isDefault'] = false
    if (!isNeedChangeIsDefaultToFalse)
      cloneTempData[id].value[freq]['isDefault'] = true

    yield put(
      modalActions.modal_props_set({
        priority: 'normal',
        props: {
          isEditChanged: true,
          editData: cloneTempData,
        },
      })
    )
  } catch (error) {
    devWarLog('[res-handler] caliSelect error:', error)
  }
}
