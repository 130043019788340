import * as R from 'ramda'
import { call, put, select, delay } from 'redux-saga/effects'

import { setSingleDeviceData } from 'src/redux/slices/deviceData'
import { udboxUIControlForSingleAction as uiControlAction } from 'src/redux/slices/uiControl/freqConverter/udbox'

import { modalActions } from 'src/redux/slices/modal'

import { getCurrentData } from 'src/redux/sagas/selector/deviceData'

import { devWarLog } from 'src/funcs/tools'

export function* deviceSetting(response) {
  try {
    const [sn, { label, value, ledIndicators }] = Object.entries(
      response?.data
    )[0]

    // 1. 更新 device 的 value
    // 2. 更新 ledIndicators
    let currentData = yield call(getCurrentData, sn)
    currentData.settings.device[label] = value
    currentData.current.ledIndicators = ledIndicators
    yield put(setSingleDeviceData({ sn, data: currentData }))

    // 這邊做 modal 相關處理
    if (label === 'SOURCE_100M') {
      // 切 external success
      if (+value === 1) {
        // [spec]
        // 如果切到 external, 要把 output 100m 關掉
        // (因為 都吃外部訊號了，不可能還當訊號源 output 給別人)
        let currentData = yield call(getCurrentData, sn)
        currentData = R.assocPath(
          ['settings', 'device', 'OUT_100M'],
          0
        )(currentData)
        yield put(setSingleDeviceData({ sn, data: currentData }))

        // 顯示 success 打勾 然後 delay 1000 再關 Modal
        yield put(uiControlAction.setUD5gRefSourceIsSuccess({ sn }))
        yield delay(1000)
        yield put(modalActions.modal_hide({ priority: 'normal' }))
        yield put(uiControlAction.clearUD5gRefSourceStatus({ sn }))
      }

      // 切 internal success
      if (+value === 0) {
        // [spec]
        // 如果回 internal
        // 讓 output 100MHz 的按鈕直接跟燈號狀態一致
        // (因為這燈號會 keep 在先前 internal 的狀態)
        let currentData = yield call(getCurrentData, sn)
        currentData = R.assocPath(
          ['settings', 'device', 'OUT_100M'],
          ledIndicators.OUT_100M
        )(currentData)

        yield put(setSingleDeviceData({ sn, data: currentData }))

        // 這邊要處理要關 normal modal 的情境是
        // 「切 external 失敗後按 cancel 後的 call internal 成功」
        const modalName = yield select(state => state.modal.normal.name)
        if (modalName === 'FREQ_CONVERTER_UDBOX_TO_EXTERNAL_ERROR') {
          yield put(uiControlAction.clearUD5gRefSourceStatus({ sn }))
          yield put(modalActions.modal_hide({ priority: 'normal' }))
        }
      }
    }
  } catch (error) {
    devWarLog('[res-handler] deviceSetting error:', error)
  }
}
