import React from 'react'
import { useSelector } from 'react-redux'

import PortalAndOverlay from 'src/components/Popup/Modal/PortalAndOverlay'

import ListMode from 'src/containers/Modal/Normal/beamformers/bbox/DeviceController/CustomAntenna/ListMode'
import EditMode from 'src/containers/Modal/Normal/beamformers/bbox/DeviceController/CustomAntenna/EditMode'
import ListModeDeleteCheck from 'src/containers/Modal/Normal/beamformers/bbox/DeviceController/CustomAntenna/ChildModal/ListModeDeleteCheck'
import EditModeDeleteCheck from 'src/containers/Modal/Normal/beamformers/bbox/DeviceController/CustomAntenna/ChildModal/EditModeDeleteCheck'
import LeaveEditCheck from 'src/containers/Modal/Normal/beamformers/bbox/DeviceController/CustomAntenna/ChildModal/LeaveEditCheck'
import UsingNotDeleteCheck from 'src/containers/Modal/Normal/beamformers/bbox/DeviceController/CustomAntenna/ChildModal/UsingNotDeleteCheck'

const CustomAntenna = () => {
  const modalProps = useSelector(state => state.modal.normal.props)
  const { editData, child_modal_show } = modalProps

  const hasEditData = Object.keys(editData).length !== 0

  return (
    <PortalAndOverlay
      open
      render={close => (
        <div className={modalClass}>
          {hasEditData ? <EditMode /> : <ListMode />}

          {child_modal_show === 'LIST_MODE_DELETE_CHECK' && (
            <ListModeDeleteCheck />
          )}
          {child_modal_show === 'EDIT_MODE_DELETE_CHECK' && (
            <EditModeDeleteCheck />
          )}
          {child_modal_show === 'LEAVE_EDIT_CHECK' && <LeaveEditCheck />}
          {child_modal_show === 'USING_NOT_DELETE_CHECK' && (
            <UsingNotDeleteCheck />
          )}
        </div>
      )}
    />
  )
}

export default CustomAntenna

const modalClass = `relative 
                    w-full max-w-[800px] 
                    bg-dark-2 
                    flex flex-col 
                    rounded-[8px]   
                    `
