import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import CommonButton from 'src/components/Button/CommonButton'

import { IoMdOpen } from 'react-icons/io'

import { isOffline } from 'src/funcs/getEnv'
import { setAcceptPrivacyPolicy } from 'src/redux/slices/uiControl/facility/settings'

const PrivacyBar = props => {
  const dispatch = useDispatch()

  const acceptPrivacyPolicy = useSelector(
    state => state.settings.acceptPrivacyPolicy
  )

  useEffect(() => {
    //* 取得是否已按過同意隱私權政策
    const accept = sessionStorage.getItem('acceptPrivacyPolicy')
    dispatch(setAcceptPrivacyPolicy({ value: accept }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleAcceptAllClick = () => {
    sessionStorage.setItem('acceptPrivacyPolicy', true)
    dispatch(setAcceptPrivacyPolicy({ value: true }))
  }

  if (!acceptPrivacyPolicy) {
    if (!isOffline)
      return (
        <div className={container}>
          <p className={content}>
            This website uses cookies to collect your information for improving
            the service quality. This helps us to better understand how our site
            is used and how to optimize it. You can withdraw your consent at any
            time. More information is visible in our Privacy Policy.
          </p>

          <div className={buttonBox}>
            <CommonButton
              icon={<IoMdOpen />}
              size='lg'
              type='outlined'
              href='https://www.tmytek.com/legal/privacy'>
              Details
            </CommonButton>
            <CommonButton
              size='lg'
              type='primary'
              onClick={handleAcceptAllClick}>
              Accept All
            </CommonButton>
          </div>
        </div>
      )

    if (isOffline)
      return (
        <div className={container}>
          <p className={content}>
            User information will not be collected or transmitted to TMYTEK by
            this software/application program.
          </p>
          <CommonButton size='lg' type='primary' onClick={handleAcceptAllClick}>
            Accept All
          </CommonButton>
        </div>
      )
  }

  return <></>
}

export default PrivacyBar

const container = `
                    fixed z-50
                    bottom-0 inset-x-0 
                    bg-black/70 backdrop-blur 
                    py-10 px-12
                    flex flex-col xl:flex-row justify-center items-center
                    gap-8
                    `

const buttonBox = `flex gap-x-6`

const content = `  
                max-w-[844px]
                text-white 
                text-base leading-5 font-normal 
                `
