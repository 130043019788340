import {
  fork,
  take,
  call,
  put,
  flush,
  actionChannel,
  throttle,
  delay,
  select,
  cancel,
} from 'redux-saga/effects'
import { eventChannel, buffers, END } from 'redux-saga'
import { devLog, devWarLog } from 'src/funcs/tools'
import { isDeveloping } from 'src/funcs/getEnv'
import { getIsDemoMode } from 'src/redux/sagas/funcs/general'

import { modalActions } from 'src/redux/slices/modal'

function* apiThrottle({ tag, throttleAction, emitAction }) {
  try {
    yield throttle(1000, throttleAction, putEmitAction, tag, emitAction)
  } catch (error) {
    devWarLog('[socketEmitCreator - > apiThrottle] error:', error)
  }
}

//! queueArray 相關的是 queue CRUD 管理
// let queueArray = { beamformers: [], freqConverter: [], facility: [] }

function* apiNormal({ tag, normalAction, emitAction }) {
  try {
    while (true) {
      const action = yield take(normalAction.type)
      yield fork(putEmitAction, tag, emitAction, action)
    }
  } catch (error) {
    devWarLog('[socketEmitCreator - > apiNormal] error:', error)
  }
}

function* putEmitAction(tag, emitAction, action) {
  try {
    //! queueArray 相關的是 queue CRUD 管理
    // const { sn } = action.payload.data
    // const { queueID, eventName } = action.payload

    // queue management insert
    // queueArray[tag] = [
    //   ...queueArray[tag],
    //   {
    //     queueID,
    //     sn,
    //     eventName,
    //     cancel: false,
    //     // cancel: Math.random() * 10 > 5 ? false : true,
    //   },
    // ]

    yield put(emitAction(action.payload))
  } catch (error) {
    devWarLog('[socketEmitCreator - > putEmitAction] error:', error)
  }
}

// 踏入进阶！redux-saga中的八个高阶知识点
// 1.2 actionChannel
// https://juejin.cn/post/7009110590752817183
function* apiQueueSender({ tag, emitAction, emitPkg, callbackAction }) {
  // while 包 try catch 是讓這裡收到錯誤還可以繼續正常跑
  while (true) {
    try {
      const requestChan = yield actionChannel(
        emitAction.type,
        buffers.fixed(30)
      )

      while (true) {
        const action = yield take(requestChan)
        const isDemoMode = yield call(getIsDemoMode)

        if (!isDemoMode) {
          const {
            //  queueID,
            eventName,
            data,
            beforeEmitActionType,
            timeout,
          } = action.payload

          //! queueArray 相關的是 queue CRUD 管理
          // queue management
          // const isCancel = yield queueArray[tag].find(e => e.queueID === queueID)
          //   ?.cancel

          // if (isCancel) {
          // queue management pop
          // queueArray[tag] = yield queueArray[tag].splice(1)
          // }

          // if (!isCancel) {

          if (beforeEmitActionType)
            yield put({ type: beforeEmitActionType, payload: data })

          const emitChannel = yield call(emitPkg, eventName, data)

          const timeoutFunc = yield fork(timeoutProcess, { eventName, timeout })

          // 這裡的 success = ack(acknowledgement)
          const ack = yield take(emitChannel)

          // queue management pop
          // queueArray[tag] = yield queueArray[tag].splice(1)

          yield cancel(timeoutFunc)

          // 如果收到 ack === response.code
          // ack !== 0 代表有 error, 就把 requestChan 清空
          if (ack !== 0) {
            yield flush(requestChan)
            // queueArray[tag] = []
          }

          yield put(callbackAction(ack))
        }
      }
      // }
    } catch (error) {
      devWarLog('[socketEmitCreator -> apiQueueSender] error:', error)
    }
  }
}

// props 全貌
// const {
//   tag,
//   lookupID,
//   socket,
//   emitAction,
//   callbackAction,
//   throttleAction,
//   normalAction,
// } = props
export default function* socketEmitCreator(props) {
  const { tag, lookupID, socket } = props

  const emitPkg = (eventName, payloads) =>
    eventChannel(emitter => {
      feSocketDevLog({ tag, eventName, payloads })

      // TODO: phase 3 後拔除 lookupID
      //! 這邊是 lookupID 只會出現在 phase 2,
      //! 因為 set device ip 那隻 api 會需要
      //! 但有會員之後將不會有 lookupID 這個欄位
      // 屆時把 eventChannel 這邊移到 apiQueueSender
      socket.emit(eventName, { lookupID, ...payloads }, acknowledgement => {
        // 有的 API FE 只是單純接收，不會有 acknowledgement 可以接，這時會跑 undefined
        // emitter 回 undefined 會出錯，所以要用 END 處理
        emitter(acknowledgement === undefined ? END : acknowledgement)
      })
      return () => {}
    })

  yield fork(apiThrottle, props)
  yield fork(apiNormal, props)

  yield fork(apiQueueSender, {
    emitPkg,
    ...props,
  })
}

const feSocketDevLog = ({ tag, eventName, payloads }) => {
  if (isDeveloping) {
    devLog('--------------')
    console.groupCollapsed(
      // console.group(
      '%c༼ つ◕_◕ ༽つ ' + tag + ': ' + eventName + ' [FE][Socket]',
      'color: rgba(255,150,150,0.7); font-weight: 900'
    )

    console.log('[FE] Socket Emit: ')
    console.table(payloads)
    console.count('[FE] Emit count')
    console.groupEnd()

    console.time(`[BE] ${tag} Timer`)
  }
}

function* timeoutProcess({ eventName, timeout }) {
  try {
    const modalName = yield select(state => state.modal.high.name)
    const isOpenTimeoutModal = modalName === 'ERROR_TIMEOUT'

    while (!isOpenTimeoutModal) {
      yield delay(timeout || 3000)
      yield put(
        modalActions.modal_show({
          priority: 'high',
          name: 'ERROR_TIMEOUT',
          props: { eventName },
        })
      )
    }
  } catch (error) {
    devWarLog('[socketEmitCreator -> timeoutProcess] error:', error)
  } finally {
    const modalName = yield select(state => state.modal.high.name)

    const isOpenTimeoutModal = modalName === 'ERROR_TIMEOUT'

    if (isOpenTimeoutModal)
      yield put(modalActions.modal_hide({ priority: 'high' }))
  }
}
