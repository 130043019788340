import React from 'react'
import { useDispatch } from 'react-redux'

import Modal from 'src/components/Popup/Modal'
import CommonButton from 'src/components/Button/CommonButton'

import { BiCheck } from 'react-icons/bi'
import { modalActions } from 'src/redux/slices/modal'

const DeviceErrorMailSended = props => {
  const dispatch = useDispatch()
  return (
    <Modal>
      <Modal.Icon>
        <BiCheck className='text-white' />
      </Modal.Icon>

      <Modal.Title>We got your report</Modal.Title>

      <Modal.Description>
        Thanks for using the feedback system to help us make our product even
        better.
      </Modal.Description>

      <form
        onSubmit={() =>
          dispatch(modalActions.modal_hide({ priority: 'emergency' }))
        }>
        <Modal.ColumnButtonBox>
          <CommonButton size='lg' type='general' buttonType='submit'>
            Close
          </CommonButton>
        </Modal.ColumnButtonBox>
      </form>
    </Modal>
  )
}

export default DeviceErrorMailSended
