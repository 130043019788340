import React from 'react'
import { useSelector, useDispatch } from 'react-redux'

import CommonInput from 'src/components/Input/CommonInput'
import CommonButton from 'src/components/Button/CommonButton'

import useSetHeader from 'src/hooks/useSetHeader'

import { BiEdit, BiUser } from 'react-icons/bi'
import { MdOutlineCameraAlt } from 'react-icons/md'

import { modalActions } from 'src/redux/slices/modal'

const Settings = props => {
  const dispatch = useDispatch()
  const picture = useSelector(state => state.member.picture)
  const preferredUsername = useSelector(state => state.member.preferredUsername)
  const avatarDefaultColor = useSelector(
    state => state.member.avatarDefaultColor
  )
  const email = useSelector(state => state.member.email)

  useSetHeader({ key: 'settings', props: '' })

  const handleShowModal = modalName =>
    dispatch(
      modalActions.modal_show({
        priority: 'normal',
        name: modalName,
        props: {},
      })
    )

  const handleAvatarClick = () => handleShowModal('MEMBER_CHANGE_PROFILE_PHOTO')

  const handleChangeNameClick = () => handleShowModal('MEMBER_CHANGE_NAME')

  const handleChangePasswordClick = () =>
    handleShowModal('MEMBER_CHANGE_PASSWORD')

  return (
    <div className='px-4'>
      <h2 className='pageTitle'>Settings</h2>
      <div className={bg}>
        <main className={mainClass}>
          <div className={avatarCircle} onClick={handleAvatarClick}>
            <MdOutlineCameraAlt className={avatarCameraIcon} />

            {!picture && (
              <>
                <div
                  className={noPictureBg}
                  style={{ background: avatarDefaultColor }}
                />
                <BiUser className='w-10 h-10 text-black/50 group-hover:text-transparent' />
              </>
            )}

            {picture && (
              <img
                src={picture}
                alt='tmytek member avatar'
                className='group-hover:brightness-75 group-hover:opacity-50 duration-500'
              />
            )}
          </div>

          <div className={colBox}>
            <h4 className={subTitle}>Name</h4>
            <CommonInput disabled value={preferredUsername} />
            <CommonButton
              type='outlined'
              icon={<BiEdit />}
              onClick={handleChangeNameClick}>
              Change Name
            </CommonButton>
          </div>

          <div className={colBox}>
            <h4 className={subTitle}>Email</h4>
            <CommonInput disabled value={email} />
          </div>

          <div className={colBox}>
            <h4 className={subTitle}>Password</h4>
            <CommonButton
              type='outlined'
              icon={<BiEdit />}
              onClick={handleChangePasswordClick}>
              Change Password
            </CommonButton>
          </div>
        </main>
      </div>
    </div>
  )
}

export default Settings

const bg = `max-w-[632px] w-full 
            bg-light-4/10 
            py-10 px-4 mx-auto 
            flex justify-center 
            rounded-md
            `
const mainClass = `w-full max-w-[336px] 
                  flex flex-col 
                  `

const colBox = `w-full flex flex-col gap-y-2 py-2 my-2`
const subTitle = `text-base text-light-1 font-normal`

const avatarCircle = `relative 
                      group 
                      w-[100px] h-[100px] 
                      flex items-center justify-center 
                      rounded-full cursor-pointer 
                      mx-auto overflow-hidden
                      duration-500 
                      border-1 border-solid border-light-4 
                      mb-5
                      `

const noPictureBg = `absolute inset-0 -z-10 group-hover:opacity-50 duration-500`

const avatarCameraIcon = `w-10 h-10 
                          absolute inset-0 m-auto z-10 
                          text-transparent group-hover:text-white 
                          duration-500
                          `
