import { call, put, delay, select } from 'redux-saga/effects'

import { getCurrentData } from 'src/redux/sagas/selector/deviceData'
import { setSingleDeviceData } from 'src/redux/slices/deviceData'

import { devWarLog } from 'src/funcs/tools'
import { modalActions } from 'src/redux/slices/modal'
import { cclCttcUIControlForSingleActions as uiControlAction } from 'src/redux/slices/uiControl/beamformers/cloverCellCttc'

export function* common_refSignalSourceChange(response) {
  try {
    const [sn, { value }] = Object.entries(response?.data)[0]

    let currentData = yield call(getCurrentData, sn)
    currentData.deviceControl.common.refSource = value
    yield put(setSingleDeviceData({ sn, data: currentData }))

    const modalName = yield select(state => state.modal.normal.name)

    // 如果有 modal 顯示，要做後續動作 (顯示 success & 關掉 modal)
    if (modalName) {
      yield put(
        uiControlAction.setCommonIsSuccess({
          sn,
          target: +value === 1 ? 'toExternal' : 'toInternal',
        })
      )

      yield delay(1000)
      yield put(uiControlAction.clearCommonStatus({ sn }))
      yield put(modalActions.modal_all_hide({ priority: 'normal' }))
    }
  } catch (error) {
    devWarLog(error)
  }
}

export function* common_frequencyChange(response) {
  try {
    const [sn, { deviceData }] = Object.entries(response?.data)[0]
    yield put(setSingleDeviceData({ sn, data: deviceData }))
  } catch (error) {
    devWarLog(error)
  }
}

export function* common_externalControlChange(response) {
  try {
    yield console.log()
    // 這個 response 是 204
  } catch (error) {
    devWarLog(error)
  }
}
