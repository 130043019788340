import React from 'react'
import { useDispatch } from 'react-redux'

import Modal from 'src/components/Popup/Modal'
import CommonButton from 'src/components/Button/CommonButton'

import { BiInfoCircle } from 'react-icons/bi'
import { modalActions } from 'src/redux/slices/modal'

const PremiumContactTMYTEK = props => {
  const dispatch = useDispatch()
  const handleOKClick = () =>
    dispatch(modalActions.modal_hide({ priority: 'normal' }))
  return (
    <Modal>
      <Modal.Icon>
        <BiInfoCircle className='text-white' />
      </Modal.Icon>
      <Modal.Title>
        Polarization Presets is a premium feature.
        <br /> Please contact TMYTEK to learn more.
      </Modal.Title>

      <Modal.RowButtonBox>
        <CommonButton
          autoFocus
          size='lg'
          type='general'
          onClick={handleOKClick}>
          OK
        </CommonButton>
      </Modal.RowButtonBox>
    </Modal>
  )
}

PremiumContactTMYTEK.propTypes = {}

export default PremiumContactTMYTEK
