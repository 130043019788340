import React from 'react'

import { useDispatch, useSelector } from 'react-redux'

import {
  head,
  main,
  footer,
} from 'src/containers/Modal/Normal/beamformers/bbox/DeviceController/CustomAntenna/style'
import { h2, h3 } from 'src/containers/Modal/style'

import CommonButton from 'src/components/Button/CommonButton'

import FunctionBar from 'src/containers/Modal/Normal/beamformers/bbox/DeviceController/CustomAntenna/ListMode/FunctionBar'
import THead from 'src/containers/Modal/Normal/beamformers/bbox/DeviceController/CustomAntenna/ListMode/THead'
import TBody from 'src/containers/Modal/Normal/beamformers/bbox/DeviceController/CustomAntenna/ListMode/TBody'

import noCustomAntennaImg from 'src/assets/img/no_custom_antenna.png'

import { modalActions } from 'src/redux/slices/modal'

import useGetCurrentDeviceData from 'src/hooks/useGetCurrentDeviceData'

const ListMode = () => {
  const dispatch = useDispatch()
  const modalProps = useSelector(state => state.modal.normal.props)
  const { sn } = modalProps

  const { current } = useGetCurrentDeviceData(sn)

  const { boardCount, channelCount } = current.data.deviceControl.channel
  const deviceType = current.data.info.deviceType
  const lstCurrentAntennaData = current.data.deviceControl.common.lstAntennaData
  const customOnlyAntennaList = lstCurrentAntennaData.filter(
    e => e.type === 'customized'
  )

  const offsetCount = boardCount * channelCount

  const newAntennaData = {
    filename: '',
    name: 'Unnamed antenna',
    deviceType,
    type: 'customized',
    spacingX: 0,
    spacingY: 0,
    thetaMax: 0,
    rxOffset: new Array(offsetCount).fill(0), // List[float]
    txOffset: new Array(offsetCount).fill(0),
  }
  const handleCreateClick = () =>
    dispatch(
      modalActions.modal_props_set({
        priority: 'normal',
        props: {
          editData: newAntennaData,
          isCreate: true,
        },
      })
    )

  const handleCloseClick = () =>
    dispatch(modalActions.modal_hide({ priority: 'normal' }))

  return (
    <>
      <header className={`${head} ${h2} text-light-1 pt-10 pb-6 text-center`}>
        Custom Antenna
      </header>

      {customOnlyAntennaList.length === 0 && <NoData />}

      {customOnlyAntennaList.length > 0 && (
        <main className={main + ' flex flex-col items-start px-[84px]'}>
          <FunctionBar />
          <THead />
          <TBody />
        </main>
      )}

      <footer className={footer + ' flex justify-end gap-x-8'}>
        <CommonButton
          type='general'
          size='lg'
          className='px-4'
          disabled={customOnlyAntennaList.length >= 50}
          onClick={handleCreateClick}>
          Create Custom Antenna
        </CommonButton>

        <CommonButton type='outlined' size='lg' onClick={handleCloseClick}>
          Close
        </CommonButton>
      </footer>
    </>
  )
}

export default ListMode

const NoData = () => (
  <main className={main + ' flex flex-col items-center justify-center'}>
    <div className='w-[240px] h-[160px] mb-4'>
      <img src={noCustomAntennaImg} alt='TMXLab-kit bbox - no custom antenna' />
    </div>
    <h2 className={`${h2} text-light-4`}>No custom antenna</h2>
    <h3 className={`${h3} text-light-4 text-center`}>
      Click the button below to create new one.
      <br /> Up to 50 custom antenna.
    </h3>
  </main>
)
