import { getHost } from 'src/redux/sagas/services/url'
import { call } from 'redux-saga/effects'

import { getIsDemoMode } from 'src/redux/sagas/funcs/general'
import { _printBELog, _printFELog } from './__restful_API_sender'

export default function* __export_API_sender({ api, data }) {
  try {
    const isDemoMode = yield call(getIsDemoMode)
    const { url } = api(data)

    // demo mode 不 support export
    if (!isDemoMode) {
      yield call(_printFELog, { url, data })
      const response = yield call(_callExportAPI, { ...api(), data })
      yield call(_printBELog, { url })

      return yield call(_downloadFile, { response })
    } else return false
  } catch (errorMsg) {
    console.log(`Error: `, errorMsg)
  }
}

function* _callExportAPI({ server, method, url, data }) {
  return yield fetch(getHost('ajax', server) + url, {
    method,
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then(response => response)
    .catch(error => error.response)
}

function* _downloadFile({ response }) {
  //! 這邊 response 成功不會有 response.data.code 因為是回傳 file
  //! 所以用 response.status 來判斷
  const isSuccess = response?.status >= 200 && response?.status < 300

  if (isSuccess) {
    const contentDisposition = response?.headers?.get('Content-Disposition')
    const match = contentDisposition?.match(/filename="(.+)"/)
    const fileName = match?.[1] || 'unknown.csv'

    const blob = yield response.blob()

    const blobUrl = yield URL.createObjectURL(blob)

    const a = yield document.createElement('a')
    a.href = yield blobUrl
    a.download = yield fileName
    yield a.click()

    yield URL.revokeObjectURL(blobUrl)

    return { success: true }
  } else return { success: false }
}
