import React from 'react'
import { useDispatch } from 'react-redux'

import Checkbox from 'src/components/Button/Checkbox'

import { h3 } from 'src/containers/Modal/style'
import useGetCurrentDeviceData from 'src/hooks/useGetCurrentDeviceData'

import { modalActions } from 'src/redux/slices/modal'

const THead = ({ caliDataLength, selectIdList }) => {
  const dispatch = useDispatch()

  const isNoSelect = selectIdList.length === 0
  const isSelectAll = selectIdList.length === caliDataLength

  const { current } = useGetCurrentDeviceData()
  const caliData = current.data.deviceControl.actionPanel.caliData
  const caliDataIsList = Object.keys(caliData)

  const handleAllSelectClick = () =>
    dispatch(
      modalActions.modal_props_set({
        priority: 'normal',
        props: {
          selectIdList: isSelectAll ? [] : caliDataIsList,
        },
      })
    )

  return (
    <div className={tHead} onClick={handleAllSelectClick}>
      <Checkbox
        disabled={false}
        status={
          isSelectAll ? 'checked' : isNoSelect ? 'uncheck' : 'indeterminate'
        }
      />
      <div className={h3 + ' text-light-1 ml-[6px]'}>
        Custom Antenna ({caliDataLength} / 5)
      </div>
    </div>
  )
}

THead.propTypes = {}

export default THead

const tHead = `
              h-7 
              mt-6 mb-3 p-1 ml-3
              flex items-center gap-x-3 
              cursor-pointer select-none 
              `
