import { createSlice, current } from '@reduxjs/toolkit'
import * as R from 'ramda'

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* -----------------      Single        -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
const itemData = {
  common: {
    toExternal: { isRequesting: false, isSuccess: false },
    toInternal: { isRequesting: false, isSuccess: false },
  },
}

export const cclCttcUIControlForSingleSlice = createSlice({
  name: 'cclCttcUIControlForSingle',
  initialState: {},
  reducers: {
    addUiControl(state, action) {
      const { sn } = action.payload
      return { ...current(state), [sn]: itemData }
    },

    // refSource
    clearCommonStatus(state, action) {
      const { sn } = action.payload
      state[sn].common.toExternal = R.map(
        R.always(false),
        state[sn].common.toExternal
      )
      state[sn].common.toInternal = R.map(
        R.always(false),
        state[sn].common.toInternal
      )
      return state
    },
    setCommonIsRequesting(state, action) {
      const { sn, target } = action.payload
      state[sn].common[target].isRequesting = true
      state[sn].common[target].isSuccess = false
      return state
    },
    setCommonIsSuccess(state, action) {
      const { sn, target } = action.payload
      state[sn].common[target].isRequesting = false
      state[sn].common[target].isSuccess = true
      return state
    },
  },
})

export const { addUiControl } = cclCttcUIControlForSingleSlice.actions

export const cclCttcUIControlForSingleActions =
  cclCttcUIControlForSingleSlice.actions

export const cclCttcUIControlForSingle = cclCttcUIControlForSingleSlice.reducer
