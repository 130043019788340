import React from 'react'
import { useDispatch } from 'react-redux'

import RfMode from 'src/containers/operating/device/beamformers/bbox/DeviceController/Common/RfMode'
import Frequency from 'src/containers/operating/device/beamformers/bbox/DeviceController/Common/Frequency'
import Antenna from 'src/containers/operating/device/beamformers/bbox/DeviceController/Common/Antenna'
// import AntennaIcon from 'src/containers/operating/device/beamformers/bbox/Common/AntennaIcon'

import CommonButton from 'src/components/Button/CommonButton'

import { modalActions } from 'src/redux/slices/modal'

import useGetCurrentDeviceData from 'src/hooks/useGetCurrentDeviceData'
import useGetScreenSize from 'src/hooks/useGetScreenSize'
import useBindErrorBoundary from 'src/hooks/useBindErrorBoundary'

const Common = () => {
  const dispatch = useDispatch()
  const { isSM, isMD } = useGetScreenSize()

  const { current } = useGetCurrentDeviceData()
  const sn = current?.sn

  const handleSaveBeamConfigClick = useBindErrorBoundary(() => {
    dispatch(
      modalActions.modal_show({
        priority: 'normal',
        name: 'BBOX_SAVE_TO_BEAM_CONFIG',
        props: { sn },
      })
    )
  })

  return (
    <div className={container}>
      <div className='px-2 py-2 lg:py-0 lg:pt-2'>
        <CommonButton
          type='text'
          size={isSM || isMD ? 'lg' : 'sm'}
          onClick={handleSaveBeamConfigClick}>
          Save to Beam Config...
        </CommonButton>
      </div>

      <div className={divider + ' hidden md:block'} />

      <div className={bodyContainer}>
        <div className={bodyBox}>
          <h6 className={subtitle}>Current Mode</h6>
          <RfMode />
        </div>

        <div className={bodyBox}>
          <h6 className={subtitle}>Central Frequency</h6>
          <Frequency />
        </div>
      </div>

      <div className={divider} />

      <div className={footContainer}>
        <Antenna />
      </div>
    </div>
  )
}

export default Common

const container = `[Common-container] 
                    funcBG 
                    w-full
                    px-3 xl:px-2 py-2 
                    rounded-md
                    `

const divider = `w-full h-[1px] bg-dark-5 my-2`

export const subtitle = `
            [Common-subtitle] 
            text-light-1 text-base lg:text-sm font-normal leading-5
            `

const bodyContainer = `
            [Common-bodyContainer] 
            w-full h-[75px] lg:h-[60px] 
            flex items-center
            `

const bodyBox = `
            [Common-bodyBox] 
            w-1/2 px-2 
            flex flex-col gap-y-2 lg:gap-y-1
            `

const footContainer = `
            [Common-footContainer] 
            w-full h-[75px] lg:h-[60px] px-2 
            flex items-center gap-x-2
            `
