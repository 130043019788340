import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as R from 'ramda'

import InputGroupPkg from 'src/containers/Modal/Normal/beamformers/bbox/DeviceController/CustomAntenna/EditMode/InputGroupPkg'

import Tooltip from 'src/components/Popup/Tooltip'

import { BiInfoCircle } from 'react-icons/bi'

import { h3 } from 'src/containers/Modal/style'

import XIcon from 'src/assets/svg/unit/X'
import YIcon from 'src/assets/svg/unit/Y'
import ThetaIcon from 'src/assets/svg/unit/Theta'

import { spacingIsInvalid, thetaMaxIsInvalid } from 'src/funcs/device/bbox'

import antennaSpacingImage from 'src/assets/img/antenna-spacing.png'
import { modalActions } from 'src/redux/slices/modal'

const SpacingAndAngle = () => {
  const dispatch = useDispatch()
  const modalProps = useSelector(state => state.modal.normal.props)
  const { editData } = modalProps

  const spacingInvalid = spacing => spacingIsInvalid(spacing)

  const thetaMaxInvalid = thetaMax => thetaMaxIsInvalid(thetaMax)

  const handleInputChange = (value, label) =>
    dispatch(
      modalActions.modal_props_set({
        priority: 'normal',
        props: {
          isEditChanged: true,
          editData: R.assoc(label, value)(editData),
        },
      })
    )
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------       Props       ------------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  const spacingXInputGroupPkgProps = {
    hint: '',
    isInvalid: spacingInvalid(editData['spacingX']),
    inputGroupProps: {
      className: 'mr-[30px]',
      value: editData['spacingX'],
      icon: <XIcon className='opacity-50' />,
      unit: <span className='opacity-30'>mm</span>,
      danger: spacingInvalid(editData['spacingX']),
      step: 1,
      keydownMin: 0,
      decimalScale: 2,
      onChange: e => handleInputChange(e.formattedValue, 'spacingX'),
    },
  }
  const spacingYInputGroupPkgProps = {
    hint: '',
    isInvalid: spacingInvalid(editData['spacingY']),
    inputGroupProps: {
      value: editData['spacingY'],
      icon: <YIcon className='opacity-50' />,
      unit: <span className='opacity-30'>mm</span>,
      danger: spacingInvalid(editData['spacingY']),
      step: 1,
      keydownMin: 0,
      decimalScale: 2,
      onChange: e => handleInputChange(e.formattedValue, 'spacingY'),
    },
  }
  const thetaMaxInputGroupPkgProps = {
    hint: 'Range 0 ~ 90',
    isInvalid: thetaMaxInvalid(editData['thetaMax']),
    inputGroupProps: {
      value: editData['thetaMax'],
      icon: <ThetaIcon className='opacity-50' />,
      unit: <span className='opacity-30'>˚</span>,
      danger: thetaMaxInvalid(editData['thetaMax']),
      step: 1,
      keydownMin: 0,
      keydownMax: 90,
      onChange: e => handleInputChange(e.formattedValue, 'thetaMax'),
    },
  }

  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------        JSX         -----------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  return (
    <div className='p-2 flex gap-x-[30px]'>
      <div className='w-full flex flex-col '>
        <h3 className={h3 + ' mb-2 text-light-1 flex items-center gap-x-2'}>
          Antenna element spacing{' '}
          <Tooltip
            className='w-[216px]'
            content={
              <div className='py-2'>
                <h3 className='text-light-4'>
                  Pitch-to-pitch spacing between two adjacent antenna elements
                  in X and Y coordinates
                </h3>
                <div className='w-[184px] h-[88px] mt-2'>
                  <img src={antennaSpacingImage} alt='TMYTEK antenna spacing' />
                </div>
              </div>
            }
            placement='right'
            enableDismiss={true}>
            <BiInfoCircle className='text-lg cursor-pointer' />
          </Tooltip>
        </h3>

        <div className='w-full flex '>
          <InputGroupPkg {...spacingXInputGroupPkgProps} />
          <InputGroupPkg {...spacingYInputGroupPkgProps} />
        </div>
      </div>

      <div className='w-full flex flex-col text-light-1'>
        <h3 className={h3 + ' mb-2'}>Theta max angle</h3>
        <InputGroupPkg {...thetaMaxInputGroupPkgProps} />
      </div>
    </div>
  )
}

export default SpacingAndAngle
