export const calibrationTableImportApi = () => ({
  server: 'dbService',
  method: 'POST',
  url: '/api/db/calibration/table/import',
  timeout: 100000,
  config: {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/octet-stream',
    },
  },
})
