import React from 'react'
import { useDispatch } from 'react-redux'

import Modal from 'src/components/Popup/Modal'
import CommonInput from 'src/components/Input/CommonInput'
import PasswordInput from 'src/components/Input/PasswordInput'
import CommonButton from 'src/components/Button/CommonButton'

import { modalActions } from 'src/redux/slices/modal'

import {
  member_modalClose_watcher,
  member_forgotPasswordResetModal_submit_watcher,
  member_forgotPasswordResetModal_resendCode_watcher,
} from 'src/redux/actions/facility/member'

const ForgotPasswordReset = props => {
  const dispatch = useDispatch()

  const {
    email,
    verificationCode,
    errorMessage,
    resendDelay,
    newPassword,
    confirmNewPassword,
  } = props

  const handleInputChange = ({ value, label }) =>
    dispatch(
      modalActions.modal_props_set({
        priority: 'high',
        props: { [label]: value },
      })
    )

  const handleSubmit = event => {
    event.preventDefault()
    dispatch(member_forgotPasswordResetModal_submit_watcher())
  }
  const handleResendClick = () =>
    dispatch(member_forgotPasswordResetModal_resendCode_watcher())

  const handleCloseIconClick = () => dispatch(member_modalClose_watcher())

  return (
    <Modal>
      <Modal.CloseIcon onClick={handleCloseIconClick} />

      <Modal.Title>Reset Password</Modal.Title>

      <Modal.Description>
        Enter the code we emailed to
        <br /> <b>{email}</b>
        <br />
        and enter a new password below to reset.
      </Modal.Description>

      <div className='flex flex-col'>
        <form onSubmit={handleSubmit} className='flex flex-col'>
          {errorMessage && <div className='text-red mt-8'>{errorMessage}</div>}

          <Modal.FormLabel htmlFor='verificationCode' className='mt-8'>
            Verification code
          </Modal.FormLabel>
          <CommonInput
            autoFocus
            id='verificationCode'
            size='lg'
            value={verificationCode}
            placeholder='code'
            onChange={e =>
              handleInputChange({
                value: e.formattedValue,
                label: 'verificationCode',
              })
            }
          />
          <Modal.FormLabel htmlFor='newPassword' className='mt-4'>
            New Password
          </Modal.FormLabel>
          <PasswordInput
            id='newPassword'
            size='lg'
            value={newPassword}
            placeholder='Enter your Password'
            onChange={e =>
              handleInputChange({
                value: e.formattedValue,
                label: 'newPassword',
              })
            }
          />
          <small className='text-white/50 mt-2'>At least 8 characters</small>

          <Modal.FormLabel htmlFor='confirmNewPassword' className='mt-4'>
            Confirm Password
          </Modal.FormLabel>
          <PasswordInput
            id='confirmNewPassword'
            size='lg'
            value={confirmNewPassword}
            placeholder='Please confirm your password'
            onChange={e =>
              handleInputChange({
                value: e.formattedValue,
                label: 'confirmNewPassword',
              })
            }
          />

          <Modal.ColumnButtonBox>
            <CommonButton buttonType='submit' size='lg'>
              Reset Password
            </CommonButton>

            <CommonButton
              size='lg'
              type='outlined'
              disabled={resendDelay !== 0}
              onClick={handleResendClick}>
              Resend Code {resendDelay !== 0 && ` ${resendDelay}s`}
            </CommonButton>
          </Modal.ColumnButtonBox>
        </form>
      </div>
    </Modal>
  )
}

export default ForgotPasswordReset
