import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import ErrorBoundaryOfDevice from 'src/containers/ErrorBoundary/Device'

import ActionPanel from 'src/containers/operating/device/powerDetector/ActionPanel'
import Dashboard from 'src/containers/operating/device/powerDetector/Dashboard'

import DeviceInitializing from 'src/containers/operating/DeviceInitializing'
import DeviceSomethingWereWrong from 'src/containers/operating/DeviceSomethingWereWrong'

import useGetCurrentDeviceData from 'src/hooks/useGetCurrentDeviceData'
import useGetScreenSize from 'src/hooks/useGetScreenSize'
import useGetHelmet from 'src/hooks/useGetHelmet'

import { pd_general_initDevice_watcher } from 'src/redux/actions/powerDetector'

const PowerDetector = props => {
  const dispatch = useDispatch()
  const { current } = useGetCurrentDeviceData()

  const sn = current.sn
  const info = current.data?.info

  const { isSM, isMD, isLG, isXL, is2XL } = useGetScreenSize()

  useEffect(() => {
    if (info?.initialization === 'unfinished')
      dispatch(pd_general_initDevice_watcher({ sn }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sn])

  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------        JSX         -----------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  // ==================================
  // 第一步 攔截 init 中或 init 失敗
  // ==================================
  if (info?.initialization === 'unfinished') return <DeviceInitializing />

  if (info?.initialization === 'failed')
    return (
      <DeviceSomethingWereWrong
        handleRetry={() => dispatch(pd_general_initDevice_watcher({ sn }))}
      />
    )

  if (isSM || isMD || isLG)
    return (
      <div className='flex flex-col gap-y-6'>
        <div className='w-full'>
          <ActionPanel />
        </div>
        <div className='w-full'>
          <Dashboard />
        </div>
      </div>
    )

  if (isXL || is2XL)
    return (
      <div className='flex gap-x-4'>
        <div className='w-[240px] shrink-0'>
          <ActionPanel />
        </div>
        <div className='w-full'>
          <Dashboard />
        </div>
      </div>
    )
}

const PowerDetectorWithErrorBoundary = () => {
  const { current } = useGetCurrentDeviceData()
  const deviceType = current.deviceType
  const helmet = useGetHelmet({ deviceType })

  return (
    // TODO: retry 補 call re-init api
    <ErrorBoundaryOfDevice
      deviceName='pd'
      handleRetry={() => console.log('Error Boundary Retry Click')}>
      {helmet}
      <PowerDetector />
    </ErrorBoundaryOfDevice>
  )
}

export default PowerDetectorWithErrorBoundary
