import { select, call, put, take } from 'redux-saga/effects'
import moment from 'moment'

import { scanDevicesAPI } from 'src/redux/sagas/services/restfulAPI/facilityApi'

import __restful_API_sender from 'src/redux/sagas/services/restfulTools/__restful_API_sender'

import {
  clearDevicesData,
  setScanDevicesData,
} from 'src/redux/slices/deviceData'

import { lottie_scanAnimateModal_complete_watcher } from 'src/redux/actions/facility/home'

import { tempIpUpdateFromDeviceData } from 'src/redux/slices/uiControl/facility/home'

import { modalActions } from 'src/redux/slices/modal'
import { generalError } from 'src/redux/sagas/handlers/event/facility/generalError'

import { devWarLog } from 'src/funcs/tools'

import { errorActions } from 'src/redux/slices/uiControl/facility/error'

// 檢查 scan 回傳的 data 中有沒有 initialized = failed
// 如果有就新增 error message
// 到時候 report issue 寄 mail 才有內容
function* updateErrorMessageForInitFailedDevice(newDeviceData) {
  const lstInitFailedData = Object.entries(newDeviceData).filter(
    ([sn, data]) => data.info.initialization === 'failed'
  )
  const time = moment().format('YYYY-MM-DD  HH:mm:ss')

  for (let i = 0; i <= lstInitFailedData.length - 1; i++) {
    yield put(
      errorActions.setDeviceError({
        sn: lstInitFailedData[i][0],
        errorData: {
          time,
          api: '/api/facility/device/scanDevices',
          code: '',
          codeName: 'SCAN_INIT_FAILED',
          data: lstInitFailedData[i][1],
          errorInfo: {
            sn: lstInitFailedData[i][0],
            deviceType: lstInitFailedData[i][1]?.info?.deviceType,
            versionInfo: {
              caliTable: lstInitFailedData[i][1]?.info?.versionInfo?.caliTable,
              firmware: lstInitFailedData[i][1]?.info?.versionInfo?.firmware,
              hardware: lstInitFailedData[i][1]?.info?.versionInfo?.hardware,
            },
          },
          message: `'Initialization' field in device info is failed`,
          module: 'Front-end',
          subModule: '',
          status: 'error',
        },
      })
    )
  }
}

export function* scanDevices() {
  try {
    yield put(clearDevicesData())

    const scanResponse = yield call(__restful_API_sender, {
      api: scanDevicesAPI,
    })

    if (+scanResponse.data.code === 0) {
      const newDeviceData = scanResponse.data.data
      yield put(setScanDevicesData(newDeviceData))
      yield put(tempIpUpdateFromDeviceData(newDeviceData))

      yield call(updateErrorMessageForInitFailedDevice, newDeviceData)
    }

    if (+scanResponse.data.code !== 0) {
      const lookupID = yield select(state => state.user.lookupID)
      const time = moment().format('YYYY-MM-DD  HH:mm:ss')
      yield call(generalError, {
        time,
        userID: lookupID,
        api: '',
        code: 1,
        codeName: 'Scan device API response ERROR',
        message: `Scan device API response code !== 0 ,message: ${scanResponse.data.message}`,
      })
    }
  } catch (error) {
    devWarLog('[handler] scanDevices error:', error)
  }
}

export function* manualScanDevices() {
  try {
    yield put(
      modalActions.modal_show({
        priority: 'normal',
        name: 'ANIMATION_TMYTEK_LOGO_LONG_TIME',
        props: { status: 'loading' },
      })
    )
    yield call(scanDevices)
    yield put(
      modalActions.modal_show({
        priority: 'normal',
        name: 'ANIMATION_TMYTEK_LOGO_LONG_TIME',
        props: { status: 'complete' },
      })
    )
    yield take(lottie_scanAnimateModal_complete_watcher.type)
    yield put(modalActions.modal_hide({ priority: 'normal' }))
  } catch (error) {
    devWarLog('[handler] manualScanDevices error:', error)
  }
}
