import React from 'react'
import { RotatingLines } from 'react-loader-spinner'

import Modal from 'src/components/Popup/Modal'

import { HiOutlineUpload } from 'react-icons/hi'

const FileExporting = () => {
  return (
    <Modal>
      <Modal.Icon>
        <HiOutlineUpload className='text-white' />
      </Modal.Icon>

      <Modal.Title>File exporting......</Modal.Title>

      <Modal.Description>
        The waiting time depends on your internet speed.
      </Modal.Description>

      <div className='flex justify-center mt-6'>
        <RotatingLines
          width='56'
          strokeColor='white'
          strokeWidth='3'
          animationDuration='0.7'
          wrapperStyle={{}}
          visible={true}
        />
      </div>
    </Modal>
  )
}

export default FileExporting
