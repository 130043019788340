import React, { useState, Fragment } from 'react'
import { useDispatch } from 'react-redux'

import useGetCurrentMode from 'src/hooks/useGetCurrentMode'
import useGetCurrentDeviceData from 'src/hooks/useGetCurrentDeviceData'

import { FaCommentDollar } from 'react-icons/fa'
import { MdOpenInNew } from 'react-icons/md'

import { facility_getQuoteButtonClick_watcher } from 'src/redux/actions/facility/getQuote'

import { getDeviceInfo } from 'src/funcs/getDeviceInfo'
import { isOffline } from 'src/funcs/getEnv'

const FixedButton = () => {
  const dispatch = useDispatch()
  const { isDemoMode } = useGetCurrentMode()
  const { current } = useGetCurrentDeviceData()
  const { deviceType } = current

  const [isHover, setIsHover] = useState(false)

  const devName = getDeviceInfo[deviceType]?.devName?.first

  const handleGetQuoteClick = () =>
    dispatch(facility_getQuoteButtonClick_watcher())

  const buttonList = [
    {
      name: 'Get Quote',
      icon: <FaCommentDollar className={iconClass} />,
      handler: handleGetQuoteClick, // 事件 或 連結 二選一
      href: '',
      show: !isOffline && isDemoMode, // 這欄位 true 才會顯示
    },
    {
      name: 'Learn More',
      icon: <MdOpenInNew className={iconClass} />,
      handler: () => {},
      href: learnMoreLinks[devName] || homePageLink,
      show: !isOffline && isDemoMode && deviceType, // device 頁面才顯示
    },
    //! 要加按鈕就加在這...
  ].filter(e => e.show)

  //* ------ top 回補計算 () ------
  const buttonHeight = isHover ? hoverBtnH : btnH
  // -------------------------------------
  // 這邊目標就是拿到內容物 height 的一半
  // 所以先拿一個 button 的高(56px)
  // 去加上含分隔線的 button 的高 (第二顆 button 開始上面要加分隔線)
  // 然後除以二
  const topOffset =
    buttonList.length > 1
      ? (buttonHeight + (buttonHeight + dgH) * (buttonList.length - 1)) / 2
      : buttonHeight / 2

  const containerTop = `calc(50vh - ${topOffset}px)`

  if (isDemoMode)
    return (
      <div
        className={container}
        style={{ top: containerTop }}
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}>
        {buttonList.map((e, i) => (
          <Fragment key={'FixedButton' + e.name + i}>
            {i !== 0 && <div className={divide} />}

            <ExternalLink href={e.href}>
              <button
                className={buttonClass}
                onClick={e.handler}
                style={{ height: `${buttonHeight}px` }}>
                {e.icon}
                <p className={labelClass}>{e.name}</p>
              </button>
            </ExternalLink>
          </Fragment>
        ))}
      </div>
    )

  return <></>
}

FixedButton.propTypes = {}

export default FixedButton

// btnH = button height
// hoverBtnH = hover button height
const btnH = 56
const hoverBtnH = 93

// dgH = divide height(1px) + gap-y-1(4px + 4px) = 9
const dgH = 9

const container = `group 
                  fixed z-10
                  right-0 
                  flex flex-col gap-y-1 items-center
                  bg-teal 
                  rounded-l-sm 
                  cursor-pointer 
                  duration-500 
                  `

const buttonClass = `
                    w-[56px] 
                    group-hover:w-[106px] 
                    duration-500 
                    flex flex-col items-center justify-center 
                    overflow-hidden 
                    `

const iconClass = `text-3xl group-hover:text-4xl 
                  translate-y-0 group-hover:-translate-y-3 
                  duration-500
                  `

const labelClass = `absolute 
                  invisible group-hover:visible
                  opacity-0 group-hover:opacity-100 
                  translate-y-6 
                  text-xs font-bold whitespace-nowrap break-normal 
                  scale-0 group-hover:scale-[1.4] 
                  duration-500 ease-out
                  `

const divide = `w-3/4 h-[1px] bg-black/30`

const ExternalLink = props =>
  props.href ? (
    <a href={props.href} target='_blank' rel='noopener noreferrer'>
      {props.children}
    </a>
  ) : (
    props.children
  )

const learnMoreLinks = {
  udbox: process.env.REACT_APP_CORP_WEB_LINK_FOR_UDBOX,
  bbox: process.env.REACT_APP_CORP_WEB_LINK_FOR_BBOX,
  bboard: process.env.REACT_APP_CORP_WEB_LINK_FOR_BBOARD,
  udModule: process.env.REACT_APP_CORP_WEB_LINK_FOR_UDMODULE,
  pd: process.env.REACT_APP_CORP_WEB_LINK_FOR_POWER_DETECTOR,
  ris: process.env.REACT_APP_CORP_WEB_LINK_FOR_RIS,
}

const homePageLink = process.env.REACT_APP_CORP_WEB_LINK_FOR_HOME_PAGE
