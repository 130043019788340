import React, { useRef } from 'react'
import { useDispatch } from 'react-redux'

import CommonSelect from 'src/components/Select/CommonSelect'
import SelectWithAction from 'src/components/Select/SelectWithAction'

import useGetCurrentMode from 'src/hooks/useGetCurrentMode'
import useGetScreenSize from 'src/hooks/useGetScreenSize'
import useGetCurrentDeviceData from 'src/hooks/useGetCurrentDeviceData'

import {
  bbox_common_freqSelect_watcher,
  bbox_common_freqAfterImport_watcher,
} from 'src/redux/actions/beamformers/bboxAction'

import { isOffline } from 'src/funcs/getEnv'

import { calibrationTableImportApi } from 'src/redux/sagas/services/restfulAPI/dbServiceApi'

import useUploadFiles from 'src/hooks/useUploadFiles'

import { modalActions } from 'src/redux/slices/modal'

const Frequency = ({ disabled }) => {
  const dispatch = useDispatch()

  const { isSM, isMD } = useGetScreenSize()
  const isMDSize = isSM || isMD

  const { isDemoMode } = useGetCurrentMode()
  const { current } = useGetCurrentDeviceData()

  const sn = current?.sn
  const { currentFreq, lstFreqOptions } = current.data.deviceControl.common

  const inputFileRef = useRef()

  const handleBeforeTableImport = () =>
    dispatch(
      modalActions.modal_show({
        priority: 'normal',
        name: 'FILE_IMPORT_IMPORTING',
        props: {},
      })
    )
  const handleAfterTableImport = responseData =>
    dispatch(bbox_common_freqAfterImport_watcher({ sn, responseData }))

  const { handleFileInputChange } = useUploadFiles({
    inputFileRef,
    api: calibrationTableImportApi,
    payload: '{}',
    beforeCallback: handleBeforeTableImport,
    afterCallback: handleAfterTableImport,
  })

  const handleFreqSelect = (event, value) =>
    dispatch(bbox_common_freqSelect_watcher({ sn, value }))

  const handleFreqImportClick = () => inputFileRef.current.click()

  if (isOffline && !isDemoMode)
    return (
      <>
        <input
          className='hidden'
          ref={inputFileRef}
          type='file'
          accept='.csv'
          disabled={disabled}
          multiple
          onChange={handleFileInputChange}
        />
        <SelectWithAction
          value={currentFreq}
          selectDisabled={disabled}
          actionDisabled={disabled}
          size={isMDSize ? 'md' : 'sm'}
          currentChildren={currentFreq + ' GHz'}
          selectClassName={`w-full z-10 `}
          onChange={handleFreqSelect}
          onClickForAction={handleFreqImportClick}>
          {lstFreqOptions.map((item, index) => (
            <SelectWithAction.Option
              key={'bbox ui ' + item + index}
              name={item}
              disabled={false}>
              {item} GHz
            </SelectWithAction.Option>
          ))}
        </SelectWithAction>
      </>
    )

  return (
    <CommonSelect
      value={currentFreq}
      disabled={disabled}
      size={isMDSize ? 'md' : 'sm'}
      currentChildren={currentFreq + ' GHz'}
      selectClassName={`w-full z-10 `}
      onChange={handleFreqSelect}>
      {lstFreqOptions.map((item, index) => (
        <CommonSelect.Option
          key={'bbox ui ' + item + index}
          name={item}
          disabled={disabled}>
          {item} GHz
        </CommonSelect.Option>
      ))}
    </CommonSelect>
  )
}

export default Frequency
