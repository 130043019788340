import React from 'react'
import PropTypes from 'prop-types'

import Theta from 'src/components/composite/InputGroupAppendDialog/Theta'
import Phi from 'src/components/composite/InputGroupAppendDialog/Phi'
import Gain from 'src/components/composite/InputGroupAppendDialog/Gain'

const InputControl = ({
  deviceType,
  dragHVMode,
  thetaH,
  thetaV,
  phiH,
  phiV,
  beamCurrentGainH,
  beamCurrentGainV,
  thetaMaxH,
  thetaMaxV,
  beamGainMinH,
  beamGainMinV,
  beamGainMaxH,
  beamGainMaxV,
  isOffH,
  isOffV,
  isGainDisabled = false,
  handleInputChange,
  handleInputFocus,
}) => {
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------       State        -----------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------

  const theta = { horizon: thetaH, vertical: thetaV }
  const phi = { horizon: phiH, vertical: phiV }
  const beamCurrentGain = {
    horizon: beamCurrentGainH,
    vertical: beamCurrentGainV,
  }
  const thetaMax = { horizon: thetaMaxH, vertical: thetaMaxV }
  const beamGainMin = { horizon: beamGainMinH, vertical: beamGainMinV }
  const beamGainMax = { horizon: beamGainMaxH, vertical: beamGainMaxV }
  const isOff = { horizon: isOffH, vertical: isOffV }

  const hClassProps = {
    isActive: dragHVMode === 'horizon',
    isOff: isOff['horizon'],
  }
  const vClassProps = {
    isActive: dragHVMode === 'vertical',
    isOff: isOff['vertical'],
  }

  const handleChange = ({ label, hvMode, value, isValid }) =>
    handleInputChange({ label, hvMode, value, isValid })

  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------        JSX         -----------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  return (
    <div className={container}>
      <div className='flex flex-col gap-y-3'>
        <h6 className={subtitle(hClassProps)}>H-Polarization</h6>

        <Theta
          className={itemBox(hClassProps)}
          value={String(theta['horizon'])}
          thetaMax={thetaMax['horizon']}
          off={isOff['horizon']}
          onChange={({ value, isValid }) =>
            handleChange({ label: 'theta', hvMode: 'horizon', value, isValid })
          }
          onFocus={() => handleInputFocus({ hvMode: 'horizon' })}
        />

        <Phi
          className={itemBox(hClassProps)}
          value={String(phi['horizon'])}
          off={isOff['horizon']}
          onChange={({ value, isValid }) =>
            handleChange({ label: 'phi', hvMode: 'horizon', value, isValid })
          }
          onFocus={() => handleInputFocus({ hvMode: 'horizon' })}
        />

        <Gain
          className={itemBox(hClassProps)}
          value={String(beamCurrentGain['horizon'])}
          gainMin={beamGainMin['horizon']}
          gainMax={beamGainMax['horizon']}
          disabled={isGainDisabled}
          off={isOff['horizon']}
          onChange={({ value, isValid }) =>
            handleChange({ label: 'gain', hvMode: 'horizon', value, isValid })
          }
          onFocus={() => handleInputFocus({ hvMode: 'horizon' })}
        />
      </div>

      <div className='flex flex-col gap-y-3'>
        <h6 className={subtitle(vClassProps)}>V-Polarization</h6>

        <Theta
          className={itemBox(vClassProps)}
          value={String(theta['vertical'])}
          thetaMax={thetaMax['vertical']}
          off={isOff['vertical']}
          onChange={({ value, isValid }) =>
            handleChange({ label: 'theta', hvMode: 'vertical', value, isValid })
          }
          onFocus={() => handleInputFocus({ hvMode: 'vertical' })}
        />

        <Phi
          className={itemBox(vClassProps)}
          value={String(phi['vertical'])}
          off={isOff['vertical']}
          onChange={({ value, isValid }) =>
            handleChange({ label: 'phi', hvMode: 'vertical', value, isValid })
          }
          onFocus={() => handleInputFocus({ hvMode: 'vertical' })}
        />

        <Gain
          className={itemBox(vClassProps)}
          value={String(beamCurrentGain['vertical'])}
          gainMin={beamGainMin['vertical']}
          gainMax={beamGainMax['vertical']}
          disabled={isGainDisabled}
          off={isOff['vertical']}
          onChange={({ value, isValid }) =>
            handleChange({ label: 'gain', hvMode: 'vertical', value, isValid })
          }
          onFocus={() => handleInputFocus({ hvMode: 'vertical' })}
        />
      </div>
    </div>
  )
}

export default InputControl

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* -----------------     Static CSS     -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
const container = `[BeamSteering-InputControl-container]
                    grid grid-cols-2 
                    gap-x-4 
                    `

const subtitle = ({ isActive, isOff }) =>
  `${!isOff && isActive ? 'text-teal' : 'text-light-5'} 
                                font-sm font-bold text-center duration-500`
const itemBox = ({ isActive, isOff }) => `
                                ${
                                  !isOff && isActive
                                    ? 'opacity-100 drop-shadow-md'
                                    : 'opacity-50 drop-shadow-2xl'
                                } 
                                flex flex-col w-full 
                                duration-500
                                `

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* -----------------   Type  validate   -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
InputControl.propTypes = {
  theta: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  phi: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  handleInputChange: PropTypes.func,
}
