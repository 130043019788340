import React from 'react'
import { useDispatch } from 'react-redux'
import * as R from 'ramda'
import moment from 'moment'

import Checkbox from 'src/components/Button/Checkbox'

import { h3 } from 'src/containers/Modal/style'

import { BiChevronRight } from 'react-icons/bi'

import { modalActions } from 'src/redux/slices/modal'

import useGetCurrentDeviceData from 'src/hooks/useGetCurrentDeviceData'

import { pd_customCaliModal_editClick_watcher } from 'src/redux/actions/powerDetector'
import useGetUIControl from 'src/hooks/useGetUIControl'

const TBody = ({ caliData, selectIdList }) => {
  const dispatch = useDispatch()

  const { current } = useGetCurrentDeviceData()
  const currentData = current.data

  const isUsing = id =>
    currentData.deviceControl.actionPanel.currentCaliID === id

  const { all: all_uiControl } = useGetUIControl()
  const customCalibrationListSortRule =
    all_uiControl?.customCalibrationListSortRule

  const isChecked = id => selectIdList.includes(id)

  const sort =
    customCalibrationListSortRule === 'Latest to oldest'
      ? R.sort(R.descend(data => moment(data.createdAt).unix()))
      : R.sort(R.ascend(data => moment(data.createdAt).unix()))

  const lstCaliData = R.pipe(
    R.toPairs,
    R.map(e => ({ id: e[0], ...e[1] })),
    sort
  )(caliData)

  const handleSingleSelectClick = id => {
    let result

    if (selectIdList.includes(id)) {
      result = selectIdList.filter(e => e !== id)
    }

    if (!selectIdList.includes(id)) result = [...selectIdList, id]

    dispatch(
      modalActions.modal_props_set({
        priority: 'normal',
        props: {
          selectIdList: result,
        },
      })
    )
  }

  const handleSingleMainClick = id =>
    dispatch(
      pd_customCaliModal_editClick_watcher({
        editData: { [id]: caliData[id] },
      })
    )

  return (
    <div className={tBody}>
      {lstCaliData.map((e, i) => (
        <div key={e.id} className={tRow({ checked: isChecked(e.id) })}>
          <div className={tBodyCheckBoxContainer}>
            <Checkbox
              disabled={false}
              status={isChecked(e.id) ? 'checked' : 'uncheck'}
              onClick={() => handleSingleSelectClick(e.id)}
            />
          </div>

          <div
            className={tBodyMainContainer}
            onClick={() => handleSingleMainClick(e.id)}>
            <div className='w-full py-2 min-h-10 flex pr-[2px]'>
              <p
                className={
                  h3 + '  text-light-1 text-ellipsis overflow-hidden break-keep'
                }>
                {e.name}
              </p>

              <p className='text-yellow italic shrink-0 '>
                {isUsing(e.id) && ` --- In Use`}
              </p>
            </div>

            <div className={arrowContainer}>
              <BiChevronRight />
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}

TBody.propTypes = {}

export default TBody

const tBody = `w-full  max-h-[522px] overflow-y-scroll scrollbar`
const tRow = ({ checked }) => `
              w-full 
              px-4 py-2
              first:border-t-1 border-b-1 
              border-solid border-dark-5 
              flex 
              duration-300 
              ${!checked ? `hover:bg-black/30` : `bg-white/10`}
              `

const tBodyCheckBoxContainer = `
                          w-6 min-h-10 shrink-0
                          flex items-center
                          `

const tBodyMainContainer = `w-full flex items-center justify-between gap-x-2 pl-2 cursor-pointer `

const arrowContainer = `
                        w-6 h-6 shrink-0
                        text-white text-xl 
                        flex items-center justify-center 
                        cursor-pointer 
                        `
