import React from 'react'
import { useDispatch } from 'react-redux'

import Modal from 'src/components/Popup/Modal'
import CommonButton from 'src/components/Button/CommonButton'

import { BiError } from 'react-icons/bi'

import { modalActions } from 'src/redux/slices/modal'

const FileExportFailed = () => {
  const dispatch = useDispatch()

  const handleFailedOKClick = () =>
    dispatch(modalActions.modal_hide({ priority: 'normal' }))

  return (
    <Modal>
      <Modal.Icon>
        <BiError className='text-red ' />
      </Modal.Icon>

      <Modal.Title>Failed to export</Modal.Title>

      <Modal.Description>
        Please try again later. If the problem persists, please contact TMYTEK.
      </Modal.Description>

      <Modal.ColumnButtonBox>
        <CommonButton type='general' onClick={handleFailedOKClick}>
          OK
        </CommonButton>
      </Modal.ColumnButtonBox>
    </Modal>
  )
}

export default FileExportFailed
