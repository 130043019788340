import React, { useRef } from 'react'
import * as R from 'ramda'
import { useDispatch } from 'react-redux'
import { useErrorBoundary } from 'react-error-boundary'

import CommonButton from 'src/components/Button/CommonButton'
import CommonSelect from 'src/components/Select/CommonSelect'
import SelectWithAction from 'src/components/Select/SelectWithAction'

import { BiPlus } from 'react-icons/bi'

import useGetScreenSize from 'src/hooks/useGetScreenSize'
import useGetCurrentDeviceData from 'src/hooks/useGetCurrentDeviceData'

import {
  bbox_common_antennaImport_watcher,
  bbox_common_antennaSelect_watcher,
} from 'src/redux/actions/beamformers/bboxAction'

import { modalActions } from 'src/redux/slices/modal'

import { subtitle } from 'src/containers/operating/device/beamformers/bbox/DeviceController/Common'

import { BiEdit } from 'react-icons/bi'

import { isType } from 'src/funcs/tools'

import { getAntennaName } from 'src/funcs/device/bbox'

import useGetCurrentMode from 'src/hooks/useGetCurrentMode'

const Antenna = ({ disabled }) => {
  const inputFileRef = useRef()
  const dispatch = useDispatch()
  const { showBoundary } = useErrorBoundary()

  const { isDemoMode } = useGetCurrentMode()
  const { isSM, isMD, isMobile } = useGetScreenSize()
  const { current } = useGetCurrentDeviceData()
  const { sn, data } = current
  const { common } = data?.deviceControl

  const supportCustomAntenna = !isMobile
  const supportImport = !isMobile && !isDemoMode

  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------       Event        -----------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  const handleAntennaSelectChange = (event, value) =>
    dispatch(bbox_common_antennaSelect_watcher({ sn: current?.sn, value }))

  const handleCustomAntennaClick = () =>
    dispatch(
      modalActions.modal_show({
        priority: 'normal',
        name: 'BBOX_COMMON_CUSTOM_ANTENNA',
        props: {
          sn,
          editData: {},
          selectedIdList: [],
          child_modal_show: '',
          isCreate: false,
          isEditChanged: false,
          isEditSaveRequesting: false,
          isEditDeleteRequesting: false,
        },
      })
    )

  const handleImportClick = () => inputFileRef?.current?.click()

  const handleInputFileChange = async e => {
    try {
      if (e.target.files.length > 0) {
        await dispatch(
          modalActions.modal_show({
            priority: 'normal',
            name: 'FILE_IMPORT_IMPORTING',
            props: {},
          })
        )

        const lstAntennaFileData = Object.values(e.target.files)

        let antennaData = []

        // getFileDataObj 是一個 Promise
        // 參考文章：
        // 讓JS array reduce與async / await共舞
        // https://medium.com/@mengweichen/%E8%AE%93js-array-reduce%E8%88%87async-await%E5%85%B1%E8%88%9E-157a66ea2dfb
        for (const i in lstAntennaFileData) {
          antennaData[i] = await getFileDataObj(lstAntennaFileData[i])
        }

        const [validData, invalidData] = await filesDataVerify(antennaData)

        await dispatch(
          bbox_common_antennaImport_watcher({ sn, validData, invalidData })
        )

        inputFileRef.current.value = await ''
      }
    } catch (error) {
      showBoundary(error)
    }
  }

  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------        JSX         -----------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  return (
    <div className='w-full flex flex-col justify-center items-start gap-y-2 lg:gap-y-1 '>
      <div className='w-full flex items-center justify-between '>
        <h6 className={subtitle}>Antenna</h6>

        {supportCustomAntenna && (
          <CommonButton
            type='text'
            size={isSM || isMD ? 'lg' : 'sm'}
            disabled={disabled}
            icon={<BiEdit />}
            onClick={handleCustomAntennaClick}>
            Custom Antenna
          </CommonButton>
        )}
      </div>

      {supportImport ? (
        <>
          <input
            className='hidden'
            ref={inputFileRef}
            type='file'
            accept='.csv'
            disabled={disabled}
            multiple
            onChange={handleInputFileChange}
          />
          <SelectWithAction
            size={isSM || isMD ? 'md' : 'sm'}
            value={common?.currentAntenna}
            selectDisabled={disabled}
            actionDisabled={disabled}
            currentChildren={
              <span className={antennaOptionClass}>
                {getAntennaName(common?.currentAntenna) || noAntennaText}
              </span>
            }
            className={`w-full z-10 `}
            buttonChildren={<ImportButton />}
            onChange={handleAntennaSelectChange}
            onClickForAction={handleImportClick}>
            {common?.lstAntennaData.map(item => (
              <Option key={item.id} {...item}>
                <span className={antennaOptionClass + ' pr-2'}>
                  {getAntennaName(item.name) || noAntennaText}
                </span>
              </Option>
            ))}
          </SelectWithAction>
        </>
      ) : (
        <CommonSelect
          disabled={disabled}
          size={isSM || isMD ? 'md' : 'sm'}
          value={common?.currentAntenna}
          currentChildren={
            <span className={antennaOptionClass}>
              {getAntennaName(common?.currentAntenna) || noAntennaText}
            </span>
          }
          className={`w-full z-10 `}
          onChange={handleAntennaSelectChange}>
          {common?.lstAntennaData.map(item => (
            <Option key={item.id} {...item}>
              <span className={antennaOptionClass + ' pr-2'}>
                {getAntennaName(item.name) || noAntennaText}
              </span>
            </Option>
          ))}
        </CommonSelect>
      )}
    </div>
  )
}

export default Antenna

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* -----------------    Static  Func    -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
const noAntennaText = 'No Antenna. Please Import.'

// tracking-tighter text-[13px] 是因為當前官方天線在 current 會塞不下，
// 而 PO & QA 希望官方天線能完全顯示，因此調整成這樣
// https://tmytek.atlassian.net/browse/WEBTLK-899
const antennaOptionClass =
  'w-full -tracking-[0.07em] text-[13px] -mb-[1px] block text-ellipsis overflow-hidden'

// fileContent 會是長這樣
// AAKIT_NAME,TMYTEK_39ONE_4x4_C2210E007-28,,,,,,,,,,,,,,,\r\n
// DEV_TYPE,10,,,,,,,,,,,,,,,\r\n
// TYPE,0,,,,,,,,,,,,,,,\r\n
// SPACING,5,5,,,,,,,,,,,,,,,\r\n
// STEERING_H,-45,45,,,,,,,,,,,,,,,\r\n
// STEERING_V,-45,45,,,,,,,,,,,,,,,\r\n
// OFFSET_TX,0,10,10,25,15,20,25,20,0,15,15,10,5,10,10,25\r\n
// OFFSET_RX,0,10,10,25,15,20,25,20,0,15,15,10,5,10,10,25\r\n
const antennaFileContentToObj = (fileContent = '') =>
  fileContent
    .split('\r\n')
    .map(item => item.split(','))
    .reduce((accFile, currFile) => {
      if (currFile[0] === 'AAKIT_NAME') return { ...accFile, name: currFile[1] }

      if (currFile[0] === 'DEV_TYPE')
        return { ...accFile, deviceType: +currFile[1] }

      // 0 = official
      if (currFile[0] === 'TYPE')
        return {
          ...accFile,
          type: +currFile[1] ? 'customized' : 'official',
        }

      if (currFile[0] === 'STEERING_H')
        return { ...accFile, thetaMax: +currFile[2] }

      if (currFile[0] === 'SPACING')
        return {
          ...accFile,
          spacingX: +currFile[1],
          spacingY: +currFile[2],
        }

      if (currFile[0] === 'OFFSET_RX')
        return {
          ...accFile,
          rxOffset: [...currFile].map(e => +e).filter(e => e || e === 0),
        }

      if (currFile[0] === 'OFFSET_TX')
        return {
          ...accFile,
          txOffset: [...currFile].map(e => +e).filter(e => e || e === 0),
        }

      return accFile
    }, {})

const getFileDataObj = file =>
  new Promise((resolve, reject) => {
    let reader = new FileReader()

    reader.onload = e => {
      const fileContent = e.target.result
      const fileContentPackage = antennaFileContentToObj(fileContent)
      const addedFileName = { filename: file.name, ...fileContentPackage }
      resolve(addedFileName)
    }

    reader.readAsText(file)
  })

const typeVerify = (path, type) => data =>
  R.pipe(R.path([path]), isType(type))(data)

const filesDataVerify = files =>
  R.partition(
    R.allPass([
      R.has('filename'),
      typeVerify('filename', 'string'),
      R.has('name'),
      typeVerify('name', 'string'),
      R.has('deviceType'),
      typeVerify('deviceType', 'number'),
      R.has('type'),
      typeVerify('type', 'string'),
      R.has('thetaMax'),
      typeVerify('thetaMax', 'number'),
      R.has('spacingX'),
      typeVerify('spacingX', 'number'),
      R.has('spacingY'),
      typeVerify('spacingY', 'number'),
      R.has('rxOffset'),
      typeVerify('rxOffset', 'array'),
      R.has('txOffset'),
      typeVerify('txOffset', 'array'),
    ])
  )(files)

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* ----------------- Another Components -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
const ImportButton = () => (
  <div className='flex items-center group overflow-hidden'>
    <BiPlus />
    <span
      className='opacity-0 w-0 
    group-hover:w-[50px] group-hover:opacity-100 
    duration-500'>
      Import
    </span>
  </div>
)
const Option = ({ name, children }) => (
  <SelectWithAction.Option name={name} disabled={false}>
    {children || noAntennaText}
  </SelectWithAction.Option>
)
