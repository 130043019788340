import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import Checkbox from 'src/components/Button/Checkbox'

import { h3 } from 'src/containers/Modal/style'
import { getCustomAntennaIdList } from 'src/funcs/device/bbox'
import useGetCurrentDeviceData from 'src/hooks/useGetCurrentDeviceData'

import { modalActions } from 'src/redux/slices/modal'

const THead = () => {
  const dispatch = useDispatch()

  const modalProps = useSelector(state => state.modal.normal.props)
  const { selectedIdList } = modalProps

  const { current } = useGetCurrentDeviceData()
  const antennaData = current?.data.deviceControl?.common?.lstAntennaData
  const customAntennaIdList = getCustomAntennaIdList(antennaData)
  const lstCurrentAntennaData = current.data.deviceControl.common.lstAntennaData
  const customOnlyAntennaList = lstCurrentAntennaData.filter(
    e => e.type === 'customized'
  )

  const isAll = selectedIdList.length === customAntennaIdList.length

  const checkBoxStatus = isAll
    ? 'checked'
    : selectedIdList.length === 0
    ? 'uncheck'
    : 'indeterminate'

  const handleAllSelectClick = () =>
    dispatch(
      modalActions.modal_props_set({
        priority: 'normal',
        props: {
          selectedIdList: isAll ? [] : customAntennaIdList,
        },
      })
    )

  return (
    <div className={tHead} onClick={handleAllSelectClick}>
      <Checkbox status={checkBoxStatus} />
      <div className={h3 + ' text-light-1'}>
        Custom Antenna ({customOnlyAntennaList.length} / 50)
      </div>
    </div>
  )
}

export default THead

const tHead = `
              h-7 
              mt-6 mb-3 p-1 ml-3
              flex items-center gap-x-3 
              cursor-pointer select-none 
              `
