import React from 'react'

import PortalAndOverlay from 'src/components/Popup/Modal/PortalAndOverlay'

import ListMode from 'src/containers/Modal/Normal/PowerDetector/CustomCalibration/ListMode'
import EditMode from 'src/containers/Modal/Normal/PowerDetector/CustomCalibration/EditMode'

import SaveCalibrationConfigReminder from 'src/containers/Modal/Normal/PowerDetector/CustomCalibration/ChildModal/SaveCalibrationConfigReminder'
import ClearIncompleteData from 'src/containers/Modal/Normal/PowerDetector/CustomCalibration/ChildModal/ClearIncompleteData'
import ListModeDeleteCheck from 'src/containers/Modal/Normal/PowerDetector/CustomCalibration/ChildModal/ListModeDeleteCheck'
import EditModeDeleteCheck from 'src/containers/Modal/Normal/PowerDetector/CustomCalibration/ChildModal/EditModeDeleteCheck'
import LeaveEditCheck from 'src/containers/Modal/Normal/PowerDetector/CustomCalibration/ChildModal/LeaveEditCheck'
import UsingNotDeleteCheck from 'src/containers/Modal/Normal/PowerDetector/CustomCalibration/ChildModal/UsingNotDeleteCheck'
import CaliConfigFileSaved from 'src/containers/Modal/Normal/PowerDetector/CustomCalibration/ChildModal/CaliConfigFileSaved'

export const defaultProps = {
  selectIdList: [],
  isEditChanged: false,
  isEditCreate: false,
  isEditSaveRequesting: false,
  isDeleteRequesting: false,

  editData: {},

  // getVoltageEditList = {'100MHz': ['lowVolt', 'highVolt']}
  // 用來判斷 FreqItem 的外框與文字樣式
  getVoltageEditList: {},
  isGetVoltError: false,

  // 子 modal 控制項
  child_modal_show: '',
}

const CustomCalibration = modalProps => {
  const { editData, child_modal_show } = modalProps
  const isTemporaryDataExist = Object.keys(editData).length !== 0

  return (
    <PortalAndOverlay
      open
      render={close => (
        <div className={modalClass}>
          {!isTemporaryDataExist ? (
            <ListMode {...modalProps} />
          ) : (
            <EditMode {...modalProps} />
          )}

          {child_modal_show === 'SAVE_CALI_CONFIG_REMINDER' && (
            <SaveCalibrationConfigReminder />
          )}
          {child_modal_show === 'CLEAR_INCOMPLETE_DATA' && (
            <ClearIncompleteData />
          )}
          {child_modal_show === 'LIST_MODE_DELETE_CHECK' && (
            <ListModeDeleteCheck {...modalProps} />
          )}
          {child_modal_show === 'EDIT_MODE_DELETE_CHECK' && (
            <EditModeDeleteCheck {...modalProps} />
          )}
          {child_modal_show === 'LEAVE_EDIT_CHECK' && <LeaveEditCheck />}
          {child_modal_show === 'USING_NOT_DELETE_CHECK' && (
            <UsingNotDeleteCheck />
          )}
          {child_modal_show === 'CALI_CONFIG_FILE_SAVED' && (
            <CaliConfigFileSaved />
          )}
        </div>
      )}
    />
  )
}

export default CustomCalibration

const modalClass = `relative 
                    w-full max-w-[800px] 
                    bg-dark-2 
                    flex flex-col 
                    rounded-[8px]   
                    `
