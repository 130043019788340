import React, { useState } from 'react'
import InputGroupAppendDialog from 'src/components/composite/InputGroupAppendDialog'

import {
  gainIcon,
  gainStep,
  gainUnit,
  gainDecimalScale,
  getGainWarningText,
} from 'src/constants/beamformers'
import { rangeText } from 'src/containers/operating/device/beamformers/style'
import InputGroup from 'src/components/Input/InputGroup'

const Gain = props => {
  const {
    // Required
    value = '0',
    gainMin = 0,
    gainMax = 0,
    onChange = () => {},

    // input props
    className = 'w-full',
    icon = gainIcon,
    unit = gainUnit,
    step = gainStep,
    loop = false,
    validMin = gainMin,
    validMax = gainMax,
    decimalScale = gainDecimalScale,
    off = false,
    disabled = false,
    size = 'lg',
    suggestText = `Range ${gainMin} ~ ${gainMax}`,

    // dialog props
    placement = 'top',
    overwriteOffset = { top: 0, left: 0 },
    onFocus = () => {},
  } = props

  const [warning, setWarning] = useState('')

  const gainInputProps = {
    size,
    icon,
    unit,
    step,
    loop,
    validMin,
    validMax,
    keydownMin: validMin,
    keydownMax: validMax,
    decimalScale,
    value,
    disabled,
    off,
  }

  // dialog 要蓋上去的元素
  const overwriteElements = (
    <div>
      <InputGroup warning={true} disabled={true} {...gainInputProps} />
      <span className={rangeText + ' text-yellow'}>{suggestText}</span>
    </div>
  )
  const warningText = getGainWarningText({
    min: gainMin,
    max: gainMax,
    step: gainStep,
  })

  const gainDialogProps = {
    placement,
    overwriteOffset,
    overwriteElements: overwriteElements,
    dialogText: warningText[warning],
    warning: warning,
    setWarning: result => setWarning(result),
    setFocus: onFocus,
    changeCallback: onChange,
  }

  return (
    <div className={className}>
      <InputGroupAppendDialog {...gainInputProps} {...gainDialogProps} />
      {!disabled && <span className={rangeText}>{suggestText}</span>}
    </div>
  )
}

export default Gain
