import { take, race, call, put, select } from 'redux-saga/effects'

import __restful_API_sender from 'src/redux/sagas/services/restfulTools/__restful_API_sender'
import { sendEmailAPI } from 'src/redux/sagas/services/restfulAPI/facilityApi'

import { devWarLog } from 'src/funcs/tools'

import {
  facility_chooseDeviceModal_cancelClick_watcher,
  facility_personalInformationModal_submit_watcher,
} from 'src/redux/actions/facility/getQuote'

import { modalActions } from 'src/redux/slices/modal'

import {
  createRecipient,
  createCcRecipient,
  createBccRecipient,
  createSubject,
  createHtmlTemplate,
  createTextTemplate,
} from 'src/constants/getQuote'

export function* getQuote() {
  try {
    const modalProps = yield select(state => state.modal.normal.props)

    if (modalProps.errorMessage)
      yield put(
        modalActions.modal_show({
          priority: 'normal',
          name: 'GET_QUOTE_PERSONAL_INFORMATION',
          props: { ...modalProps },
        })
      )
    else
      yield put(
        modalActions.modal_show({
          priority: 'normal',
          name: 'GET_QUOTE_CHOOSE_DEVICE_AND_PURPOSE',
          props: {
            interestedProduct: [],
            purpose: '',
            purposeOther: '',
            firstName: '',
            lastName: '',
            email: '',
            comments: '',
            privacyPolicy: false,
            errorMessage: '',
          },
        })
      )

    const { cancel, sendMail } = yield race({
      cancel: take(facility_chooseDeviceModal_cancelClick_watcher.type),
      sendMail: take(facility_personalInformationModal_submit_watcher.type),
    })

    if (cancel) yield put(modalActions.modal_hide({ priority: 'normal' }))
    if (sendMail) {
      const modalProps = yield select(state => state.modal.normal.props)
      // ---- modalProps example
      // const {
      //   interestedProduct,
      //   purpose,
      //   purposeOthers,
      //   firstName,
      //   lastName,
      //   email,
      //   comments,
      //   privacyPolicy,
      // } = modalProps

      const { interestedProduct, firstName, lastName } = modalProps
      const subject = createSubject({ interestedProduct, firstName, lastName })
      const bodyHtml = createHtmlTemplate(modalProps)
      const bodyText = createTextTemplate(modalProps)

      const data = {
        recipient: createRecipient(),
        ccRecipient: createCcRecipient(),
        bccRecipient: createBccRecipient(),
        subject,
        bodyHtml,
        bodyText,
      }

      const response = yield call(__restful_API_sender, {
        api: sendEmailAPI,
        data,
      })

      if (response.status !== 200) throw Error(response.message)

      if (response.status === 200)
        yield put(
          modalActions.modal_show({
            priority: 'normal',
            name: 'GET_QUOTE_SUCCESS',
            props: {},
          })
        )
    }
  } catch (error) {
    devWarLog('[handler] getQuote error:', error)
    yield put(
      modalActions.modal_props_set({
        priority: 'normal',
        props: {
          errorMessage: error.message,
        },
      })
    )
    yield call(getQuote)
  }
}
