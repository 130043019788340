import React from 'react'
import { useDispatch } from 'react-redux'

import Modal from 'src/components/Popup/Modal'
import CommonButton from 'src/components/Button/CommonButton'

import { BiError } from 'react-icons/bi'

import { modalActions } from 'src/redux/slices/modal'

const FileImportExceededMax = props => {
  const dispatch = useDispatch()
  const handleExceededMaxOKClick = () =>
    dispatch(modalActions.modal_hide({ priority: 'normal' }))
  return (
    <Modal>
      <Modal.Icon>
        <BiError className='text-yellow ' />
      </Modal.Icon>

      <Modal.Title>
        Limit import to {props.filesCountMax} files at a time
      </Modal.Title>

      <Modal.Description>Please import in batches.</Modal.Description>

      <Modal.ColumnButtonBox>
        <CommonButton type='general' onClick={handleExceededMaxOKClick}>
          OK
        </CommonButton>
      </Modal.ColumnButtonBox>
    </Modal>
  )
}

export default FileImportExceededMax
