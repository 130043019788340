import React from 'react'
import { useDispatch } from 'react-redux'

import Modal from 'src/components/Popup/Modal'
import CommonButton from 'src/components/Button/CommonButton'

import {
  cloverCellCTTC_toExternalModal_useExternalClick_watcher,
  cloverCellCTTC_toExternalModal_cancelClick_watcher,
} from 'src/redux/actions/beamformers/cloverCellCTTCAction'

import Stepper from 'src/components/Stepper'

import { h4, h6 } from 'src/containers/Modal/style'
import useGetUIControl from 'src/hooks/useGetUIControl'

const ToExternalCheck = ({ sn }) => {
  const dispatch = useDispatch()

  const { single: s_uiControl } = useGetUIControl(sn)
  const { isSuccess: toExternalSuccess, isRequesting: toExternalRequesting } =
    s_uiControl?.common?.toExternal

  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------       Event        -----------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------

  const handleCancelClick = () =>
    dispatch(cloverCellCTTC_toExternalModal_cancelClick_watcher())
  const handleUseExternalClick = () =>
    dispatch(cloverCellCTTC_toExternalModal_useExternalClick_watcher())

  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------        CSS        ------------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------

  return (
    <Modal>
      <Modal.Title>Use External Reference Source</Modal.Title>

      <Stepper
        name='Use External Reference Source'
        className='mt-8'
        gap='gap-y-5'>
        <Stepper.Step>
          <h4 className={h4 + ' text-white'}>
            Connect to an external 100-MHz ref. source
          </h4>
          <p className={h6 + ' text-light-4 mt-1'}>
            Ensure the source is connected.
          </p>
        </Stepper.Step>
        <Stepper.Step>
          <h4 className={h4 + ' text-white'}>
            Turn on the external 100-MHz ref. source
          </h4>
          <p className={h6 + ' text-light-4 mt-1'}>
            Make sure the source has been transmitted.
          </p>
        </Stepper.Step>
      </Stepper>

      <Modal.RowButtonBox>
        <CommonButton
          size='lg'
          type='outlined'
          disabled={toExternalRequesting}
          onClick={handleCancelClick}>
          Cancel
        </CommonButton>
        <CommonButton
          size='lg'
          type='primary'
          loading={toExternalRequesting}
          success={toExternalSuccess}
          onClick={handleUseExternalClick}>
          Use External
        </CommonButton>
      </Modal.RowButtonBox>
    </Modal>
  )
}

export default ToExternalCheck
