import React from 'react'

import Modal from 'src/components/Popup/Modal'

import { BiCheckCircle } from 'react-icons/bi'

const FileExportSuccessful = () => {
  return (
    <Modal>
      <Modal.Icon>
        <BiCheckCircle className='text-green' />
      </Modal.Icon>
      <Modal.Title>Export successful</Modal.Title>
    </Modal>
  )
}

export default FileExportSuccessful
