import { take, call, put, race, delay } from 'redux-saga/effects'
import * as R from 'ramda'

import __restful_API_sender from 'src/redux/sagas/services/restfulTools/__restful_API_sender'

import { getCommonArgs } from 'src/redux/sagas/selector/deviceData'

import { setSingleDeviceData } from 'src/redux/slices/deviceData'

import __socket_API_sender from 'src/redux/sagas/services/socketTools/__socket_API_sender'
import beamformersApi from 'src/redux/sagas/services/socketAPI/beamformers'
import {
  spiConfigSaveApi,
  spiConfigImportApi,
  spiConfigExportApi,
} from 'src/redux/sagas/services/restfulAPI/beamformersApi'

import { devWarLog } from 'src/funcs/tools'

import { setConfigEditorSelectList } from 'src/redux/slices/uiControl/beamformers/bbox'

import { modalActions } from 'src/redux/slices/modal'

import {
  setConfigEditorIsSelectMode,
  setConfigEditorIsChanged,
  setConfigEditorSaveStatus,
  setConfigEditorSwitchMethodStatus,
} from 'src/redux/slices/uiControl/beamformers/bbox'

import {
  bbox_configClearCheckModal_clearClick_watcher,
  bbox_configClearCheckModal_cancelClick_watcher,
  bbox_configFailedToImportNoMatchedAntennaModal_okClick_watcher,
  bbox_configImportNewSettingsModal_noClick_watcher,
  bbox_configImportNewSettingsModal_importClick_watcher,
  bbox_configUnsavedChangesReminderModal_exportOnlyClick_watcher,
  bbox_configUnsavedChangesReminderModal_exportAndSaveClick_watcher,
} from 'src/redux/actions/beamformers/bboxAction'

import { getUiControl } from 'src/redux/sagas/selector/uiControl'
import __export_API_sender from 'src/redux/sagas/services/restfulTools/__export_API_sender'

// 只要更新 deviceData 的 beamConfigEditor ,
// 就把 isChanged 調整 true
// 因為 deviceControl 頁面、 spi 頁面、 editBeam Modal 都會調
// 這邊統一處理並管理 isChanged 比較乾淨
export function* updateDeviceDataCurrentConfig(payloads) {
  try {
    const { sn, currentConfig } = payloads
    let { currentData } = yield call(getCommonArgs, sn)

    currentData.beamConfigEditor.currentConfig = currentConfig

    yield put(setConfigEditorIsChanged({ sn, value: true }))
    yield put(setSingleDeviceData({ sn, data: currentData }))
  } catch (error) {
    devWarLog('[handler] updateDeviceDataCurrentConfig error:', error)
  }
}

export function* spiSaveConfig(payloads) {
  try {
    const { sn } = payloads
    yield put(setConfigEditorSaveStatus({ sn, value: 'loading' }))

    let { currentData, lookupID } = yield call(getCommonArgs, sn)
    const { tableData, sort, beamConfigName } =
      currentData.beamConfigEditor.currentConfig

    const response = yield call(__restful_API_sender, {
      api: spiConfigSaveApi,
      data: {
        sn,
        lookupID,
        beamConfigName,
        tableData,
        sort,
      },
    })

    if (response.data.code === 0) {
      yield put(setConfigEditorSaveStatus({ sn, value: 'success' }))
      yield put(setConfigEditorIsChanged({ sn, value: false }))
      yield delay(2000)
      yield put(setConfigEditorSaveStatus({ sn, value: '' }))
    }

    if (response.data.code !== 0) {
      // 顯示 spi save 錯誤的 modal, 因當前為 No antenna, 但裡面有 beam config
      // 這個目前用不到，等到支援單一天線綁定多張 config (有 team 時)，在啟用
      // yield put(
      //   modalActions.modal_show({
      //     priority: 'normal',
      //     name: 'BBOX_BEAM_CONFIG_EDITOR_SAVE_NO_ANTENNA_AND_BEAM_EXIST',
      //     props: {},
      //   })
      // )
      yield put(
        modalActions.modal_show({
          priority: 'normal',
          name: 'BBOX_BEAM_CONFIG_EDITOR_SAVE_GENERAL_FAILED',
          props: {},
        })
      )
    }
  } catch (error) {
    devWarLog('[handler] spiSaveConfig error:', error)
  }
}

export function* spiSwitchMethod(action) {
  try {
    const { sn } = action.payload

    yield put(
      modalActions.modal_show({
        priority: 'normal',
        name: 'BBOX_BEAM_CONFIG_EDITOR_CURRENT_METHOD_SWITCHING',
        props: {},
      })
    )

    yield put(setConfigEditorIsSelectMode({ sn, value: false }))
    yield put(setConfigEditorSwitchMethodStatus({ sn, value: 'loading' }))

    let { currentData, lookupID } = yield call(getCommonArgs, sn)
    const controlMethod = currentData.beamConfigEditor.controlMethod
    const newControlMethod = controlMethod === 'SPI' ? 'TLK' : 'SPI'

    const ack = yield call(
      __socket_API_sender,
      beamformersApi.SPI_SWITCH_METHOD,
      {
        sn,
        lookupID,
        controlMethod: newControlMethod,
      }
    )

    if (ack === 0) {
      currentData.beamConfigEditor.controlMethod = newControlMethod
      yield put(setSingleDeviceData({ sn, data: currentData }))
      yield put(setConfigEditorSwitchMethodStatus({ sn, value: '' }))
      yield put(modalActions.modal_hide({ priority: 'normal' }))
    }
  } catch (error) {
    devWarLog('[handler] spiSwitchMethod error:', error)
  }
}

function* callImportApi({
  sn,
  fileName = '',
  jsonFileData,
  targetFreq = null,
  targetAntenna = null,
}) {
  try {
    let { lookupID } = yield call(getCommonArgs, sn)

    if (targetFreq)
      // 切到 config 綁定的 freq
      // 不使用原本切 freq 的 saga, 建立屬於 SPI 獨立的 freq api
      // (modal 不同)
      yield call(__socket_API_sender, beamformersApi.COMMON_FREQUENCY, {
        sn,
        lookupID,
        value: targetFreq,
      })

    if (targetAntenna || targetAntenna === '')
      // 切到 config 綁定的 freq
      // 不使用原本切 antenna 的 saga, 建立屬於 SPI 獨立的 antenna api
      // (modal 不同)
      yield call(__socket_API_sender, beamformersApi.AAKIT_SELECT, {
        sn,
        lookupID,
        value: targetAntenna,
      })

    //* BE 會把 jsonFileData 無腦傳給 API 做驗證
    //* API 在驗證時會當前 freq + antenna 來驗證
    //* 因此直接前端切過去 config 綁定的 freq & antenna 就好
    const response = yield call(__restful_API_sender, {
      api: spiConfigImportApi,
      data: {
        sn,
        lookupID,
        jsonFileData,
      },
    })

    if (response.data.code === 0) {
      let { currentData } = yield call(getCommonArgs, sn)
      const { currentConfig: responseCurrentConfig } = response.data.data

      currentData.beamConfigEditor.currentConfig = responseCurrentConfig

      yield put(setSingleDeviceData({ sn, data: currentData }))
      yield put(setConfigEditorIsChanged({ sn, value: true }))

      yield put(
        modalActions.modal_show({
          priority: 'normal',
          name: 'FILE_IMPORT_SUCCESS',
          props: {},
        })
      )
      yield delay(2000)
      yield put(modalActions.modal_hide({ priority: 'normal' }))
    }

    if (response.data.code !== 0) {
      const showSaveFailedModalErrorMessageList = [
        'Invalid state while checkBeam',
      ]

      if (showSaveFailedModalErrorMessageList.includes(response.data.message))
        yield put(
          modalActions.modal_show({
            priority: 'normal',
            name: 'BBOX_BEAM_CONFIG_EDITOR_IMPORT_NO_MATCHED_ANTENNA',
            props: {},
          })
        )
      else
        yield put(
          modalActions.modal_show({
            priority: 'normal',
            name: 'FILE_IMPORT_FAILED',
            props: {
              failedList: [fileName],
            },
          })
        )
    }
  } catch (error) {
    devWarLog('[handler] callImportApi error:', error)
  }
}

export function* spiImportConfig(payloads) {
  try {
    const { sn: currentSn, fileName, jsonFileData } = payloads
    let { currentData } = yield call(getCommonArgs, currentSn)

    const { currentFreq, lstFreqOptions, lstAntennaData, currentAntenna } =
      currentData.deviceControl.common

    const bindSn = jsonFileData?.SN
    const bindFreq = jsonFileData?.FREQ
    const bindAntenna = jsonFileData?.AAKIT_NAME

    const bindAntennaDataExist =
      bindAntenna === '' ||
      lstAntennaData.find(e => e.name === bindAntenna) !== undefined
    const bindFreqDataExist =
      lstFreqOptions.find(e => +e === +bindFreq) !== undefined

    const bindSnOrBindFreqInvalid = !bindSn || !bindFreqDataExist
    const bindSnNotCurrentDevice = bindSn !== currentSn

    const isValid = !bindSnOrBindFreqInvalid && !bindSnNotCurrentDevice

    //! config 檔案的 sn or freq 不存在 或是空值
    if (bindSnOrBindFreqInvalid)
      return yield put(
        modalActions.modal_show({
          priority: 'normal',
          name: 'BBOX_BEAM_CONFIG_EDITOR_IMPORT_INCORRECT_FORMAT',
          props: {},
        })
      )

    //! config 檔案的 sn 不是當前 device
    if (bindSnNotCurrentDevice)
      return yield put(
        modalActions.modal_show({
          priority: 'normal',
          name: 'BBOX_BEAM_CONFIG_EDITOR_IMPORT_INCORRECT_SN',
          props: {
            currentSn,
            bindSn,
          },
        })
      )

    if (isValid) {
      const bindDifferentWithCurrent =
        bindFreq !== currentFreq || bindAntenna !== currentAntenna

      if (!bindAntennaDataExist) {
        //* config 檔案的 antenna 不存在 (要切回 no antenna)
        yield put(
          modalActions.modal_show({
            priority: 'normal',
            name: 'BBOX_BEAM_CONFIG_EDITOR_IMPORT_NO_MATCHED_ANTENNA',
            props: {
              bindAntenna,
            },
          })
        )

        yield take(
          bbox_configFailedToImportNoMatchedAntennaModal_okClick_watcher.type
        )

        yield put(modalActions.modal_hide({ priority: 'normal' }))
      } else if (bindDifferentWithCurrent) {
        //* config 檔案的 freq 或 antenna 跟當前使用的不一樣
        //* 跳 modal 使用者同意後
        //* 把當前 freq & antenna 切成與 config 一致
        const targetFreq = bindFreq !== currentFreq ? bindFreq : null
        const targetAntenna =
          bindAntenna !== currentAntenna ? bindAntenna : null

        yield put(
          modalActions.modal_show({
            priority: 'normal',
            name: 'BBOX_BEAM_CONFIG_EDITOR_IMPORT_NEW_SETTINGS',
            props: {
              targetFreq,
              targetAntenna,
            },
          })
        )

        const { ok, no } = yield race({
          ok: take(bbox_configImportNewSettingsModal_importClick_watcher.type),
          no: take(bbox_configImportNewSettingsModal_noClick_watcher.type),
        })

        if (no) yield put(modalActions.modal_hide({ priority: 'normal' }))

        if (ok)
          yield call(callImportApi, {
            sn: bindSn,
            fileName,
            jsonFileData,
            targetFreq,
            targetAntenna,
          })
      } else {
        yield call(callImportApi, {
          sn: bindSn,
          fileName,
          jsonFileData,
        })
      }
    }
  } catch (error) {
    devWarLog('[handler] spiImportConfig error:', error)
  }
}

export function* spiExportConfig(payloads) {
  try {
    yield put(
      modalActions.modal_show({
        priority: 'normal',
        name: 'FILE_EXPORT_EXPORTING',
        props: {},
      })
    )
    const { sn } = payloads

    let { currentData, lookupID } = yield call(getCommonArgs, sn)
    const { single: s_uiControl } = yield call(getUiControl, sn)
    const { isChanged } = s_uiControl.beamConfigEditor

    if (isChanged) {
      yield put(
        modalActions.modal_show({
          priority: 'normal',
          name: 'BBOX_BEAM_CONFIG_EDITOR_EXPORT_UNSAVED_CHANGES',
          props: { isSaveLoading: false },
        })
      )

      const { exportOnly, exportAndSave } = yield race({
        exportOnly: take(
          bbox_configUnsavedChangesReminderModal_exportOnlyClick_watcher.type
        ),
        exportAndSave: take(
          bbox_configUnsavedChangesReminderModal_exportAndSaveClick_watcher.type
        ),
      })

      if (exportOnly) yield put(modalActions.modal_hide({ priority: 'normal' }))
      if (exportAndSave) {
        yield put(
          modalActions.modal_show({
            priority: 'normal',
            name: 'BBOX_BEAM_CONFIG_EDITOR_EXPORT_UNSAVED_CHANGES',
            props: { isSaveLoading: true },
          })
        )
        yield call(spiSaveConfig, { sn })
        yield put(modalActions.modal_hide({ priority: 'normal' }))
      }
    }

    const tableData = currentData.beamConfigEditor.currentConfig.tableData

    const params = yield new URLSearchParams()
    yield params.append('sn', sn)
    yield params.append('lookupID', lookupID)
    yield params.append('tableData', tableData)

    const { success } = yield call(__export_API_sender, {
      api: spiConfigExportApi,
      data: { sn, lookupID, tableData },
    })

    if (success) {
      yield put(
        modalActions.modal_show({
          priority: 'normal',
          name: 'FILE_EXPORT_SUCCESS',
          props: {},
        })
      )
      yield delay(1000)
      yield put(modalActions.modal_hide({ priority: 'normal' }))
    }

    if (!success)
      yield put(
        modalActions.modal_show({
          priority: 'normal',
          name: 'FILE_EXPORT_FAILED',
          props: {},
        })
      )
  } catch (error) {
    devWarLog('[handler] spiExportConfig error:', error)
  }
}

export function* configClear(payloads) {
  try {
    yield put(
      modalActions.modal_show({
        priority: 'high',
        name: 'BEAMFORMERS_BBOX_BEAM_CONFIG_EDITOR_CLEAR_CHECK',
        props: {},
      })
    )

    const { clear } = yield race({
      clear: take(bbox_configClearCheckModal_clearClick_watcher.type),
      cancel: take(bbox_configClearCheckModal_cancelClick_watcher.type),
    })

    yield put(modalActions.modal_hide({ priority: 'high' }))

    if (clear) {
      const { sn, clearLists } = payloads
      let { currentData } = yield call(getCommonArgs, sn)
      const { single: s_uiControl } = yield call(getUiControl, sn)
      const { rfMode, selectLists } = s_uiControl.beamConfigEditor

      const newCurrentData = R.modifyPath(
        ['beamConfigEditor', 'currentConfig', 'tableData', rfMode],
        R.reject(e => R.includes(e.beamID, clearLists))
      )(currentData)

      yield put(setSingleDeviceData({ sn, data: newCurrentData }))
      yield put(
        setConfigEditorSelectList({
          sn,
          value: { ...selectLists, [rfMode]: [] },
        })
      )

      yield put(setConfigEditorIsChanged({ sn, value: true }))
    }
  } catch (error) {
    devWarLog('[handler] configClear error:', error)
  }
}
