import { select, put } from 'redux-saga/effects'
import * as R from 'ramda'

import { devWarLog } from 'src/funcs/tools'

import { modalActions } from 'src/redux/slices/modal'

import { getInitCaliData } from 'src/constants/powerDetector'

export function* getCaliVoltage({ response }) {
  try {
    const [, freqPower] = Object.entries(response?.data)[0]
    const [freq, { power }] = Object.entries(freqPower)[0]

    // 1. 顯示 error mask
    yield put(
      modalActions.modal_props_set({
        priority: 'normal',
        props: {
          isGetVoltError: true,
        },
      })
    )

    let targetVoltage
    let oppositeVoltage

    const modalProps = yield select(state => state.modal.normal.props)
    const editData = modalProps.editData
    const getVoltageEditList = modalProps.getVoltageEditList
    let cloneEditData = R.clone(editData)

    // 2. 取消 target 的 白字狀態 (getVoltageEditList 拔掉該 targetVoltage)
    const [[id, content]] = Object.entries(cloneEditData)

    if (+content.value[freq].lowPower === +power) {
      targetVoltage = 'lowVolt'
      oppositeVoltage = 'highVolt'
    } else if (+content.value[freq].highPower === +power) {
      targetVoltage = 'highVolt'
      oppositeVoltage = 'lowVolt'
    } else throw Error('getCaliVoltage: BE return power not matched!')

    yield put(
      modalActions.modal_props_set({
        priority: 'normal',
        props: {
          getVoltageEditList: R.modify(
            freq,
            R.filter(e => e !== targetVoltage)
          )(getVoltageEditList),
        },
      })
    )

    // 3. 調整 target or opposite voltage
    const isNeedChangeTargetVoltToEmpty =
      getVoltageEditList[freq].includes(oppositeVoltage)

    const isNeedChangeBothVoltToDefault =
      !getVoltageEditList[freq].includes(oppositeVoltage)

    let newTargetVolt
    let newOppositeVolt = cloneEditData[id].value[freq][oppositeVoltage]

    if (isNeedChangeTargetVoltToEmpty) {
      newTargetVolt = '---'
    } else if (isNeedChangeBothVoltToDefault) {
      newTargetVolt = Object.values(getInitCaliData())[0].value[freq][
        oppositeVoltage
      ]
      newOppositeVolt = Object.values(getInitCaliData())[0].value[freq][
        targetVoltage
      ]
    } else {
      newTargetVolt = Object.values(getInitCaliData())[0].value[freq][
        targetVoltage
      ]
    }

    cloneEditData[id].value[freq][oppositeVoltage] = newOppositeVolt
    cloneEditData[id].value[freq][targetVoltage] = newTargetVolt

    // 4. 將當前 freq 的 isDefault 因為有錯就一直不合法
    cloneEditData[id].value[freq]['isDefault'] = true

    yield put(
      modalActions.modal_props_set({
        priority: 'normal',
        props: {
          isEditChanged: true,
          editData: cloneEditData,
        },
      })
    )
  } catch (error) {
    devWarLog('[res-handler] caliSelect error:', error)
  }
}
