// bbox
export const spiConfigSaveApi = () => ({
  server: 'beamformers',
  method: 'PUT',
  url: '/api/beamformers/spi/save/config',
})

// bbox
export const spiConfigImportApi = () => ({
  server: 'beamformers',
  method: 'POST',
  url: '/api/beamformers/spi/import/config',
})

// bbox
export const spiConfigExportApi = () => ({
  server: 'beamformers',
  method: 'POST',
  url: '/api/beamformers/spi/export/config',
  timeout: 5000,
})

// ris
export const risCommonImportApi = () => ({
  server: 'beamformers',
  method: 'POST',
  url: '/api/beamformers/ris/common/import/channel',
})
export const risCommonExportChannelApi = () => ({
  server: 'beamformers',
  method: 'POST',
  url: '/api/beamformers/ris/common/export/channel',
  timeout: 5000,
})
