import React from 'react'
import moment from 'moment'
import { useSelector, useDispatch } from 'react-redux'

import Common from 'src/containers/operating/device/beamformers/ris/v1/Common'
import BeamSteering from 'src/containers/operating/device/beamformers/ris/v1/BeamSteering'
import Channel from 'src/containers/operating/device/beamformers/ris/v1/Channel'

import useGetCurrentDeviceData from 'src/hooks/useGetCurrentDeviceData'

import { h4 } from 'src/containers/operating/device/beamformers/style'

import noDeviceFound from 'src/assets/img/no_device_found.png'
import CommonButton from 'src/components/Button/CommonButton'

import { scanWatcher } from 'src/redux/slices/deviceData'
import { modalActions } from 'src/redux/slices/modal'

const RisV1 = props => {
  const { current } = useGetCurrentDeviceData()

  const data_28 = current?.data?.deviceControl?.[28000]
  const data_47 = current?.data?.deviceControl?.[4700]

  const isShow28 = data_28 && Object.keys(data_28).length > 0
  const isShow47 = data_47 && Object.keys(data_47).length > 0

  if (!isShow28 && !isShow47) return <DeviceNotBeFound />

  return (
    <div className='flex xl:flex-row flex-col gap-4'>
      {isShow47 && (
        <div className={freqContainer}>
          <h4 className={h4 + ' text-center'}>4.7GHz</h4>

          <div className={divider} />

          <div className={itemContainer}>
            {Object.entries(data_47).map(([port, data]) => (
              <ControlItem
                key={`control item 4G7 ${port}`}
                freq={4700}
                port={port}
              />
            ))}
          </div>
        </div>
      )}

      {isShow28 && (
        <div className={freqContainer}>
          <h4 className={h4 + ' text-center'}>28GHz</h4>

          <div className={divider} />

          <div className={itemContainer}>
            {Object.entries(data_28).map(([port, data]) => (
              <ControlItem
                key={`control item 28G ${port}`}
                freq={28000}
                port={port}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  )
}

export default RisV1

const ControlItem = ({ freq, port }) => {
  return (
    <div className='bg-light-4/10 rounded-lg w-[238px] p-4'>
      <Common freq={freq} port={port} />
      <div className={divider} />
      <BeamSteering freq={freq} port={port} />
      <div className={divider} />
      <Channel freq={freq} port={port} />
    </div>
  )
}

const freqContainer = `bg-light-4/10 w-full p-4 rounded-lg`

export const divider = `w-full h-[1px] bg-dark-5 my-4`

const itemContainer = `
                      flex justify-center 
                      xl:flex-nowrap
                      flex-wrap 
                      gap-4
                      `

const DeviceNotBeFound = () => {
  const dispatch = useDispatch()
  // const error = useSelector(state => state.error)

  const { current } = useGetCurrentDeviceData()
  const sn = current.sn

  const {
    preferredUsername: name,
    userID,
    email,
  } = useSelector(state => state.member)

  const deviceType = current?.data?.info?.deviceType
  const hwVersion = current?.data?.info?.versionInfo?.hardware
  const fwVersion = current?.data?.info?.versionInfo?.firmware

  const time = moment().format('YYYY-MM-DD  HH:mm:ss')
  const api = 'initDevice'
  const code = ''
  const codeName = ''
  const message = 'Ris v1: The device could not be found'

  const handleReportIssue = () =>
    dispatch(
      modalActions.modal_show({
        priority: 'emergency',
        name: 'ERROR_DEVICE_ERROR_SEND_MAIL',
        props: {
          name,
          userID,
          email,
          time,
          api,
          code,
          codeName,
          message,
          sn,
          deviceType,
          hwVersion,
          fwVersion,
        },
      })
    )
  const handleReScan = () => dispatch(scanWatcher())

  return (
    <div className='w-full min-h-full bg-light-4/10 flex flex-col items-center justify-center text-lg text-light-4 lg:p-28 p-10 rounded-md'>
      <img src={noDeviceFound} alt='tmytek page not found' className='mb-8' />

      <h4 className='font-bold'>The device could not be found</h4>

      <p className='my-2'>Please ensure</p>

      <ul className='list-decimal list-inside'>
        <li>RIS is properly connected to the controller</li>
        <li>Reboot the controller</li>
        <li>Click the “Re-scan” button below to try again</li>
      </ul>

      <p>If the problem persists, please contact TMYTEK. </p>

      <div className='flex gap-x-4 mt-10'>
        <CommonButton size='lg' type='outlined' onClick={handleReportIssue}>
          Report Issue
        </CommonButton>
        <CommonButton size='lg' type='general' onClick={handleReScan}>
          Re-scan
        </CommonButton>
      </div>
    </div>
  )
}
