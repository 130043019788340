import React from 'react'

import Modal from 'src/components/Popup/Modal'
import { RotatingLines } from 'react-loader-spinner'

import { BiTransfer } from 'react-icons/bi'

const CurrentMethodSwitching = props => {
  return (
    <Modal>
      <Modal.Icon>
        <BiTransfer className='text-white' />
      </Modal.Icon>

      <Modal.Title>Switching to SPI control...</Modal.Title>

      <Modal.Description>
        The waiting time depends on your internet speed.
      </Modal.Description>

      <div className='w-full mt-6 pb-10 flex justify-center'>
        <RotatingLines
          width='56'
          strokeColor='white'
          strokeWidth='3'
          animationDuration='0.7'
          wrapperStyle={{}}
          visible={true}
        />
      </div>
    </Modal>
  )
}

export default CurrentMethodSwitching
