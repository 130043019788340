import React, { useRef } from 'react'

import { useDispatch } from 'react-redux'

import Tooltip from 'src/components/Popup/Tooltip'
import CommonButton from 'src/components/Button/CommonButton'

import { HiOutlineSave } from 'react-icons/hi'
import { h4 } from 'src/containers/operating/device/beamformers/style'
import { modalActions } from 'src/redux/slices/modal'

import useGetCurrentDeviceData from 'src/hooks/useGetCurrentDeviceData'
import useGetCurrentMode from 'src/hooks/useGetCurrentMode'
import { ris_common_import_watcher } from 'src/redux/actions/beamformers/risAction'

const Common = ({ freq, port }) => {
  const dispatch = useDispatch()

  const { current } = useGetCurrentDeviceData()
  const { isDemoMode } = useGetCurrentMode()

  const sn = current?.sn
  const inputFileRef = useRef()

  const handleImportClick = () => inputFileRef.current.click()

  const label = `RIS_${port.split('_')[1]}`

  const handleFileInputChange = async event => {
    try {
      if (event.target.files.length > 0) {
        const fileData = event.target.files[0]
        const fileName = fileData.name

        //* Import flow:
        //* handle function -> 讀檔案整成 data
        //* -> spiImportConfig(saga) -> 判斷是否合法, 跳 modal
        //* -> callImportApi(saga) -> 判斷 call 哪隻 API / call API + 處理 response
        //! 在這邊開 modal 而不是 saga  開的原因是因為
        //! 如果 SPI config 檔案太大 FileReader 會 read 很久 (尤其是 windows)
        //! 這樣使用者就會看到一段空窗期，所以在這邊就直接開 importing modal
        dispatch(
          modalActions.modal_show({
            priority: 'normal',
            name: 'FILE_IMPORT_IMPORTING',
            props: {},
          })
        )

        const blob = new Blob([fileData], { type: fileData.type })
        const formData = new FormData()

        formData.append('file', blob, fileName)

        const { jsonFileData } = await readFileData(fileData)

        inputFileRef.current.value = await null

        await dispatch(
          ris_common_import_watcher({
            sn,
            freq,
            port,
            fileName,
            jsonFileData,
          })
        )
      }
    } catch (error) {
      console.warn(error)
      dispatch(
        modalActions.modal_show({
          priority: 'normal',
          name: 'BBOX_BEAM_CONFIG_EDITOR_IMPORT_INCORRECT_FORMAT',
          props: {},
        })
      )
    }
  }
  return (
    <div className='flex justify-between items-center'>
      <h6 className={h4 + ' uppercase'}>{label}</h6>

      <Tooltip title='No support in demo mode' disabled={!isDemoMode}>
        <input
          className='hidden'
          ref={inputFileRef}
          type='file'
          accept='.csv'
          onChange={handleFileInputChange}
        />
        <CommonButton
          type='text'
          icon={<HiOutlineSave />}
          // disabled
          disabled={isDemoMode}
          onClick={handleImportClick}>
          Import
        </CommonButton>
      </Tooltip>
    </div>
  )
}

export default Common

const readFileData = file =>
  new Promise((resolve, reject) => {
    let reader = new FileReader()

    reader.onload = e => {
      try {
        const fileContent = e.target.result
        const data = JSON.parse(fileContent)
        resolve({ jsonFileData: data })
      } catch (error) {
        reject(error) // 使用 reject 傳遞 JSON 解析錯誤
      }
    }

    reader.readAsText(file)
  })
