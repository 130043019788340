import React from 'react'
import { useDispatch } from 'react-redux'

import Modal from 'src/components/Popup/Modal'
import CommonButton from 'src/components/Button/CommonButton'

import { modalActions } from 'src/redux/slices/modal'

import { BiCheck } from 'react-icons/bi'

const Success = ({ email }) => {
  const dispatch = useDispatch()

  const handleSubmit = async event => {
    event.preventDefault()
    dispatch(modalActions.modal_hide({ priority: 'normal' }))
  }

  return (
    <Modal>
      <Modal.Icon>
        <BiCheck className='text-white' />
      </Modal.Icon>
      <Modal.Title>Hello! We got your request!</Modal.Title>
      <Modal.Description>
        We will reply you to <br />
        {email}
      </Modal.Description>

      <form onSubmit={handleSubmit}>
        <Modal.ColumnButtonBox>
          <CommonButton size='lg' type='primary' buttonType='submit'>
            OK
          </CommonButton>
        </Modal.ColumnButtonBox>
      </form>
    </Modal>
  )
}

export default Success
