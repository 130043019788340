import React from 'react'
import { useDispatch } from 'react-redux'

import Modal from 'src/components/Popup/Modal'
import CommonButton from 'src/components/Button/CommonButton'

import { BiTrash } from 'react-icons/bi'

import { modalActions } from 'src/redux/slices/modal'

const UsingNotDeleteCheck = props => {
  const dispatch = useDispatch()

  const handleOKClick = () =>
    dispatch(
      modalActions.modal_props_set({
        priority: 'normal',
        props: { child_modal_show: '' },
      })
    )

  return (
    <Modal>
      <Modal.Icon>
        <BiTrash className='text-red ' />
      </Modal.Icon>

      <Modal.Title>Items in use will not be deleted</Modal.Title>

      <Modal.ColumnButtonBox>
        <CommonButton size='lg' type='general' onClick={handleOKClick}>
          OK
        </CommonButton>
      </Modal.ColumnButtonBox>
    </Modal>
  )
}

export default UsingNotDeleteCheck
