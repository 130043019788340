import React from 'react'
import { useDispatch } from 'react-redux'

import Modal from 'src/components/Popup/Modal'
import CommonButton from 'src/components/Button/CommonButton'

import { BiTrash } from 'react-icons/bi'

import {
  bbox_customAntennaModal_deleteChildModal_deleteClick_watcher,
  bbox_customAntennaModal_deleteChildModal_cancelClick_watcher,
} from 'src/redux/actions/beamformers/bboxAction'

const ListModeDeleteCheck = () => {
  const dispatch = useDispatch()

  const handleDeleteClick = () =>
    dispatch(bbox_customAntennaModal_deleteChildModal_deleteClick_watcher())

  const handleCancelClick = () =>
    dispatch(bbox_customAntennaModal_deleteChildModal_cancelClick_watcher())

  return (
    <Modal>
      <Modal.Icon>
        <BiTrash className='text-red' />
      </Modal.Icon>

      <Modal.Title>Delete selected items? </Modal.Title>

      <Modal.Description>You cannot undo this action.</Modal.Description>

      <Modal.RowButtonBox>
        <CommonButton
          size='lg'
          type='primary'
          danger
          onClick={handleDeleteClick}>
          Delete
        </CommonButton>
        <CommonButton size='lg' type='general' onClick={handleCancelClick}>
          Cancel
        </CommonButton>
      </Modal.RowButtonBox>
    </Modal>
  )
}

export default ListModeDeleteCheck
