import { delay, select, race, take, call, put } from 'redux-saga/effects'
import * as R from 'ramda'

import { setSingleDeviceData } from 'src/redux/slices/deviceData'

import { udboxUIControlForSingleAction as uiControlAction } from 'src/redux/slices/uiControl/freqConverter/udbox'

import {
  getCurrentData,
  getCommonArgs,
} from 'src/redux/sagas/selector/deviceData'

import __socket_API_sender from 'src/redux/sagas/services/socketTools/__socket_API_sender'
import freqConverterApi from 'src/redux/sagas/services/socketAPI/freqConverter'
import facilityApi from 'src/redux/sagas/services/socketAPI/facility'

import {
  rfIfIsValid,
  valueConversionFromUnit,
  reverseValueUnitTokHz,
} from 'src/funcs/device/udbox'

import { modalActions } from 'src/redux/slices/modal'

import {
  ud_general_freqUnlockModal_cancelClick_watcher,
  ud_general_freqUnlockModal_enterClick_watcher,
} from 'src/redux/actions/freqConverter/generalAction'

import { devWarLog } from 'src/funcs/tools'

function* getLO({ sn }) {
  try {
    let currentData = yield call(getCurrentData, sn)
    const { lookupID } = yield call(getCommonArgs, sn)

    const unit = currentData.settings.freq.unit
    const RF = currentData.settings.freq.RF
    const IF = currentData.settings.freq.IF

    const isValid = yield call(rfIfIsValid, { RF, IF, unit })

    if (isValid) {
      yield call(__socket_API_sender, freqConverterApi.GET_LO, {
        sn,
        lookupID,
        RF: reverseValueUnitTokHz(RF.value, unit),
        IF: reverseValueUnitTokHz(IF.value, unit),
      })
    }

    if (!isValid) {
      currentData.settings.freq.LO = {
        ...currentData.settings.freq.LO,
        currentSelection: '',
        LSI: {
          value: '',
          disabled: {
            range: false,
            resolution: false,
          },
        },
        HSI: {
          value: '',
          disabled: {
            range: false,
            resolution: false,
          },
        },
      }
      yield put(setSingleDeviceData({ sn, data: currentData }))
    }
  } catch (error) {
    devWarLog('[handler] getLO error:', error)
  }
}

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* -----------------      unlock        -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------

export function* unlockClick(payloads) {
  try {
    const { sn } = payloads

    const normalModal = yield select(state => state.modal.normal)

    const isModalOpen = normalModal.name === 'FREQ_CONVERTER_UNLOCK_INPUT'

    if (!isModalOpen)
      yield put(
        modalActions.modal_show({
          priority: 'normal',
          name: 'FREQ_CONVERTER_UNLOCK_INPUT',
          props: { sn, digest: '', isRequesting: false, isFailed: false },
        })
      )

    const { cancel, enter } = yield race({
      cancel: take(ud_general_freqUnlockModal_cancelClick_watcher.type),
      enter: take(ud_general_freqUnlockModal_enterClick_watcher.type),
    })

    if (cancel) yield put(modalActions.modal_hide({ priority: 'normal' }))

    if (enter) {
      const digest = yield select(state => state.modal.normal.props.digest)
      const { lookupID } = yield call(getCommonArgs, sn)

      yield put(
        modalActions.modal_show({
          priority: 'normal',
          name: 'FREQ_CONVERTER_UNLOCK_REQUESTING',
          props: {
            sn,
            RF: {},
            IF: {},
            LO: {},
          },
        })
      )

      const unlockVerifyAck = yield call(
        __socket_API_sender,
        freqConverterApi.FREQ_SETTING_UNLOCK_RANGE,
        {
          sn,
          lookupID,
          digest,
        }
      )
      if (+unlockVerifyAck !== 0) throw Error('license key verify failed')

      const rebootAck = yield call(
        __socket_API_sender,
        facilityApi.DEVICE_REBOOT,
        {
          sn,
          lookupID,
        }
      )
      if (+rebootAck !== 0) throw Error('reboot failed')

      const initAck = yield call(
        __socket_API_sender,
        freqConverterApi.INIT_DEVICE,
        {
          sn,
          lookupID,
        }
      )

      if (+initAck !== 0) throw Error('init failed')

      yield put(
        modalActions.modal_show({
          priority: 'normal',
          name: 'FREQ_CONVERTER_UNLOCK_SUCCESS',
          props: {
            sn,
          },
        })
      )

      yield delay(2000)
      yield put(modalActions.modal_hide({ priority: 'normal' }))
    }
  } catch (error) {
    devWarLog('[handler] unlockClick error:', error)
  }
}

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* -----------------        unit        -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
function _dynamicValueConversionFromUnit(value, prevUnit, newUnit) {
  // 先把值全部轉成 KHz
  const kValue = reverseValueUnitTokHz(value, prevUnit)
  return valueConversionFromUnit(kValue, newUnit)
}
export function* unitChange(payloads) {
  try {
    const { sn, value: newUnit } = payloads

    let currentData = yield call(getCurrentData, sn)

    const prevUnit = yield R.clone(currentData.settings.freq.unit)
    currentData.settings.freq.unit = yield newUnit

    const settingRF = currentData.settings.freq.RF.value
    const settingIF = currentData.settings.freq.IF.value

    if (settingRF)
      currentData.settings.freq.RF.value = yield call(
        _dynamicValueConversionFromUnit,
        settingRF,
        prevUnit,
        newUnit
      )

    if (settingIF)
      currentData.settings.freq.IF.value = yield call(
        _dynamicValueConversionFromUnit,
        settingIF,
        prevUnit,
        newUnit
      )

    yield put(setSingleDeviceData({ sn, data: currentData }))
  } catch (error) {
    devWarLog('[handler] unitChange error:', error)
  }
}

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* -----------------      RF,IF,LO      -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------

export function* RFChange(payloads) {
  try {
    const { sn, value } = payloads

    let currentData = yield call(getCurrentData, sn)

    currentData.settings.freq.RF.value = value

    yield put(setSingleDeviceData({ sn, data: currentData }))
    yield put(uiControlAction.setIsFreqChanged({ sn, value: true }))
    yield call(getLO, { sn })
  } catch (error) {
    devWarLog('[handler] RFChange error:', error)
  }
}
export function* IFChange(payloads) {
  try {
    const { sn, value } = payloads
    let currentData = yield call(getCurrentData, sn)

    currentData.settings.freq.IF.value = value

    yield put(setSingleDeviceData({ sn, data: currentData }))
    yield put(uiControlAction.setIsFreqChanged({ sn, value: true }))
    yield call(getLO, { sn })
  } catch (error) {
    devWarLog('[handler] IFChange error:', error)
  }
}
export function* LOSelect(payloads) {
  try {
    const { sn, value } = payloads
    let currentData = yield call(getCurrentData, sn)
    const prevValue = currentData.settings.freq.LO.currentSelection

    if (value !== prevValue) {
      currentData.settings.freq.LO.currentSelection = value

      yield put(setSingleDeviceData({ sn, data: currentData }))
      yield put(uiControlAction.setIsFreqChanged({ sn, value: true }))
    }
  } catch (error) {
    devWarLog('[handler] LOSelect error:', error)
  }
}

export function* applyClick(payloads) {
  try {
    const { sn } = payloads

    let { currentData, lookupID } = yield call(getCommonArgs, sn)

    const { RF, IF, unit } = currentData.settings.freq
    const LOCurrentSelection = currentData.settings.freq.LO.currentSelection
    const LO = currentData.settings.freq.LO[LOCurrentSelection].value
    const bandwidth = currentData.settings.freq.bandwidth // isObject

    const kHzRF = reverseValueUnitTokHz(RF.value, unit)
    const kHzIF = reverseValueUnitTokHz(IF.value, unit)

    yield put(uiControlAction.setIsFreqRequesting({ sn }))

    yield call(__socket_API_sender, freqConverterApi.FREQ_SETTING_APPLY, {
      sn,
      lookupID,
      RF: kHzRF,
      IF: kHzIF,
      LO,
      bandwidth,
    })
  } catch (error) {
    devWarLog('[handler] applyClick error:', error)
  }
}

export function* clearClick(payloads) {
  try {
    const { sn } = payloads

    let { currentData } = yield call(getCommonArgs, sn)

    currentData.settings.freq.RF.value = ''
    currentData.settings.freq.IF.value = ''
    currentData.settings.freq.LO.currentSelection = ''
    currentData.settings.freq.LO.LSI.value = ''
    currentData.settings.freq.LO.HSI.value = ''
    currentData.settings.freq.bandwidth.isCustom = false
    currentData.settings.freq.bandwidth.value = 0

    yield put(setSingleDeviceData({ sn, data: currentData }))
  } catch (error) {
    devWarLog('[handler] applyClick error:', error)
  }
}

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* -----------------     Bandwidth      -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
// bandwidth be & fe 的資料格式永遠是 kHz
// bandwidth view 上永遠是 MHz,
// 所以 view 那邊會把全部的值都轉 MHz
// 然後我們在 saga 把全部的值都轉回 kHz
// result = data = kHz, view = MHz
export function* bandwidthSelectChange(payloads) {
  try {
    const { sn, value } = payloads
    let currentData = yield call(getCurrentData, sn)

    if (value === 'custom') {
      currentData.settings.freq.bandwidth.isCustom = true
      currentData.settings.freq.bandwidth.value = ''
    }

    if (value !== 'custom') {
      currentData.settings.freq.bandwidth.isCustom = false
      currentData.settings.freq.bandwidth.value = reverseValueUnitTokHz(
        value,
        'MHz'
      )
    }

    yield put(setSingleDeviceData({ sn, data: currentData }))
    yield put(uiControlAction.setIsFreqChanged({ sn, value: true }))
  } catch (error) {
    devWarLog('[handler] bandwidthSelectChange error:', error)
  }
}
export function* bandwidthInputChange(payloads) {
  try {
    const { sn, value } = payloads
    let currentData = yield call(getCurrentData, sn)

    currentData.settings.freq.bandwidth.value = reverseValueUnitTokHz(
      value,
      'MHz'
    )

    yield put(setSingleDeviceData({ sn, data: currentData }))
    yield put(uiControlAction.setIsFreqChanged({ sn, value: true }))
  } catch (error) {
    devWarLog('[handler] bandwidthInputChange error:', error)
  }
}
