import React from 'react'
import { useDispatch } from 'react-redux'

import Modal from 'src/components/Popup/Modal'
import CommonButton from 'src/components/Button/CommonButton'
import { modalActions } from 'src/redux/slices/modal'

import { IoHourglassOutline } from 'react-icons/io5'

const GlobalTimeout = props => {
  const dispatch = useDispatch()

  return (
    <Modal>
      <Modal.Icon>
        <IoHourglassOutline className='text-white' />
      </Modal.Icon>

      <Modal.Title>Loading taking longer than expected</Modal.Title>

      <Modal.Description>
        We apologize for the inconvenience. You can choose to continue waiting
        or refresh the page.
      </Modal.Description>

      <Modal.RowButtonBox>
        <CommonButton
          size='lg'
          type='general'
          onClick={() => window.location.reload()}>
          Refresh
        </CommonButton>
        <CommonButton
          size='lg'
          type='general'
          onClick={() =>
            dispatch(modalActions.modal_hide({ priority: 'high' }))
          }>
          Continue Waiting
        </CommonButton>
      </Modal.RowButtonBox>
    </Modal>
  )
}

export default GlobalTimeout
