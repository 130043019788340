import React from 'react'
import { useSelector } from 'react-redux'

import { FloatingPortal, FloatingOverlay } from '@floating-ui/react'

import TMXLABShortAnimation from 'src/assets/json/TMXLABShortAnimation.json'

import LottieWeb from 'src/components/Lottie'

const AnimationTMYLogoLoginWelcome = props => {
  const preferredUsername = useSelector(state => state.member.preferredUsername)

  const overlayClass = `
    fixed 
    inset-0 
    bg-black/80 
    z-10 
    flex flex-col  
    items-center justify-center 
    duration-[5000ms] 
    backdrop-blur-sm 
    opacity-100
    `

  const lottieClass = `max-w-[400px] `

  return (
    <FloatingPortal>
      <FloatingOverlay lockScroll className={overlayClass}>
        <LottieWeb
          className={lottieClass}
          play={true}
          loop={true}
          segments={[260, 347]}
          animationData={TMXLABShortAnimation}
          forceSegments={false}
        />

        <h6 className={helloText}>Hello, {preferredUsername}</h6>
      </FloatingOverlay>
    </FloatingPortal>
  )
}

export default AnimationTMYLogoLoginWelcome

const helloText = `w-5/6 block break-words text-2xl text-white text-center -mt-10`
