import React from 'react'

import { useDispatch, useSelector } from 'react-redux'

import { h2 } from 'src/containers/Modal/style'

import CommonButton from 'src/components/Button/CommonButton'

import {
  head,
  main,
  footer,
} from 'src/containers/Modal/Normal/PowerDetector/CustomCalibration/style'
import FunctionBar from 'src/containers/Modal/Normal/PowerDetector/CustomCalibration/ListMode/FunctionBar'
import THead from 'src/containers/Modal/Normal/PowerDetector/CustomCalibration/ListMode/THead'
import TBody from 'src/containers/Modal/Normal/PowerDetector/CustomCalibration/ListMode/TBody'
import NoData from 'src/containers/Modal/Normal/PowerDetector/CustomCalibration/ListMode/NoData'

import { modalActions } from 'src/redux/slices/modal'

import { pd_customCaliModal_createClick_watcher } from 'src/redux/actions/powerDetector'

const ListMode = ({ sn, selectIdList }) => {
  const dispatch = useDispatch()
  const caliData = useSelector(
    state => state.deviceData[sn].deviceControl.actionPanel.caliData
  )

  const caliDataLength = Object.keys(caliData).length

  const handleCreateClick = () =>
    dispatch(pd_customCaliModal_createClick_watcher({ sn }))
  const handleCloseClick = () =>
    dispatch(modalActions.modal_hide({ priority: 'normal' }))

  return (
    <>
      <header className={`${head} ${h2} text-light-1 pt-10 pb-6 text-center`}>
        Custom Calibration Config
      </header>

      {caliDataLength === 0 && <NoData />}

      {caliDataLength > 0 && (
        <main className={main + ' flex flex-col items-start px-[84px]'}>
          <FunctionBar selectIdList={selectIdList} />
          <THead {...{ caliDataLength, selectIdList }} />
          <TBody {...{ caliData, selectIdList }} />
        </main>
      )}

      <footer className={footer + ' flex justify-end gap-x-8'}>
        <CommonButton
          type='general'
          size='lg'
          className='px-4'
          disabled={caliDataLength >= 5}
          onClick={handleCreateClick}>
          Create Custom Calibration Config
        </CommonButton>

        <CommonButton type='outlined' size='lg' onClick={handleCloseClick}>
          Close
        </CommonButton>
      </footer>
    </>
  )
}

ListMode.propTypes = {}

export default ListMode
