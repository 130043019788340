import axios from 'axios'
import { getHost } from 'src/redux/sagas/services/url'
import { call } from 'redux-saga/effects'
import { isDeveloping } from 'src/funcs/getEnv'

import { _demoSuccessRes, getIsDemoMode } from 'src/redux/sagas/funcs/general'
import Controllers from 'src/server/Controllers/index.mjs'
//* [AJAX] MIDDLEWARE ---------------------
export default function* __restful_API_sender({ api, data }) {
  try {
    const isDemoMode = yield call(getIsDemoMode)

    //* api 用 function 是哪天要在裡面加判斷比較好改
    const { url = '', isAlwaysUseRestfulAPI = false } = api()
    const demoKey = `r_${url}`

    // 發送普通的 Restful API
    if (!isDemoMode || isAlwaysUseRestfulAPI) {
      yield call(_printFELog, { url, data })

      const response = yield call(_apiPkg, { api, data })

      yield call(_printBELog, {
        url,
        response: response?.data?.data,
      })
      return response

      // (Demo) call src/server/Controllers 的 function
    } else if (isDemoMode && !isAlwaysUseRestfulAPI && Controllers?.[demoKey]) {
      const response = Controllers?.[demoKey]({ req: data, isDemoMode: true })

      return _demoSuccessRes(response)

      // 上述兩者都不滿足就報錯
    } else {
      throw Error('Send "API" failed or "Demo Controller" not found')
    }
  } catch (errorMsg) {
    console.log(`Error: `, errorMsg)
    return errorMsg
  }
}

export const _apiPkg = ({ api, data }) => {
  const {
    server = '',
    method = 'GET',
    url = '/',
    timeout = 5000,
    config = {},
    tool = 'axios',
  } = api()

  const baseURL = getHost('ajax', server)

  if (tool === 'axios') {
    const instance = axios.create({ baseURL, timeout })
    const l_method = method.toLocaleLowerCase()
    if (method === 'GET') return instance[l_method](url, config)
    if (method !== 'GET') return instance[l_method](url, data, config)
  }

  if (tool === 'fetch') {
    return fetch(baseURL + url, {
      method,
      body: JSON.stringify(data),
      ...config,
    })
      .then(response => response)
      .catch(error => error.response)
  }
}

// ngf = not generator function
export const __ngf_restful_API_sender = ({ api, data }) => {
  const {
    server = '',
    method = 'GET',
    url = '/',
    timeout = 5000,
    config = {},
  } = api()

  const baseURL = getHost('ajax', server)
  const instance = axios.create({ baseURL, timeout })
  const l_method = method.toLocaleLowerCase()
  if (method === 'GET') return instance[l_method](url, config)
  if (method !== 'GET') return instance[l_method](url, data, config)
}

export const _printFELog = ({ url, data }) => {
  if (isDeveloping) {
    console.groupCollapsed(
      '%c༼ つ◕_◕ ༽つ  ' + url + ' [FE][Restful]',
      'color: rgba(255,150,150,0.7); font-weight: 900'
    )
    console.log('[FE] Request: ')
    console.dir(data || '-- No Data --')
    console.count('[FE] Request count')
    console.groupEnd()

    console.time('[BE] Timer')
  }
}
export const _printBELog = ({ url, response }) => {
  if (isDeveloping) {
    console.groupCollapsed(
      '%cლ(・´ｪ`・ლ)  ' + url + ' [BE][Restful]',
      'color: rgba(150,150,255,0.7); font-weight: 900'
    )
    console.log('[BE] Response:')
    console.dir(_demoSuccessRes(response))
    console.count('[BE] Response count')
    console.timeEnd('[BE] Timer')
    console.groupEnd()
  }
}
