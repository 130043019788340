import React from 'react'
import { useDispatch } from 'react-redux'

import Modal from 'src/components/Popup/Modal'
import CommonButton from 'src/components/Button/CommonButton'

import { BiXCircle } from 'react-icons/bi'

import { modalActions } from 'src/redux/slices/modal'

const FileImportFailed = props => {
  const dispatch = useDispatch()

  const handleFailedOKClick = () =>
    dispatch(modalActions.modal_hide({ priority: 'normal' }))

  return (
    <Modal>
      <Modal.Icon>
        <BiXCircle className='text-red ' />
      </Modal.Icon>

      <Modal.Title>Failed to import {props.failedList.length} file</Modal.Title>

      <Modal.Description>
        <>
          {props.failedList.map((e, i) => (
            <div className='text-center' key={'modal failed list ' + i}>
              {e}
            </div>
          ))}
          <br />
          <div className='text-center'>
            Please make sure the selected file matches the system format and try
            again.
          </div>
        </>
      </Modal.Description>

      <Modal.ColumnButtonBox>
        <CommonButton type='general' onClick={handleFailedOKClick}>
          OK
        </CommonButton>
      </Modal.ColumnButtonBox>
    </Modal>
  )
}

export default FileImportFailed
