import { take, put, race, call, delay, fork, cancel } from 'redux-saga/effects'
import Decimal from 'decimal.js'
import * as R from 'ramda'

import __socket_API_sender from 'src/redux/sagas/services/socketTools/__socket_API_sender'

import { getCommonArgs } from 'src/redux/sagas/selector/deviceData'
import { getIsDemoMode } from 'src/redux/sagas/funcs/general'

import { setSingleDeviceData } from 'src/redux/slices/deviceData'
import {
  setIsPowerReading as ui_setIsPowerReading,
  addSinglePowerPoint as ui_addSinglePowerPoint,
  updateMeasureDataLength as ui_updateMeasureDataLength,
} from 'src/redux/slices/uiControl/powerDetector'

import { devWarLog } from 'src/funcs/tools'

import powerDetectorApi from 'src/redux/sagas/services/socketAPI/powerDetector'
import localMiddlewareApi from 'src/redux/sagas/services/socketAPI/localMiddleware'
import { modalActions } from 'src/redux/slices/modal'
import {
  pd_changeCaliCheckModal_changeClick_watcher,
  pd_changeCaliCheckModal_noClick_watcher,
} from 'src/redux/actions/powerDetector'
import { getUiControl } from 'src/redux/sagas/selector/uiControl'

export function* freqChange(payloads) {
  try {
    const { sn, value, isValid } = payloads
    const { currentData, lookupID } = yield call(getCommonArgs, sn)

    currentData.deviceControl.actionPanel.currentFreq = value

    yield put(setSingleDeviceData({ sn, data: currentData }))

    if (isValid)
      yield call(
        __socket_API_sender,
        localMiddlewareApi.ACTION_PANEL_SET_CURRENT_FREQ,
        {
          lookupID,
          sn,
          value: +value,
        }
      )
  } catch (error) {
    devWarLog('[handler] freqChange error:', error)
  }
}

export function* readMethodChange(payloads) {
  try {
    const { sn, value } = payloads
    const { currentData, lookupID } = yield call(getCommonArgs, sn)

    currentData.deviceControl.actionPanel.readMethod = value

    yield put(setSingleDeviceData({ sn, data: currentData }))

    yield call(
      __socket_API_sender,
      localMiddlewareApi.ACTION_PANEL_SET_READ_METHOD,
      {
        lookupID,
        sn,
        value,
      }
    )
  } catch (error) {
    devWarLog('[handler] readMethodChange error:', error)
  }
}

export function* updateRateChange(payloads) {
  try {
    const { sn, value } = payloads
    const { currentData, lookupID } = yield call(getCommonArgs, sn)

    currentData.deviceControl.actionPanel.updateRate = value

    yield put(setSingleDeviceData({ sn, data: currentData }))

    yield call(
      __socket_API_sender,
      localMiddlewareApi.ACTION_PANEL_SET_UPDATE_RATE,
      {
        lookupID,
        sn,
        value: +value,
      }
    )
  } catch (error) {
    devWarLog('[handler] updateRateChange error:', error)
  }
}

let demoContinuousReadFuncList = {}

const _createMockPower = offset => {
  const newPower = new Decimal(-1)
    .add(Math.random() * offset)
    .sub(Math.random() * offset)
    .toFixed(2)

  return +newPower
}
// const _createMockPower = offset => -1.35

function* _demoReadPowerOnce({ sn }) {
  const { currentData } = yield call(getCommonArgs, sn)
  const average = currentData.deviceControl.actionPanel.average

  let newPower
  if (!average || average === 1) newPower = _createMockPower(10)
  if (average === 5) newPower = _createMockPower(7)
  if (average === 10) newPower = _createMockPower(4)
  if (average === 20) newPower = _createMockPower(1)

  currentData.deviceControl.dashboard.currentPowerValue = newPower

  const oldMeasureData = currentData.deviceControl.dashboard.measureData
  const _mapIndexed = R.addIndex(R.map)
  const newMeasureData = yield R.pipe(
    R.insert(0, { point: 0, power: newPower }),
    R.take(960),
    _mapIndexed((e, i) => ({ ...e, point: i + 1 }))
  )(oldMeasureData)

  currentData.deviceControl.dashboard.measureData = newMeasureData

  yield put(
    ui_addSinglePowerPoint({
      sn,
      power: newPower,
      measureDataLength: newMeasureData.length,
    })
  )
  yield put(setSingleDeviceData({ sn, data: currentData }))

  yield put(
    ui_updateMeasureDataLength({
      sn,
      measureDataLength: currentData.deviceControl.dashboard.measureData.length,
    })
  )
}

function* _demoReadPowerContinuous({ sn }) {
  const { currentData } = yield call(getCommonArgs, sn)
  const updateRate = currentData.deviceControl.actionPanel.updateRate

  while (true) {
    yield call(_demoReadPowerOnce, { sn })
    yield delay(updateRate)
  }
}

export function* readClick(payloads) {
  try {
    const { sn } = payloads
    const { currentData, lookupID } = yield call(getCommonArgs, sn)

    const isDemoMode = yield call(getIsDemoMode)
    const readMethod = currentData.deviceControl.actionPanel.readMethod
    const { single: s_uiControl } = yield call(getUiControl, sn)
    const isPowerReading = s_uiControl.isPowerReading

    // 1. 改 power reading (不分 demo or device control)
    if (readMethod === 'continuous') {
      if (isPowerReading) yield put(ui_setIsPowerReading({ sn, value: false }))
      if (!isPowerReading) yield put(ui_setIsPowerReading({ sn, value: true }))
    }

    // 2. 如果是 demo 直接 call single 或 continuous 相關 function
    // (demo read power 的行為完完全全在這裡就處理完了，沒過 server Controllers)
    if (isDemoMode) {
      if (readMethod === 'single') yield call(_demoReadPowerOnce, { sn })

      if (readMethod === 'continuous') {
        if (isPowerReading) yield cancel(demoContinuousReadFuncList[sn])

        if (!isPowerReading)
          demoContinuousReadFuncList[sn] = yield fork(
            _demoReadPowerContinuous,
            { sn }
          )
      }
    }

    // 如果是 device control 就 call API
    if (!isDemoMode) {
      let action
      if (readMethod === 'single') action = 'start'
      if (readMethod === 'continuous')
        action = isPowerReading ? 'stop' : 'start'

      yield call(
        __socket_API_sender,
        localMiddlewareApi.ACTION_PANEL_ACTIVATE_READ_CONTROL,
        {
          lookupID,
          sn,
          readMethod,
          action,
        }
      )
    }
  } catch (error) {
    devWarLog('[handler] readClick error:', error)
  }
}

export function* averageChange(payloads) {
  try {
    const { sn, value } = payloads
    const { currentData, lookupID } = yield call(getCommonArgs, sn)

    currentData.deviceControl.actionPanel.average = value

    yield put(setSingleDeviceData({ sn, data: currentData }))

    yield call(
      __socket_API_sender,
      localMiddlewareApi.ACTION_PANEL_SET_AVERAGE,
      {
        lookupID,
        sn,
        value: +value,
      }
    )
  } catch (error) {
    devWarLog('[handler] averageChange error:', error)
  }
}

export function* caliSelect(payloads) {
  try {
    const { sn, id } = payloads

    let isCheckPass = true
    let isNeedClearChartData = false

    // 1. 檢查當前 chart 是否有 data 有的話要跳提示
    const { single: s_uiControl } = yield call(getUiControl, sn)
    const measureDataLength = s_uiControl.measureDataLength

    if (measureDataLength !== 0) {
      yield put(
        modalActions.modal_show({
          priority: 'normal',
          name: 'PD_CHANGE_ANOTHER_CALI_CONFIG_FILE',
          props: {},
        })
      )

      const { change, no } = yield race({
        change: take(pd_changeCaliCheckModal_changeClick_watcher.type),
        no: take(pd_changeCaliCheckModal_noClick_watcher.type),
      })

      if (change) isNeedClearChartData = true
      if (no) isCheckPass = false

      yield put(modalActions.modal_hide({ priority: 'normal' }))
    }

    // 2. 如果確定可以換 cali 了就 call api
    if (isCheckPass) {
      const { lookupID } = yield call(getCommonArgs, sn)

      yield put(
        modalActions.modal_show({
          priority: 'normal',
          name: 'PD_CALI_CONFIG_FILE_CHANGING',
          props: {},
        })
      )

      const selectCaliID_ack = yield call(
        __socket_API_sender,
        powerDetectorApi.ACTION_PANEL_SELECT_CURRENT_CALI_ID,
        {
          lookupID,
          sn,
          value: id,
        }
      )

      if (selectCaliID_ack === 0 && isNeedClearChartData)
        yield call(
          __socket_API_sender,
          localMiddlewareApi.DASHBOARD_CLEAR_POWER_HISTORY,
          {
            lookupID,
            sn,
          }
        )
    }
  } catch (error) {
    devWarLog('[handler] caliSelect error:', error)
  }
}
